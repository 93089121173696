/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { getAlterationOutput } from '../../../../api';
import { manageOrderCodeLabelColumns } from '../../../../components';
import { alteration } from '../../../../entityModel';
import { ManageAlterationsTableBody } from './ManageAlterationsTableBody';
import { ManageAlterationsTableHead } from './ManageAlterationsTableHead';

interface ManageAlterationsTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getAlterationOutput;
  onEdit: (user: alteration) => void;
  onDelete: (user: alteration) => void;
}

export const ManageAlterationsTable = ({ error, loading, data, onEdit, onDelete, ...rest }: ManageAlterationsTableProps) => {
  return (
    <table className="table table-bordered">
      <ManageAlterationsTableHead
        columns={manageOrderCodeLabelColumns}
      />
      <ManageAlterationsTableBody
        columns={manageOrderCodeLabelColumns}
        loading={loading}
        error={error}
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </table>
  );
};
