/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TXT_EMAIL,
  TXT_PASSWORD,
  TXT_SIGNING_IN,
  TXT_SIGN_IN
} from '../../translations/translationConstants';
import { useAuth, useAuthServices } from '../../hooks';
import { CustomError } from '../../components';
import {
  capitalizeFirstLetterEveryword
} from '../../shared/stringFunctions';

export const SignInPage = () => {
  const [, setAuth] = useAuth();
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailnameValid] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isSigningIn, setSigningIn] = useState(false);
  const { t } = useTranslation();
  const { signIn } = useAuthServices();

  const onEmailChanged =
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setEmail(event.currentTarget.value);
    };

  const onPasswordChanged =
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPassword(event.currentTarget.value);
    };

  useEffect(() => {
    setIsEmailnameValid(!!email && email.trim().length > 0);
  }, [email]);

  useEffect(() => {
    setIsPasswordValid(!!password && password.trim().length > 0);
  }, [password]);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(undefined);
    if (!isEmailValid || !isPasswordValid) {
      return;
    }
    setSigningIn(true);
    const signInOutput = await signIn({
      email,
      password,
      IsMobile: false
    });
    setAuth({ ...signInOutput });

    if (signInOutput.errorMessage) {
      setError(signInOutput.errorMessage);
      setSigningIn(false);
    }
  };

  const txtEmail = capitalizeFirstLetterEveryword(t(TXT_EMAIL));
  const txtPassword = capitalizeFirstLetterEveryword(t(TXT_PASSWORD));

  return (
    <div className="login-box">
      <div className="card card-outline card-primary">
        <div className="card-body" style={{ textAlign: 'center' }}>
          <img src={process.env.PUBLIC_URL + '/rinda.png'} />
          <br />
          <br />
          {
            error !== undefined && !!error && error.length > 0 &&
            <CustomError>{error}</CustomError>
          }
          <form onSubmit={onSubmit} method="post">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder={txtEmail}
                value={email}
                onChange={onEmailChanged}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-envelope" />
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                type="password"
                className="form-control"
                placeholder={txtPassword}
                value={password}
                onChange={onPasswordChanged}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock" />
                </div>
              </div>
            </div>
            <div className="row">
              <button type="submit" className="btn btn-primary btn-block">
                {capitalizeFirstLetterEveryword(t(isSigningIn ? TXT_SIGNING_IN : TXT_SIGN_IN))}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
