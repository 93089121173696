import { gql } from '@apollo/client';
import { perpancinganQueryResult } from './perpancinganQueries';

export const ADD_PERPANCINGAN = gql`
  mutation addPerpancingan($input: AddPerpancinganInput!) {
    addPerpancingan(input: $input){
      ${perpancinganQueryResult}
    }
  }
`;

export const UPDATE_PERPANCINGAN = gql`
  mutation updatePerpancingan($input: UpdatePerpancinganInput!) {
    updatePerpancingan(input: $input){
      ${perpancinganQueryResult}
    }
  }
`;

export const DELETE_PERPANCINGAN = gql`
  mutation deletePerpancingan($input: DeletePerpancinganInput!) {
    deletePerpancingan(input: $input)
  }
`;
