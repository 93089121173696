import { gql } from '@apollo/client';
import { assayQueryResult } from './assayQueries';

export const ADD_ASSAY = gql`
  mutation addAssay($input: AddAssayInput!) {
    addAssay(input: $input){
      ${assayQueryResult}
    }
  }
`;

export const UPDATE_ASSAY = gql`
  mutation updateAssay($input: UpdateAssayInput!) {
    updateAssay(input: $input){
      ${assayQueryResult}
    }
  }
`;

export const DELETE_ASSAY = gql`
  mutation deleteAssay($input: DeleteAssayInput!) {
    deleteAssay(input: $input)
  }
`;
