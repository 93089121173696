/* eslint-disable max-len */
// import {
//   Button,
//   CircularProgress,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   useTheme
// } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../shared/stringFunctions';
import {
  TXT_DELETE_CONFIRMATION,
  TXT_DELETE_CONFIRMATION_TITLE,
  TXT_NO,
  TXT_YES
} from '../../translations/translationConstants';
export interface DeleteConfirmationProps {
  objectName: string;
  loading: boolean;
  onYes: () => void;
  onNo: () => void;
};

export const DeleteConfirmation = (props: DeleteConfirmationProps) => {
  const { t } = useTranslation();
  // const theme = useTheme();
  const title = t(TXT_DELETE_CONFIRMATION_TITLE).toUpperCase();
  const message = capitalizeFirstLetter(t(TXT_DELETE_CONFIRMATION, {
    name: props.objectName
  }));
  return (
    <div className="modal fade" id="modal-default">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>{message}</p>
          </div>
          <div className="modal-footer justify-content-between">
            <button type="button" onClick={props.onNo} className="btn btn-default" data-dismiss="modal">{t(TXT_NO)}</button>
            <button type="button" onClick={props.onYes} className="btn btn-primary" data-dismiss="modal">{t(TXT_YES)}</button>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>

  );
};
