import { gql } from '@apollo/client';
import { machineQueryResult } from './machineQueries';

export const ADD_MACHINE = gql`
  mutation addMachine($input: AddMachineInput!) {
    addMachine(input: $input){
      ${machineQueryResult}
    }
  }
`;

export const UPDATE_MACHINE = gql`
  mutation updateMachine($input: UpdateMachineInput!) {
    updateMachine(input: $input){
      ${machineQueryResult}
    }
  }
`;

export const DELETE_MACHINE = gql`
  mutation deleteMachine($input: DeleteMachineInput!) {
    deleteMachine(input: $input)
  }
`;
