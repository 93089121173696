/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { getBoreholeOutput } from '../../../../api';
import { borehole } from '../../../../entityModel';
import { manageBoreholeColumns } from './manageBoreholesColumns';
import { ManageBoreholesTableBody } from './ManageBoreholeTableBody';
import { ManageBoreholesTableHead } from './ManageBoreholeTableHead';

interface ManageBoreholesTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getBoreholeOutput;
  onDetail: (borehole: borehole) => void;
  onDelete: (borehole: borehole) => void;
  onExport: (borehole: borehole) => void;
}

export const ManageBoreholesTable = ({ error, loading, data, onDetail, onDelete, onExport, ...rest }: ManageBoreholesTableProps) => {
  return (
    <table className="table table-bordered">
      <ManageBoreholesTableHead
        columns={manageBoreholeColumns}
      />
      <ManageBoreholesTableBody
        columns={manageBoreholeColumns}
        loading={loading}
        error={error}
        data={data}
        onDetail={onDetail}
        onDelete={onDelete}
        onExport={onExport}
      />
    </table>
  );
};
