/* eslint-disable max-len */
// import { LabelDisplayedRowsArgs, TablePagination } from '@material-ui/core';
// import { ReactNode } from 'react';
// import { useTranslation } from 'react-i18next';
// import { capitalizeFirstLetter } from '../../shared/stringFunctions';
// import {
//   TXT_DISPLAYED_ROWS,
//   TXT_ROWS_PER_PAGE
// } from '../../translations/translationConstants';
// import { globalPageSizeOptions } from './globalPageSizeOptions';
// import { usePaginationBarStyles } from './paginationBarStyles';

import { memo } from 'react';
import { usePagination } from '../../hooks';

export interface PaginationBarProps {
  siblingCount?: number;
  totalCount?: number;
  pageSize: number;
  currentPage: number;
  onPageChanged: (page: number) => void;
  className?: string;
  onChangeRowsPerPage?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
};

export const PaginationBar = memo((props: PaginationBarProps) => {
  const paginationRange = usePagination({
    currentPage: props.currentPage,
    totalCount: props.totalCount,
    siblingCount: props.siblingCount,
    pageSize: props.pageSize
  });

  if (props.currentPage === 0 || paginationRange && paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    if (props.currentPage !== lastPage) {
      props.onPageChanged(props.currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (props.currentPage !== 1) {
      props.onPageChanged(props.currentPage - 1);
    }
  };

  const lastPage = paginationRange && paginationRange[paginationRange.length - 1];
  return (
    <div className="card-footer clearfix">
      <ul className="pagination pagination-sm m-0 float-right">
        <li className={`page-item ${props.currentPage === 1 ? 'disabled' : ''}`} onClick={onPrevious}><a className="page-link">«</a></li>
        {
          paginationRange && paginationRange.map((pageNumber) => {
            if (pageNumber.toLocaleString().includes('...')) {
              return <li key={pageNumber} className="page-item"><a style={{ cursor: 'pointer' }} className='page-link'>&#8230;</a></li>;
            }

            return (
              <li
                key={pageNumber}
                className={`page-item ${props.currentPage === pageNumber ? 'active' : ''}`}
                onClick={() => props.onPageChanged(parseInt(pageNumber.toString()))}
              >
                <a style={{ cursor: 'pointer' }} className="page-link">{pageNumber}</a>
              </li>
            );
          })
        }
        <li className={`page-item ${props.currentPage === lastPage ? 'disabled' : ''}`} onClick={onNext}><a style={{ cursor: 'pointer' }} className="page-link">»</a></li>
      </ul>
    </div>
    // <TablePagination
    //   {...props}
    //   component='div'
    //   rowsPerPageOptions={props.rowsPerPageOptions || globalPageSizeOptions}
    //   className={props.className || classes.pagingBar}
    //   labelRowsPerPage={lblRowsPerPage}
    //   labelDisplayedRows={lblDisplayedRows}
    // />
  );
});

