import axios from 'axios';
import { apiUrl } from '../../../shared/links';
import { refreshTokenInput } from './refreshTokenInput';
import { refreshTokenOutput } from './refreshTokenOutput';

export const refreshTokenService = async (input: refreshTokenInput) => {
  const serviceURL = `${apiUrl}/user/refreshtoken`;
  try {
    const response = await axios.post<refreshTokenOutput>(serviceURL, input);
    return response.data;
  } catch (ex) {
    if (!ex.response || !ex.response.data) {
      const retError: refreshTokenOutput = {
        accessToken: '',
        accessTokenValidDate: new Date(),
        email: '',
        errorMessage: ex
      };
      return retError;
    }

    return ex.response.data as refreshTokenOutput;
  }
};
