interface SignOutButtonProps {
  onSignOut: () => void;
}

export const SignOutButton = (props : SignOutButtonProps) => {
  return (
    <>
      <a className="nav-link" onClick={props.onSignOut}>
        <i className="fas fa-sign-out-alt" />
      </a>
    </>
  );
};
