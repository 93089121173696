import {
  TXT_ALTERATION1,
  TXT_ALTERATION2,
  TXT_AU,
  TXT_CB,
  TXT_CH,
  TXT_CORE_INTEGRITY,
  TXT_CORE_RECT_UOM,
  TXT_CP,
  TXT_CY,
  TXT_DELETE,
  TXT_EDIT,
  TXT_FROM,
  TXT_GEOTECHNICAL,
  TXT_GX,
  TXT_LENGTH_UOM,
  TXT_LITH,
  TXT_MINERALS,
  TXT_PERCENTAGE,
  TXT_QZ,
  TXT_REMARKS,
  TXT_SL_GN,
  TXT_STOCKWORK,
  TXT_SX,
  TXT_TO,
  TXT_VEIN_QUAL1,
  TXT_VEIN_QUAL2,
  TXT_VEIN_QUAL3
} from '../../../../translations/translationConstants';

export interface ManageGeologicalDataColumn {
  id: 'from' | 'to' | 'lith' | 'vein1' | 'vein2' | 'vein3' | 'alt1' |
  'alt2' | 'stockwork' | 'cy' | 'qz' | 'ch' | 'cb' | 'sx' | 'gx' |
  'slgn' | 'cp' | 'au' | 'coreRec' | 'percentage' | 'length' |
  'remarks' | 'geotech' | 'edit' | 'delete';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  colSpan?: string;
};

export const manageGeologicalDataColumns: ManageGeologicalDataColumn[] = [
  {
    id: 'from',
    label: TXT_FROM,
    maxWidth: '5%',
    align: 'left',
    colSpan: 'none'
  },
  {
    id: 'to',
    label: TXT_TO,
    maxWidth: '5%',
    align: 'left',
    colSpan: 'none'
  },
  {
    id: 'lith',
    label: TXT_LITH,
    maxWidth: '5%',
    align: 'left',
    colSpan: 'none'
  },
  {
    id: 'vein1',
    label: TXT_VEIN_QUAL1,
    maxWidth: '5%',
    align: 'left',
    colSpan: 'none'
  },
  {
    id: 'vein2',
    label: TXT_VEIN_QUAL2,
    maxWidth: '5%',
    align: 'left',
    colSpan: 'none'
  },
  {
    id: 'vein3',
    label: TXT_VEIN_QUAL3,
    maxWidth: '5%',
    align: 'left',
    colSpan: 'none'
  },
  {
    id: 'alt1',
    label: TXT_ALTERATION1,
    maxWidth: '5%',
    align: 'left',
    colSpan: 'none'
  },
  {
    id: 'alt2',
    label: TXT_ALTERATION2,
    maxWidth: '5%',
    align: 'left',
    colSpan: 'none'
  },
  {
    id: 'stockwork',
    label: TXT_STOCKWORK,
    maxWidth: '5%',
    align: 'left',
    colSpan: 'none'
  },
  {
    id: 'cy',
    label: TXT_CY,
    maxWidth: '3%',
    align: 'center',
    colSpan: TXT_MINERALS
  },
  {
    id: 'qz',
    label: TXT_QZ,
    maxWidth: '3%',
    align: 'center',
    colSpan: TXT_MINERALS
  },
  {
    id: 'ch',
    label: TXT_CH,
    maxWidth: '3%',
    align: 'center',
    colSpan: TXT_MINERALS
  },
  {
    id: 'cb',
    label: TXT_CB,
    maxWidth: '3%',
    align: 'center',
    colSpan: TXT_MINERALS
  },
  {
    id: 'sx',
    label: TXT_SX,
    maxWidth: '3%',
    align: 'center',
    colSpan: TXT_MINERALS
  },
  {
    id: 'gx',
    label: TXT_GX,
    maxWidth: '3%',
    align: 'center',
    colSpan: TXT_MINERALS
  },
  {
    id: 'slgn',
    label: TXT_SL_GN,
    maxWidth: '3%',
    align: 'center',
    colSpan: TXT_MINERALS
  },
  {
    id: 'cp',
    label: TXT_CP,
    maxWidth: '3%',
    align: 'center',
    colSpan: TXT_MINERALS
  },
  {
    id: 'au',
    label: TXT_AU,
    maxWidth: '3%',
    align: 'center',
    colSpan: TXT_MINERALS
  },
  {
    id: 'coreRec',
    label: TXT_CORE_RECT_UOM,
    maxWidth: '3%',
    align: 'center',
    colSpan: TXT_CORE_INTEGRITY
  },
  {
    id: 'percentage',
    label: TXT_PERCENTAGE,
    maxWidth: '3%',
    align: 'center',
    colSpan: TXT_CORE_INTEGRITY
  },
  {
    id: 'length',
    label: TXT_LENGTH_UOM,
    maxWidth: '3%',
    align: 'center',
    colSpan: TXT_CORE_INTEGRITY
  },
  {
    id: 'remarks',
    label: TXT_REMARKS,
    maxWidth: '4.75%',
    align: 'left',
    colSpan: 'none'
  },
  {
    id: 'geotech',
    label: TXT_GEOTECHNICAL,
    maxWidth: '4.75%',
    align: 'center',
    colSpan: 'none'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '4.75%',
    align: 'center',
    colSpan: 'none'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '4.75%',
    align: 'center',
    colSpan: 'none'
  }
];
