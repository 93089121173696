import {
  TXT_CODE,
  TXT_DELETE,
  TXT_EDIT,
  TXT_LABEL
} from '../../translations/translationConstants';

export interface ManageCodeLabelColumn {
  id: 'code' | 'label' | 'edit' | 'delete';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

export const manageCodeLabelColumns: ManageCodeLabelColumn[] = [
  {
    id: 'code',
    label: TXT_CODE,
    maxWidth: '35%',
    align: 'left'
  },
  {
    id: 'label',
    label: TXT_LABEL,
    maxWidth: '45%',
    align: 'left'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '5%'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '5%'
  }
];
