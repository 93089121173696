/* eslint-disable max-len */
import { ReactNode } from 'react';

export const CustomError = (props: { children: ReactNode }) => {
  return (
    <div className="alert alert-danger">
      {props.children}
    </div>
  );
};
