import { gql } from '@apollo/client';

export const machineQueryResult = `
  id,
  code,
  label
`;

export const GET_MACHINE = gql`
  query getMachines($code: String, $skip: Int, $take: Int) {
    machines(code: $code, skip: $skip, take: $take) {
      items {
        ${machineQueryResult}
      },
      totalCount
    }
  }
`;
