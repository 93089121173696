import { gql } from '@apollo/client';

export const holeSizeQueryResult = `
  id,
  code,
  label
`;

export const GET_HOLE_SIZE = gql`
  query getHoleSizes($code: String, $skip: Int, $take: Int) {
    holeSizes(code: $code, skip: $skip, take: $take) {
      items {
        ${holeSizeQueryResult}
      },
      totalCount
    }
  }
`;
