/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { getWeatheringOutput } from '../../../../api';
import { manageOrderCodeLabelColumns } from '../../../../components';
import { weathering } from '../../../../entityModel';
import { ManageWeatheringsTableBody } from './ManageWeatheringTableBody';
import { ManageWeatheringsTableHead } from './ManageWeatheringTableHead';

interface ManageWeatheringsTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getWeatheringOutput;
  onEdit: (weathering: weathering) => void;
  onDelete: (weathering: weathering) => void;
}

export const ManageWeatheringsTable = ({ error, loading, data, onEdit, onDelete, ...rest }: ManageWeatheringsTableProps) => {
  return (
    <table className="table table-bordered">
      <ManageWeatheringsTableHead
        columns={manageOrderCodeLabelColumns}
      />
      <ManageWeatheringsTableBody
        columns={manageOrderCodeLabelColumns}
        loading={loading}
        error={error}
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </table>
  );
};
