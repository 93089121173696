import {
  TXT_DELETE,
  TXT_EDIT,
  TXT_NAME,
  TXT_PHOTO
} from '../../../../translations/translationConstants';

export interface ManagePhotoColumn {
  id: 'name' | 'photo' | 'edit' | 'delete';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

export const managePhotoColumns: ManagePhotoColumn[] = [
  {
    id: 'name',
    label: TXT_NAME,
    maxWidth: '30%',
    align: 'center'
  },
  {
    id: 'photo',
    label: TXT_PHOTO,
    maxWidth: '45%',
    align: 'center'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '10%',
    align: 'center'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '10%',
    align: 'center'
  }
];
