/* eslint-disable max-len */

import { applicationRole, applicationUser } from '../../../../entityModel';
import { useTranslation } from 'react-i18next';
import {
  addApplicationUserInput,
  getApplicationRolesOutput,
  GET_APPLICATION_ROLES,
  updateApplicationUserInput
} from '../../../../api';
import { TXT_ADD_USER, TXT_CANCEL, TXT_EDIT_USER, TXT_EMAIL, TXT_MOBILE_USER, TXT_NAME, TXT_PASSWORD, TXT_PHONE, TXT_REPASSWORD, TXT_ROLE, TXT_SAVE, TXT_SAVING, TXT_USERNAME } from '../../../../translations/translationConstants';
import { capitalizeFirstLetterEveryword } from '../../../../shared/stringFunctions';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

export interface ManageUserEditorProps {
  user?: applicationUser;
  onClose: () => void;
  onSave: (payload: addApplicationUserInput | updateApplicationUserInput) => void;
  isSaving: boolean;
  savingError?: string;
};

export const ManageUserEditor = (props: ManageUserEditorProps) => {
  const { t } = useTranslation();

  const [username, setUsername] = useState<string | undefined>(props.user && props.user.username);
  const [fullName, setFullname] = useState<string | undefined>(props.user && props.user.profile && props.user.profile.fullName);
  const [role, setRole] = useState<applicationRole | undefined>(props.user && props.user.role);
  const [email, setEmail] = useState<string | undefined>(props.user && props.user.profile && props.user.profile.email);
  const [phone, setPhone] = useState<string | undefined>(props.user && props.user.profile && props.user.profile.phone);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [respassword, setRepassword] = useState<string | undefined>(undefined);
  const [isMobile, setIsMobile] = useState<boolean>(props.user ? props.user.isMobile! : false);

  const [getRoles, getRolesOperation] = useLazyQuery<getApplicationRolesOutput>(GET_APPLICATION_ROLES);
  useEffect(() => {
    getRoles();
  }, []);

  const txtTitle = t(props.user ? TXT_EDIT_USER : TXT_ADD_USER).toUpperCase();
  const txtSave = t(TXT_SAVE);
  const txtSaving = t(TXT_SAVING);


  const txtUsername = `${capitalizeFirstLetterEveryword(t(TXT_USERNAME))}*`;
  const txtName = `${capitalizeFirstLetterEveryword(t(TXT_NAME))}*`;
  const txtRole = `${capitalizeFirstLetterEveryword(t(TXT_ROLE))}*`;
  const txtEmail = `${capitalizeFirstLetterEveryword(t(TXT_EMAIL))}*`;
  const txtPhone = `${capitalizeFirstLetterEveryword(t(TXT_PHONE))}*`;
  const txtPassword = `${capitalizeFirstLetterEveryword(t(TXT_PASSWORD))}*`;
  const txtRePassword = `${capitalizeFirstLetterEveryword(t(TXT_REPASSWORD))}*`;
  const txtIsMobile = `${capitalizeFirstLetterEveryword(t(TXT_MOBILE_USER))}*`;

  const roleOptions = getRolesOperation.loading ||
    getRolesOperation.data === undefined ||
    getRolesOperation.data.roles.items.length <= 0 ?
    [] : getRolesOperation.data.roles.items;

  const onUsernameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.currentTarget.value);
  };

  const onFullNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullname(event.currentTarget.value);
  };

  const onRoleChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const appRole = roleOptions.find((role) => role.id === event.currentTarget.value);
    setRole(appRole);
  };

  const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  const onPhoneChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.currentTarget.value);
  };

  const onPasswordChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const onRepasswordChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepassword(event.currentTarget.value);
  };

  const onIsMobileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsMobile(event.currentTarget.checked);
  };

  const validate = () => {
    return !!username &&
      !!fullName &&
      !!role &&
      !!email &&
      !!phone &&
      password === respassword;
  };

  const [disabledSaveButton, setDisabledSaveButton] = useState(!validate());

  useEffect(() => {
    setDisabledSaveButton(!validate());
  }, [username, fullName, role, email, phone, password, respassword]);

  const createAddInput = (): addApplicationUserInput => {
    return {
      username: username!,
      fullName: fullName!,
      roleId: role!.id!,
      email: email!,
      phone: phone!,
      password: password!,
      rePassword: respassword!,
      isMobile: isMobile
    };
  };

  const updateAddInput = (): updateApplicationUserInput => {
    return {
      username: username!,
      fullName: fullName!,
      roleId: role!.id!,
      email: email!,
      phone: phone!,
      isMobile: isMobile
    };
  };

  const onSaving = () => {
    if (!validate()) {
      return;
    }
    props.onSave(!props.user ? createAddInput() : updateAddInput());
  };

  return (
    <div className="modal fade" id="modal-user-editor">
      <div className="modal-dialog">
        <form onSubmit={onSaving}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{txtTitle}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label>{txtUsername}</label>
                <input type="text" onChange={onUsernameChanged} value={username} className="form-control" />
              </div>
              <div className="form-group">
                <label>{txtName}</label>
                <input type="text" onChange={onFullNameChanged} value={fullName} className="form-control" />
              </div>
              <div className="form-group">
                <label>{txtEmail}</label>
                <input type="email" onChange={onEmailChanged} value={email} className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="inputStatus">{txtRole}</label>
                <select onChange={onRoleChanged} className="form-control custom-select" value={role?.id}>
                  {roleOptions && roleOptions.length > 0 && roleOptions.map((role: applicationRole) => (
                    <option key={role.id} value={role.id}>{role.name}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>{txtPhone}</label>
                <input type="phone" onChange={onPhoneChanged} value={phone} className="form-control" />
              </div>
              <div className="form-group">
                <label>{txtPassword}</label>
                <input type="password" onChange={onPasswordChanged} value={password} className="form-control" />
              </div>
              <div className="form-group">
                <label>{txtRePassword}</label>
                <input type="password" onChange={onRepasswordChanged} value={respassword} className="form-control" />
              </div>
              <div className="form-check">
                <input type="checkbox" checked={isMobile} onChange={onIsMobileChanged} className="form-check-input" />
                <label className="form-check-label" >{txtIsMobile}</label>
              </div>

            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={props.onClose} className="btn btn-default" data-dismiss="modal">{t(TXT_CANCEL)}</button>
              <button type="submit" disabled={disabledSaveButton || props.isSaving} className="btn btn-primary">{props.isSaving ? txtSaving : txtSave}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

