import { gql } from '@apollo/client';

export const infillAlterationQueryResult = `
  id,
  code,
  label
`;

export const GET_INFILL_ALTERATION = gql`
  query getinfillAlterations($code: String, $skip: Int, $take: Int) {
    infillAlterations(code: $code, skip: $skip, take: $take) {
      items {
        ${infillAlterationQueryResult}
      },
      totalCount
    }
  }
`;
