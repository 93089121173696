/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import {
  capitalizeFirstLetterEveryword
} from '../../../shared/stringFunctions';
import { TXT_ADD_STRENGTH, TXT_STRENGTH } from '../../../translations/translationConstants';
import {
  TXT_SEARCH_BY_NAME
} from '../../../translations/translationConstants';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import {
  addStrengthInput,
  deleteStrengthInput,
  getLithologiesInput,
  getStrengthOutput,
  GET_STRENGTH,
  updateStrengthInput
} from '../../../api';
import { useEffect } from 'react';
import { useState } from 'react';
import { ManageStrengthsTable } from './ManageStrengthTable';
import {
  DeleteConfirmation,
  ExportToExcel,
  globalPageSizeOptions,
  PaginationBar
} from '../../../components';
import { strength } from '../../../entityModel';
import { useApolloErrorHandler, useAuthServices } from '../../../hooks';
import {
  ADD_STRENGTH,
  DELETE_STRENGTH,
  UPDATE_STRENGTH
} from '../../../api';
import { ManageStrengthEditor } from './ManagerStrengthEditor';

export const ManageStrengths = () => {
  const { t } = useTranslation();
  const { isSignedIn } = useAuthServices();

  const [txtSearch, setTxtSearch] = useState('');
  const [alterationData, setAlterationData] = useState<strength[] | undefined>([]);

  const onTxtSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTxtSearch(event.currentTarget.value);
  };

  const [selectedStrength, setSelectedStrength] = useState<strength | undefined>(undefined);
  const [getStrengths, { loading, data, error, refetch }] = useLazyQuery<getStrengthOutput>(GET_STRENGTH);
  const [getAllAlterations] = useLazyQuery<getStrengthOutput>(GET_STRENGTH, {
    onCompleted: (data) => setAlterationData(data.strengths.items)
  });

  useEffect(() => {
    getStrengths();
    getAllAlterations();
  }, []);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(globalPageSizeOptions[0]);

  const [openEditor, setOpenEditor] = useState(false);
  const [savingError, setSavingError] = useState<string | undefined>(undefined);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const lblSearch = capitalizeFirstLetterEveryword(t(TXT_SEARCH_BY_NAME));

  const fetchStrengths = () => {
    const variables: getLithologiesInput = {
      code: txtSearch,
      take: pageSize,
      skip: (pageNumber - 1) * pageSize
    };
    getStrengths({
      variables
    });
  };

  const fetch = (resetPage?: boolean) => {
    if (!!resetPage && pageNumber !== 1) {
      setPageNumber(1);
    } else {
      fetchStrengths();
    }
  };

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, error);
  }, [error]);

  useEffect(() => {
    fetch();
  }, [pageNumber]);

  useEffect(() => {
    fetch(true);
  }, [pageSize, txtSearch]);

  const openEditStrengthEditor = (strength: strength) => {
    setSelectedStrength(strength);
    setOpenEditor(true);
  };

  const openDeleteStrengthConfirmation = (strength: strength) => {
    setSelectedStrength(strength);
    setOpenDeleteConfirmation(true);
  };

  const openAddStrengthEditor = () => {
    setSelectedStrength(undefined);
    setOpenEditor(true);
  };

  const closeEditor = () => {
    setOpenEditor(false);
  };

  const afterAddingOrUpdatingStrength = () => {
    setOpenEditor(false);
    if (refetch) {
      refetch();
    }
  };

  const handleAddingOrUpdatingStrengthError = (error: ApolloError) => {
    if (error.graphQLErrors.length > 0 &&
      !!error.graphQLErrors[0].extensions) {
      setSavingError(error.graphQLErrors[0].extensions['message']);
    } else {
      setSavingError(error.message);
    }
  };

  const [addStrength, addStrengthOperation] = useMutation<strength>(ADD_STRENGTH, {
    onError: (e) => handleAddingOrUpdatingStrengthError(e),
    onCompleted: (result) => afterAddingOrUpdatingStrength()
  });

  const [updateStrength, updateStrengthOperation] = useMutation<strength>(UPDATE_STRENGTH, {
    onError: (e) => handleAddingOrUpdatingStrengthError(e),
    onCompleted: (result) => afterAddingOrUpdatingStrength()
  });

  const onEditorSave = (payload: addStrengthInput | updateStrengthInput) => {
    if (!selectedStrength) {
      addStrength({
        variables: {
          input: payload
        }
      });
    } else {
      updateStrength({
        variables: {
          input: payload
        }
      });
    }
  };

  const onDeleteCancel = () => setOpenDeleteConfirmation(false);

  const [deleteStrength, deleteStrengthOperation] = useMutation<boolean>(DELETE_STRENGTH, {
    onError: (e) => { },
    onCompleted: (result) => {
      if (data && refetch) {
        if (data.strengths.items.length == 1) {
          const targetPage = pageNumber - 1 <= 0 ? 1 : pageNumber - 1;
          if (pageNumber !== targetPage) {
            setPageNumber(targetPage);
          } else {
            refetch();
          }
        } else {
          refetch();
        }
      }
      onDeleteCancel();
    }
  });

  const onDelete = () => {
    const input: deleteStrengthInput = {
      id: selectedStrength!.id!
    };
    deleteStrength({
      variables: {
        input
      }
    });
  };

  const onPageSizeChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(+event.target.value);
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{t(capitalizeFirstLetterEveryword(TXT_STRENGTH))}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          {
            openEditor &&
            <ManageStrengthEditor
              strength={selectedStrength}
              onClose={closeEditor}
              onSave={onEditorSave}
              isSaving={
                !selectedStrength ? addStrengthOperation.loading :
                  updateStrengthOperation.loading
              }
              savingError={savingError}
            />
          }
          {
            openDeleteConfirmation &&
            <DeleteConfirmation
              loading={deleteStrengthOperation.loading}
              onYes={onDelete}
              onNo={onDeleteCancel}
              objectName={selectedStrength?.label || ''}
            />
          }
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className='row'>
                    <div className='col-6'>
                      <div className="form-group">
                        <label>{lblSearch}</label>
                        <input type='text' className='form-control' value={txtSearch} onChange={onTxtSearchChanged} />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        className='btn btn-primary'
                        data-target="#modal-strength-editor"
                        data-toggle="modal"
                        disabled={loading}
                        onClick={openAddStrengthEditor}>
                        {t(TXT_ADD_STRENGTH).toUpperCase()}
                      </button>
                    </div>
                    <div className="col-md-6" style={{ textAlign: 'right' }}>
                      <ExportToExcel filename={TXT_STRENGTH} data={alterationData} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ManageStrengthsTable
                    loading={loading}
                    data={data}
                    error={error}
                    onEdit={openEditStrengthEditor}
                    onDelete={openDeleteStrengthConfirmation}
                  />
                </div>
                <PaginationBar
                  currentPage={pageNumber}
                  totalCount={data?.strengths.totalCount}
                  pageSize={pageSize}
                  onPageChanged={(page) => setPageNumber(page)}
                  onChangeRowsPerPage={onPageSizeChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
