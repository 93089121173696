/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import { CustomError } from '../../../../components';
import { geoPhoto } from '../../../../entityModel';
import {
  TXT_DATA_NOT_FOUND,
  TXT_DELETE,
  TXT_EDIT,
  TXT_LOADING
} from '../../../../translations/translationConstants';
import { capitalizeFirstLetter } from '../../../../shared/stringFunctions';
import { ApolloError } from '@apollo/client';
import { ManagePhotoColumn } from './managePhotoColumns';
import { memo } from 'react';
import { getGeoPhotoOutput } from '../../../../api';
import { apiBaseUrl } from '../../../../shared/links';

export interface ManagePhotosTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: getGeoPhotoOutput;
  columns: ManagePhotoColumn[];
  onEdit: (photo: geoPhoto) => void;
  onDelete: (photo: geoPhoto) => void;
}

export const ManagePhotoTableBody =
  memo(({ error, columns, loading, data, onEdit, onDelete }: ManagePhotosTableBodyProps) => {
    const { t } = useTranslation();
    return (
      <tbody>
        {
          loading &&
          <tr>
            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
              <h3>
                {t(TXT_LOADING).toUpperCase()}
              </h3>
            </td>
          </tr>
        }
        {
          !loading && error && error.message &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {error.message}
              </CustomError>
            </td>
          </tr>
        }
        {
          !loading && data &&
          data.geoPhotos && data.geoPhotos.items &&
          data.geoPhotos.items.length > 0 &&
          data.geoPhotos.items.map((photo, index) => {
            return (
              <tr key={`row-${photo.id}`}>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {`${photo.from} - ${photo.to}`}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <img className="product-image-thumb" src={apiBaseUrl !== undefined ? apiBaseUrl + photo.filePath : '#'} />
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <button
                    data-target="#modal-photo-editor"
                    data-toggle="modal"
                    className='btn btn-primary btn-sm'
                    aria-label="edit photo"
                    onClick={() => onEdit(photo)}>
                    {t(TXT_EDIT).toUpperCase()}
                  </button>
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <button
                    data-target="#modal-default"
                    data-toggle="modal"
                    className='btn btn-danger btn-sm'
                    aria-label="delete photo"
                    type="button"
                    onClick={() => onDelete(photo)}>
                    {t(TXT_DELETE).toUpperCase()}
                  </button>
                </td>
              </tr>
            );
          })
        }
        {
          !loading &&
          (!data ||
            !data.geoPhotos ||
            !data.geoPhotos.items ||
            data.geoPhotos.items.length <= 0) &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {capitalizeFirstLetter(t(TXT_DATA_NOT_FOUND))}
              </CustomError>
            </td>
          </tr>
        }
      </tbody>
    );
  });

ManagePhotoTableBody.displayName = 'ManagePhotoTableBody';
