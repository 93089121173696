/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import { CustomError } from '../../../../components';
import { borehole } from '../../../../entityModel';
import {
  TXT_DATA_NOT_FOUND,
  TXT_DELETE,
  TXT_DETAIL,
  TXT_EXPORT_DETAIL,
  TXT_LOADING
} from '../../../../translations/translationConstants';
import { capitalizeFirstLetter } from '../../../../shared/stringFunctions';
import { getBoreholeOutput } from '../../../../api';
import { ApolloError } from '@apollo/client';
import { ManageBoreholesColumn } from './manageBoreholesColumns';

export interface ManageBoreholesTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: getBoreholeOutput;
  columns: ManageBoreholesColumn[];
  onDetail: (borehole: borehole) => void;
  onDelete: (borehole: borehole) => void;
  onExport: (borehole: borehole) => void;
}

export const ManageBoreholesTableBody =
  ({ error, columns, loading, data, onDetail, onDelete, onExport }: ManageBoreholesTableBodyProps) => {
    const { t } = useTranslation();
    return (
      <tbody>
        {
          loading &&
          <tr>
            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
              <h3>
                {t(TXT_LOADING).toUpperCase()}
              </h3>
            </td>
          </tr>
        }
        {
          !loading && error && error.message &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {error.message}
              </CustomError>
            </td>
          </tr>
        }
        {
          !loading && data &&
          data.boreholes && data.boreholes.items &&
          data.boreholes.items.length > 0 &&
          data.boreholes.items.map((borehole) => (
            <tr key={`row-${borehole.id}`}>
              <td>
                {borehole.code}
              </td>
              <td>
                {borehole.prospect && borehole.prospect.block && borehole.prospect.block.label}
              </td>
              <td>
                {borehole.prospect && borehole.prospect.label}
              </td>
              <td>
                {borehole.length}
              </td>
              <td>
                {borehole.norting}
              </td>
              <td>
                {borehole.easting}
              </td>
              <td>
                {borehole.collar}
              </td>
              <td style={{ textAlign: 'center' }}>
                <span className={`badge ${borehole.status?.toString().includes('OPEN') ? 'bg-danger' : 'bg-success'} `}>{borehole.status}</span>
              </td>
              <td align='center'>
                <button
                  className='btn btn-primary btn-sm'
                  onClick={() => onDetail(borehole)}>
                  {t(TXT_DETAIL).toUpperCase()}
                </button>
              </td>
              <td align='center'>
                <button
                  data-target="#modal-default"
                  data-toggle="modal"
                  className='btn btn-danger btn-sm'
                  type="button"
                  onClick={() => onDelete(borehole)}>
                  {t(TXT_DELETE).toUpperCase()}
                </button>
              </td>
              <td align='center'>
                <button
                  className='btn btn-primary btn-sm'
                  onClick={() => onExport(borehole)}
                  type="button">
                  {t(TXT_EXPORT_DETAIL).toUpperCase()}
                </button>
              </td>
            </tr>
          ))
        }
        {
          !loading &&
          (!data ||
            !data.boreholes ||
            !data.boreholes.items ||
            data.boreholes.items.length <= 0) &&
          <tr>
            <td colSpan={columns.length}>
              {capitalizeFirstLetter(t(TXT_DATA_NOT_FOUND))}
            </td>
          </tr>
        }
      </tbody>
    );
  };
