/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { getApplicationRolesOutput } from '../../../../api';
import { applicationRole } from '../../../../entityModel';
import { manageRoleColumns } from './manageRolesColumns';
import { ManageRolesTableBody } from './ManageRolesTableBody';
import { ManageRolesTableHead } from './ManageRolesTableHead';

interface ManageRolesTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getApplicationRolesOutput;
  onEdit: (user: applicationRole) => void;
  onDelete: (user: applicationRole) => void;
}

export const ManageRolesTable = ({ error, loading, data, onEdit, onDelete, ...rest }: ManageRolesTableProps) => {
  return (
    <table className="table table-bordered">
      <ManageRolesTableHead
        columns={manageRoleColumns}
      />
      <ManageRolesTableBody
        columns={manageRoleColumns}
        loading={loading}
        error={error}
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </table>
  );
};
