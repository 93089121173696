/* eslint-disable max-len */
import { gql } from '@apollo/client';
import { boreholeQueryResult } from '../borehole';

export const geoPhotoQueryResult = `
  id
  code
  from
  to
  borehole {
    ${boreholeQueryResult}
  }
  filePath
`;

export const GET_GEO_PHOTO = gql`
  query getGeoPhotos($code: String, $skip: Int, $take: Int, $filter: GeoPhotoFilterInput) {
    geoPhotos(code: $code, skip: $skip, take: $take, where: $filter) {
      items {
        ${geoPhotoQueryResult}
      },
      totalCount
    }
  }
`;
