import { gql } from '@apollo/client';

export const structureQueryResult = `
  id,
  code,
  label
`;

export const GET_STRUCTURE = gql`
  query getStructures($code: String, $skip: Int, $take: Int) {
    structures(code: $code, skip: $skip, take: $take) {
      items {
        ${structureQueryResult}
      },
      totalCount
    }
  }
`;
