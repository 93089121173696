/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import { CustomError } from '../../../../components';
import { logHoleSize } from '../../../../entityModel';
import {
  TXT_DATA_NOT_FOUND,
  TXT_DELETE,
  TXT_EDIT,
  TXT_LOADING
} from '../../../../translations/translationConstants';
import { capitalizeFirstLetter } from '../../../../shared/stringFunctions';
import { ApolloError } from '@apollo/client';
import { ManageLogHoleSizeColumn } from './manageLogHoleSizeColumns';
import { getLogHoleSizeOutput } from '../../../../api';
import { memo } from 'react';

export interface ManageLogHoleSizesTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: getLogHoleSizeOutput;
  columns: ManageLogHoleSizeColumn[];
  onEdit: (logHoleSize: logHoleSize) => void;
  onDelete: (logHoleSize: logHoleSize) => void;
}

export const ManageLogHoleSizeTableBody =
  memo(({ error, columns, loading, data, onEdit, onDelete }: ManageLogHoleSizesTableBodyProps) => {
    const { t } = useTranslation();
    return (
      <tbody>
        {
          loading &&
          <tr>
            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
              <h3>
                {t(TXT_LOADING).toUpperCase()}
              </h3>
            </td>
          </tr>
        }
        {
          !loading && error && error.message &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {error.message}
              </CustomError>
            </td>
          </tr>
        }
        {
          !loading && data &&
          data.logHoleSizes && data.logHoleSizes.items &&
          data.logHoleSizes.items.length > 0 &&
          data.logHoleSizes.items.map((logHoleSize) => (
            <tr key={`row-${logHoleSize.id}`}>
              <td>
                {logHoleSize.holeSize && logHoleSize.holeSize.label}
              </td>
              <td>
                {logHoleSize.from}
              </td>
              <td>
                {logHoleSize.to}
              </td>
              <td align='center'>
                <button
                  data-target="#modal-logHoleSize-editor"
                  data-toggle="modal"
                  className='btn btn-primary btn-sm'
                  aria-label="edit logHoleSize"
                  onClick={() => onEdit(logHoleSize)}>
                  {t(TXT_EDIT).toUpperCase()}
                </button>
              </td>
              <td align='center'>
                <button
                  data-target="#modal-default"
                  data-toggle="modal"
                  className='btn btn-danger btn-sm'
                  aria-label="delete logHoleSize"
                  type="button"
                  onClick={() => onDelete(logHoleSize)}>
                  {t(TXT_DELETE).toUpperCase()}
                </button>
              </td>
            </tr>
          ))
        }
        {
          !loading &&
          (!data ||
            !data.logHoleSizes ||
            !data.logHoleSizes.items ||
            data.logHoleSizes.items.length <= 0) &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {capitalizeFirstLetter(t(TXT_DATA_NOT_FOUND))}
              </CustomError>
            </td>
          </tr>
        }
      </tbody>
    );
  });

ManageLogHoleSizeTableBody.displayName = 'ManageLogHoleSizeTableBody';
