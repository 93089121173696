/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import { CustomError } from '../../../../components';
import { perpancingan } from '../../../../entityModel';
import {
  TXT_DATA_NOT_FOUND,
  TXT_DELETE,
  TXT_EDIT,
  TXT_LOADING
} from '../../../../translations/translationConstants';
import { capitalizeFirstLetter } from '../../../../shared/stringFunctions';
import { ApolloError } from '@apollo/client';
import { ManagePerpancinganColumn } from './managePerpancinganColumns';
import { memo } from 'react';
import { getPerpancinganOutput } from '../../../../api';
import moment from 'moment';

export interface ManagePerpancinganTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: getPerpancinganOutput;
  columns: ManagePerpancinganColumn[];
  onEdit: (perpancingan: perpancingan) => void;
  onDelete: (perpancingan: perpancingan) => void;
}

export const ManagePerpancinganTableBody =
  memo(({ error, columns, loading, data, onEdit, onDelete }: ManagePerpancinganTableBodyProps) => {
    const { t } = useTranslation();
    return (
      <tbody>
        {
          loading &&
          <tr>
            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
              <h3>
                {t(TXT_LOADING).toUpperCase()}
              </h3>
            </td>
          </tr>
        }
        {
          !loading && error && error.message &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {error.message}
              </CustomError>
            </td>
          </tr>
        }
        {
          !loading && data &&
          data.perpancingan && data.perpancingan.items &&
          data.perpancingan.items.length > 0 &&
          data.perpancingan.items.map((perpancingan, index) => {
            return (
              <tr key={`row-${perpancingan.id}`}>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {perpancingan.from}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {perpancingan.to}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {perpancingan.drillProgress}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {perpancingan.startDate && moment(perpancingan.startDate).format('D MMM Y')}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {perpancingan.finishDate && moment(perpancingan.finishDate).format('D MMM Y')}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {perpancingan.totalTime}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {perpancingan.coreLength}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {perpancingan.coreRec}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {perpancingan.coreLose}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {perpancingan.dSize}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {perpancingan.drillRpm}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {perpancingan.pumpRpm}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {perpancingan.drillWater}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {perpancingan.rockFormation}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {perpancingan.remarks}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {perpancingan.drillTechnique}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <button
                    data-target="#modal-perpancingan-editor"
                    data-toggle="modal"
                    className='btn btn-primary btn-sm'
                    aria-label="edit perpancingan"
                    onClick={() => onEdit(perpancingan)}>
                    {t(TXT_EDIT).toUpperCase()}
                  </button>
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <button
                    data-target="#modal-default"
                    data-toggle="modal"
                    className='btn btn-danger btn-sm'
                    aria-label="delete perpancingan"
                    type="button"
                    onClick={() => onDelete(perpancingan)}>
                    {t(TXT_DELETE).toUpperCase()}
                  </button>
                </td>
              </tr>
            );
          })
        }
        {
          !loading &&
          (!data ||
            !data.perpancingan ||
            !data.perpancingan.items ||
            data.perpancingan.items.length <= 0) &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {capitalizeFirstLetter(t(TXT_DATA_NOT_FOUND))}
              </CustomError>
            </td>
          </tr>
        }
      </tbody>
    );
  });

ManagePerpancinganTableBody.displayName = 'ManagePerpancinganTableBody';
