/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { memo } from 'react';
import { getGeotechnicalDataOutput } from '../../../../api';
import { geotechnicalData } from '../../../../entityModel';
import { manageGeotechnicalDataColumns } from './manageGeotechnicalDataColumns';
import { ManageGeotechnicalDataTableBody } from './ManageGeotechnicalDataTableBody';
import { ManageGeotechnicalDataTableHead } from './ManageGeotechnicalDataTableHead';

interface ManageGeotechnicalDatasTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getGeotechnicalDataOutput;
  onEdit: (user: geotechnicalData) => void;
  onDelete: (user: geotechnicalData) => void;
}

export const ManageGeotechnicalDatasTable = memo(({ error, loading, data, onEdit, onDelete, ...rest }: ManageGeotechnicalDatasTableProps) => {
  return (
    <table className="table table-bordered">
      <ManageGeotechnicalDataTableHead
        columns={manageGeotechnicalDataColumns}
      />
      <ManageGeotechnicalDataTableBody
        columns={manageGeotechnicalDataColumns}
        loading={loading}
        error={error}
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </table>
  );
});

ManageGeotechnicalDatasTable.displayName = 'ManageGeoGeotechnicalDatasTable';

