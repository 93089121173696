import { gql } from '@apollo/client';

export const alterationQueryResult = `
  id,
  order,
  code,
  label
`;

export const GET_ALTERATIONS = gql`
  query getAlterations($code: String, $skip: Int, $take: Int) {
    alterations(code: $code, skip: $skip, take: $take) {
      items {
        ${alterationQueryResult}
      },
      totalCount
    }
  }
`;
