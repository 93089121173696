import {
  TXT_DELETE,
  TXT_EDIT,
  TXT_NAME
} from '../../../../translations/translationConstants';

export interface ManageRolesColumn {
  id: 'name' | 'edit' | 'delete';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

export const manageRoleColumns: ManageRolesColumn[] = [
  {
    id: 'name',
    label: TXT_NAME,
    maxWidth: '90%',
    align: 'left'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '5%'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '5%'
  }
];
