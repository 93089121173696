import { gql } from '@apollo/client';

export const weatheringQueryResult = `
  id,
  order,
  code,
  label
`;

export const GET_WEATHERING = gql`
  query getWeatherings($code: String, $skip: Int, $take: Int) {
    weathering(code: $code, skip: $skip, take: $take) {
      items {
        ${weatheringQueryResult}
      },
      totalCount
    }
  }
`;
