import {
  TXT_AZIMUTH,
  TXT_DELETE,
  TXT_DEPTH,
  TXT_DIP,
  TXT_EDIT
} from '../../../../translations/translationConstants';

export interface ManageSurveyDataColumn {
  id: 'depth' | 'azimuth' | 'dip' | 'edit' | 'delete';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

export const manageSurveyDataColumns: ManageSurveyDataColumn[] = [
  {
    id: 'depth',
    label: TXT_DEPTH,
    maxWidth: '30%',
    align: 'left'
  },
  {
    id: 'azimuth',
    label: TXT_AZIMUTH,
    maxWidth: '30%',
    align: 'left'
  },
  {
    id: 'dip',
    label: TXT_DIP,
    maxWidth: '30%',
    align: 'left'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '5%'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '5%'
  }
];
