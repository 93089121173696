/* eslint-disable max-len */
import {
  TXT_ALPHA,
  TXT_BETA,
  TXT_DEFECT_TYPE,
  TXT_DELETE,
  TXT_DEPTH,
  TXT_DIP,
  TXT_DIR,
  TXT_EDIT,
  TXT_FROM,
  TXT_HEALED,
  TXT_INFILL_ALTERATION,
  TXT_INFILL_MINERAL,
  TXT_INFILL_WIDTH,
  TXT_JA,
  TXT_JN,
  TXT_JR,
  TXT_NO_DEFECT,
  TXT_NO_DEFECT_SETS,
  TXT_ORIENTATION,
  TXT_ORI_QUALITY,
  TXT_QUANTITY,
  TXT_ROUGHNESS,
  TXT_SRF,
  TXT_STRUCTURE,
  TXT_TO
} from '../../../../translations/translationConstants';

export interface ManageGeotechnicalDataColumn {
  id: 'from' | 'to' | 'structure' | 'dip' | 'dir' | 'noDefectSet' | 'jn' | 'noDefect' |
  'depth' | 'defectType' | 'quantity' | 'alpha' | 'beta' | 'roughness' | 'jr' | 'infillAlteration' |
  'ja' | 'srf' | 'infillMineral' | 'infillWidth' | 'healed' | 'orientation' | 'oriQuality' |
  'edit' | 'delete';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

export const manageGeotechnicalDataColumns: ManageGeotechnicalDataColumn[] = [
  {
    id: 'from',
    label: TXT_FROM,
    maxWidth: '20%',
    align: 'center'
  },
  {
    id: 'to',
    label: TXT_TO,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'structure',
    label: TXT_STRUCTURE,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'dip',
    label: TXT_DIP,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'dir',
    label: TXT_DIR,
    maxWidth: '35%',
    align: 'center'
  },
  {
    id: 'noDefectSet',
    label: TXT_NO_DEFECT_SETS,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'jn',
    label: TXT_JN,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'noDefect',
    label: TXT_NO_DEFECT,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'depth',
    label: TXT_DEPTH,
    maxWidth: '35%',
    align: 'center'
  },
  {
    id: 'defectType',
    label: TXT_DEFECT_TYPE,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'quantity',
    label: TXT_QUANTITY,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'alpha',
    label: TXT_ALPHA,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'beta',
    label: TXT_BETA,
    maxWidth: '35%',
    align: 'center'
  },
  {
    id: 'roughness',
    label: TXT_ROUGHNESS,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'jr',
    label: TXT_JR,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'infillAlteration',
    label: TXT_INFILL_ALTERATION,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'ja',
    label: TXT_JA,
    maxWidth: '35%',
    align: 'center'
  },
  {
    id: 'srf',
    label: TXT_SRF,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'infillMineral',
    label: TXT_INFILL_MINERAL,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'infillWidth',
    label: TXT_INFILL_WIDTH,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'healed',
    label: TXT_HEALED,
    maxWidth: '35%',
    align: 'center'
  },
  {
    id: 'orientation',
    label: TXT_ORIENTATION,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'oriQuality',
    label: TXT_ORI_QUALITY,
    maxWidth: '35%',
    align: 'center'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '10%',
    align: 'center'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '10%',
    align: 'center'
  }
];
