/* eslint-disable max-len */
import { gql } from '@apollo/client';

export const perpancinganQueryResult = `
  id
  code
  from
  to
  drillProgress
  startDate
  finishDate
  totalTime
  coreLength
  coreRec
  coreLose
  dSize
  drillRpm
  pumpRpm
  drillWater
  rockFormation
  remarks
  drillTechnique
`;

export const GET_PERPANCINGAN = gql`
  query getPerpancingan($code: String, $skip: Int, $take: Int, $filter: PerpancinganFilterInput) {
    perpancingan(code: $code, skip: $skip, take: $take, where: $filter) {
      items {
        ${perpancinganQueryResult}
      },
      totalCount
    }
  }
`;
