/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import { CustomError, ManageOrderCodeLabelColumn } from '../../../../components';
import { lithology } from '../../../../entityModel';
import {
  TXT_DATA_NOT_FOUND,
  TXT_DELETE,
  TXT_EDIT,
  TXT_LOADING
} from '../../../../translations/translationConstants';
import { capitalizeFirstLetter } from '../../../../shared/stringFunctions';
import { getLithologyOutput } from '../../../../api';
import { ApolloError } from '@apollo/client';

export interface ManageLithologysTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: getLithologyOutput;
  columns: ManageOrderCodeLabelColumn[];
  onEdit: (lithology: lithology) => void;
  onDelete: (lithology: lithology) => void;
}

export const ManageLithologysTableBody =
  ({ error, columns, loading, data, onEdit, onDelete }: ManageLithologysTableBodyProps) => {
    const { t } = useTranslation();
    return (
      <tbody>
        {
          loading &&
          <tr>
            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
              <h3>
                {t(TXT_LOADING).toUpperCase()}
              </h3>
            </td>
          </tr>
        }
        {
          !loading && error && error.message &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {error.message}
              </CustomError>
            </td>
          </tr>
        }
        {
          !loading && data &&
          data.lithologies && data.lithologies.items &&
          data.lithologies.items.length > 0 &&
          data.lithologies.items.map((lithology) => (
            <tr key={`row-${lithology.id}`}>
              <td>
                {lithology.order}
              </td>
              <td>
                {lithology.code}
              </td>
              <td>
                {lithology.label}
              </td>
              <td align='center'>
                <button
                  data-target="#modal-lithology-editor"
                  data-toggle="modal"
                  className='btn btn-primary'
                  aria-label="edit lithology"
                  onClick={() => onEdit(lithology)}>
                  {t(TXT_EDIT).toUpperCase()}
                </button>
              </td>
              <td align='center'>
                <button
                  data-target="#modal-default"
                  data-toggle="modal"
                  className='btn btn-danger'
                  aria-label="delete lithology"
                  type="button"
                  onClick={() => onDelete(lithology)}>
                  {t(TXT_DELETE).toUpperCase()}
                </button>
              </td>
            </tr>
          ))
        }
        {
          !loading &&
          (!data ||
            !data.lithologies ||
            !data.lithologies.items ||
            data.lithologies.items.length <= 0) &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {capitalizeFirstLetter(t(TXT_DATA_NOT_FOUND))}
              </CustomError>
            </td>
          </tr>
        }
      </tbody>
    );
  };
