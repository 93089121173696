import { gql } from '@apollo/client';
import { prospectQueryResult } from './prospectQueries';

export const ADD_PROSPECT = gql`
  mutation addProspect($input: AddProspectInput!) {
    addProspect(input: $input){
      ${prospectQueryResult}
    }
  }
`;

export const UPDATE_PROSPECT = gql`
  mutation updateProspect($input: UpdateProspectInput!) {
    updateProspect(input: $input){
      ${prospectQueryResult}
    }
  }
`;

export const DELETE_PROSPECT = gql`
  mutation deleteProspect($input: DeleteProspectInput!) {
    deleteProspect(input: $input)
  }
`;
