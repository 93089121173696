/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import { CustomError } from '../../../../components';
import { assay } from '../../../../entityModel';
import {
  TXT_DATA_NOT_FOUND,
  TXT_DELETE,
  TXT_EDIT,
  TXT_LOADING,
  TXT_PHOTO
} from '../../../../translations/translationConstants';
import { capitalizeFirstLetter } from '../../../../shared/stringFunctions';
import { ApolloError } from '@apollo/client';
import { ManageAssayColumn } from './manageAssayColumns';
import { memo } from 'react';
import { getAssayOutput } from '../../../../api';

export interface ManageAssaysTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: getAssayOutput;
  columns: ManageAssayColumn[];
  onEdit: (assay: assay) => void;
  onDelete: (assay: assay) => void;
  onOpenPhoto: (assay: assay) => void;
}

export const ManageAssayTableBody =
  memo(({ error, columns, loading, data, onEdit, onDelete, onOpenPhoto }: ManageAssaysTableBodyProps) => {
    const { t } = useTranslation();
    return (
      <tbody>
        {
          loading &&
          <tr>
            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
              <h3>
                {t(TXT_LOADING).toUpperCase()}
              </h3>
            </td>
          </tr>
        }
        {
          !loading && error && error.message &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {error.message}
              </CustomError>
            </td>
          </tr>
        }
        {
          !loading && data &&
          data.assays && data.assays.items &&
          data.assays.items.length > 0 &&
          data.assays.items.map((assay, index) => {
            return (
              <tr key={`row-${assay.id}`}>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {assay.drillCard}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {assay.geologicalData && assay.geologicalData.from}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {assay.geologicalData && assay.geologicalData.to}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {assay.geologicalData && assay.geologicalData.coreLength}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {assay.remarks}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <button
                    data-target="#modal-assay-editor"
                    data-toggle="modal"
                    className='btn btn-primary btn-sm'
                    aria-label="edit assay"
                    onClick={() => onEdit(assay)}>
                    {t(TXT_EDIT).toUpperCase()}
                  </button>
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <button
                    data-target="#modal-default"
                    data-toggle="modal"
                    className='btn btn-danger btn-sm'
                    aria-label="delete assay"
                    type="button"
                    onClick={() => onDelete(assay)}>
                    {t(TXT_DELETE).toUpperCase()}
                  </button>
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <button
                    data-target="#modal-photo-default"
                    data-toggle="modal"
                    className='btn btn-default btn-sm'
                    aria-label="photo assay"
                    type="button"
                    onClick={() => onOpenPhoto(assay)}>
                    {t(TXT_PHOTO).toUpperCase()}
                  </button>
                </td>
              </tr>
            );
          })
        }
        {
          !loading &&
          (!data ||
            !data.assays ||
            !data.assays.items ||
            data.assays.items.length <= 0) &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {capitalizeFirstLetter(t(TXT_DATA_NOT_FOUND))}
              </CustomError>
            </td>
          </tr>
        }
      </tbody>
    );
  });

ManageAssayTableBody.displayName = 'ManageAssayTableBody';
