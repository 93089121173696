/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import {
  capitalizeFirstLetterEveryword
} from '../../../shared/stringFunctions';
import { TXT_ADD_HOLE_SIZE, TXT_HOLE_SIZES } from '../../../translations/translationConstants';
import {
  TXT_SEARCH_BY_NAME
} from '../../../translations/translationConstants';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import {
  addHoleSizeInput,
  deleteHoleSizeInput,
  getHoleSizesInput,
  getHoleSizeOutput,
  GET_HOLE_SIZE,
  updateHoleSizeInput
} from '../../../api';
import { useEffect } from 'react';
import { useState } from 'react';
import { ManageHoleSizesTable } from './ManageHoleSizesTable';
import {
  DeleteConfirmation,
  ExportToExcel,
  globalPageSizeOptions,
  PaginationBar
} from '../../../components';
import { holeSize } from '../../../entityModel';
import { useApolloErrorHandler, useAuthServices } from '../../../hooks';
import {
  ADD_HOLE_SIZE,
  DELETE_HOLE_SIZE,
  UPDATE_HOLE_SIZE
} from '../../../api';
import { ManageHoleSizeEditor } from './ManagerHoleSizeEditor';

export const ManageHoleSizes = () => {
  const { t } = useTranslation();
  const { isSignedIn } = useAuthServices();

  const [txtSearch, setTxtSearch] = useState('');
  const [alterationData, setAlterationData] = useState<holeSize[] | undefined>([]);

  const onTxtSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTxtSearch(event.currentTarget.value);
  };

  const [selectedHoleSize, setSelectedHoleSize] = useState<holeSize | undefined>(undefined);
  const [getHoleSizes, { loading, data, error, refetch }] = useLazyQuery<getHoleSizeOutput>(GET_HOLE_SIZE);
  const [getAllAlterations] = useLazyQuery<getHoleSizeOutput>(GET_HOLE_SIZE, {
    onCompleted: (data) => setAlterationData(data.holeSizes.items)
  });

  useEffect(() => {
    getHoleSizes();
    getAllAlterations();
  }, []);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(globalPageSizeOptions[0]);

  const [openEditor, setOpenEditor] = useState(false);
  const [savingError, setSavingError] = useState<string | undefined>(undefined);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const lblSearch = capitalizeFirstLetterEveryword(t(TXT_SEARCH_BY_NAME));

  const fetchHoleSizes = () => {
    const variables: getHoleSizesInput = {
      code: txtSearch,
      take: pageSize,
      skip: (pageNumber - 1) * pageSize
    };
    getHoleSizes({
      variables
    });
  };

  const fetch = (resetPage?: boolean) => {
    if (!!resetPage && pageNumber !== 1) {
      setPageNumber(1);
    } else {
      fetchHoleSizes();
    }
  };

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, error);
  }, [error]);

  useEffect(() => {
    fetch();
  }, [pageNumber]);

  useEffect(() => {
    fetch(true);
  }, [pageSize, txtSearch]);

  const openEditHoleSizeEditor = (holeSize: holeSize) => {
    setSelectedHoleSize(holeSize);
    setOpenEditor(true);
  };

  const openDeleteHoleSizeConfirmation = (holeSize: holeSize) => {
    setSelectedHoleSize(holeSize);
    setOpenDeleteConfirmation(true);
  };

  const openAddHoleSizeEditor = () => {
    setSelectedHoleSize(undefined);
    setOpenEditor(true);
  };

  const closeEditor = () => {
    setOpenEditor(false);
  };

  const afterAddingOrUpdatingHoleSize = () => {
    setOpenEditor(false);
    if (refetch) {
      refetch();
    }
  };

  const handleAddingOrUpdatingHoleSizeError = (error: ApolloError) => {
    if (error.graphQLErrors.length > 0 &&
      !!error.graphQLErrors[0].extensions) {
      setSavingError(error.graphQLErrors[0].extensions['message']);
    } else {
      setSavingError(error.message);
    }
  };

  const [addHoleSize, addHoleSizeOperation] = useMutation<holeSize>(ADD_HOLE_SIZE, {
    onError: (e) => handleAddingOrUpdatingHoleSizeError(e),
    onCompleted: (result) => afterAddingOrUpdatingHoleSize()
  });

  const [updateHoleSize, updateHoleSizeOperation] = useMutation<holeSize>(UPDATE_HOLE_SIZE, {
    onError: (e) => handleAddingOrUpdatingHoleSizeError(e),
    onCompleted: (result) => afterAddingOrUpdatingHoleSize()
  });

  const onEditorSave = (payload: addHoleSizeInput | updateHoleSizeInput) => {
    if (!selectedHoleSize) {
      addHoleSize({
        variables: {
          input: payload
        }
      });
    } else {
      updateHoleSize({
        variables: {
          input: payload
        }
      });
    }
  };

  const onDeleteCancel = () => setOpenDeleteConfirmation(false);

  const [deleteHoleSize, deleteHoleSizeOperation] = useMutation<boolean>(DELETE_HOLE_SIZE, {
    onError: (e) => { },
    onCompleted: (result) => {
      if (data && refetch) {
        if (data.holeSizes.items.length == 1) {
          const targetPage = pageNumber - 1 <= 0 ? 1 : pageNumber - 1;
          if (pageNumber !== targetPage) {
            setPageNumber(targetPage);
          } else {
            refetch();
          }
        } else {
          refetch();
        }
      }
      onDeleteCancel();
    }
  });

  const onDelete = () => {
    const input: deleteHoleSizeInput = {
      id: selectedHoleSize!.id!
    };
    deleteHoleSize({
      variables: {
        input
      }
    });
  };

  const onPageSizeChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(+event.target.value);
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{t(capitalizeFirstLetterEveryword(TXT_HOLE_SIZES))}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          {
            openEditor &&
            <ManageHoleSizeEditor
              holeSize={selectedHoleSize}
              onClose={closeEditor}
              onSave={onEditorSave}
              isSaving={
                !selectedHoleSize ? addHoleSizeOperation.loading :
                  updateHoleSizeOperation.loading
              }
              savingError={savingError}
            />
          }
          {
            openDeleteConfirmation &&
            <DeleteConfirmation
              loading={deleteHoleSizeOperation.loading}
              onYes={onDelete}
              onNo={onDeleteCancel}
              objectName={selectedHoleSize?.label || ''}
            />
          }
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className='row'>
                    <div className='col-6'>
                      <div className="form-group">
                        <label>{lblSearch}</label>
                        <input type='text' className='form-control' value={txtSearch} onChange={onTxtSearchChanged} />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        className='btn btn-primary'
                        data-target="#modal-holeSize-editor"
                        data-toggle="modal"
                        disabled={loading}
                        onClick={openAddHoleSizeEditor}>
                        {t(TXT_ADD_HOLE_SIZE).toUpperCase()}
                      </button>
                    </div>
                    <div className="col-md-6" style={{ textAlign: 'right' }}>
                      <ExportToExcel filename={TXT_HOLE_SIZES} data={alterationData} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ManageHoleSizesTable
                    loading={loading}
                    data={data}
                    error={error}
                    onEdit={openEditHoleSizeEditor}
                    onDelete={openDeleteHoleSizeConfirmation}
                  />
                </div>
                <PaginationBar
                  currentPage={pageNumber}
                  totalCount={data?.holeSizes.totalCount}
                  pageSize={pageSize}
                  onPageChanged={(page) => setPageNumber(page)}
                  onChangeRowsPerPage={onPageSizeChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
