/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { memo } from 'react';
import { getGeoPhotoOutput } from '../../../../api';
import { geoPhoto } from '../../../../entityModel';
import { managePhotoColumns } from './managePhotoColumns';
import { ManagePhotoTableBody } from './ManagePhotoTableBody';
import { ManagePhotoTableHead } from './ManagePhotoTableHead';

interface ManageGeoPhotosTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getGeoPhotoOutput;
  onEdit: (user: geoPhoto) => void;
  onDelete: (user: geoPhoto) => void;
}

export const ManagePhotosTable = memo(({ error, loading, data, onEdit, onDelete, ...rest }: ManageGeoPhotosTableProps) => {
  return (
    <table className="table table-bordered">
      <ManagePhotoTableHead
        columns={managePhotoColumns}
      />
      <ManagePhotoTableBody
        columns={managePhotoColumns}
        loading={loading}
        error={error}
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </table>
  );
});

ManagePhotosTable.displayName = 'ManageGeoPhotosTable';

