/* eslint-disable max-len */
import { gql } from '@apollo/client';
import { geologicalDataQueryResult } from '../../main/geologicalData';
import { boreholeQueryResult } from '../borehole';

export const assayQueryResult = `
  id
  code
  drillCard
  remarks
  borehole {
    ${boreholeQueryResult}
  }
  geologicalData {
    ${geologicalDataQueryResult}
  }
  filePath
`;

export const GET_ASSAY = gql`
  query getAssays($code: String, $skip: Int, $take: Int, $filter: AssayFilterInput) {
    assays(code: $code, skip: $skip, take: $take, where: $filter) {
      items {
        ${assayQueryResult}
      },
      totalCount
    }
  }
`;
