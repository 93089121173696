import { gql } from '@apollo/client';
import { strengthQueryResult } from './strengthQueries';

export const ADD_STRENGTH = gql`
  mutation addStrength($input: AddStrengthInput!) {
    addStrength(input: $input){
      ${strengthQueryResult}
    }
  }
`;

export const UPDATE_STRENGTH = gql`
  mutation updateStrength($input: UpdateStrengthInput!) {
    updateStrength(input: $input){
      ${strengthQueryResult}
    }
  }
`;

export const DELETE_STRENGTH = gql`
  mutation deleteStrength($input: DeleteStrengthInput!) {
    deleteStrength(input: $input)
  }
`;
