import axios from 'axios';
import { apiUrl } from '../../../shared/links';

export const excelBoreholeDetailService = (token: string, id: string) => {
  const exportExcelUrl = `${apiUrl}/borehole/Excel/${id}`;
  try {
    return axios.get(exportExcelUrl, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      responseType: 'blob'
    }).then((response) => {
      return new Blob([response.data]);
    });
  } catch (ex) {
    if (!ex.response || !ex.response.data) {
      console.log(ex);
    }
    console.log(ex.response.data);
  }
};
