import { atom } from 'recoil';
import appLocalStorage from '../shared/appLocalStorage';

const darkModeStateKey = 'darkMode';

const darkModeState = atom({
  key: darkModeStateKey,
  default: appLocalStorage.darkMode
});

export default darkModeState;
