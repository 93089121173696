import { gql } from '@apollo/client';

export const defectTypeQueryResult = `
  id,
  code,
  label
`;

export const GET_DEFECT_TYPE = gql`
  query getDefectTypes($code: String, $skip: Int, $take: Int) {
    defectTypes(code: $code, skip: $skip, take: $take) {
      items {
        ${defectTypeQueryResult}
      },
      totalCount
    }
  }
`;
