/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import { CustomError } from '../../../../components';
import { surveyData } from '../../../../entityModel';
import {
  TXT_DATA_NOT_FOUND,
  TXT_DELETE,
  TXT_EDIT,
  TXT_LOADING
} from '../../../../translations/translationConstants';
import { capitalizeFirstLetter } from '../../../../shared/stringFunctions';
import { ApolloError } from '@apollo/client';
import { ManageSurveyDataColumn } from './manageSurveyDataColumns';
import { getSurveyDataOutput } from '../../../../api';
import { memo } from 'react';

export interface ManageSurveyDatasTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: getSurveyDataOutput;
  columns: ManageSurveyDataColumn[];
  onEdit: (surveyData: surveyData) => void;
  onDelete: (surveyData: surveyData) => void;
}

export const ManageSurveyDataTableBody =
  memo(({ error, columns, loading, data, onEdit, onDelete }: ManageSurveyDatasTableBodyProps) => {
    const { t } = useTranslation();
    return (
      <tbody>
        {
          loading &&
          <tr>
            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
              <h3>
                {t(TXT_LOADING).toUpperCase()}
              </h3>
            </td>
          </tr>
        }
        {
          !loading && error && error.message &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {error.message}
              </CustomError>
            </td>
          </tr>
        }
        {
          !loading && data &&
          data.surveyDatas && data.surveyDatas.items &&
          data.surveyDatas.items.length > 0 &&
          data.surveyDatas.items.map((surveyData) => (
            <tr key={`row-${surveyData.id}`}>
              <td>
                {surveyData.depth}
              </td>
              <td>
                {surveyData.azimuth}
              </td>
              <td>
                {surveyData.dip}
              </td>
              <td align='center'>
                <button
                  data-target="#modal-surveyData-editor"
                  data-toggle="modal"
                  className='btn btn-primary btn-sm'
                  aria-label="edit surveyData"
                  onClick={() => onEdit(surveyData)}>
                  {t(TXT_EDIT).toUpperCase()}
                </button>
              </td>
              <td align='center'>
                <button
                  data-target="#modal-default"
                  data-toggle="modal"
                  className='btn btn-danger btn-sm'
                  aria-label="delete surveyData"
                  type="button"
                  onClick={() => onDelete(surveyData)}>
                  {t(TXT_DELETE).toUpperCase()}
                </button>
              </td>
            </tr>
          ))
        }
        {
          !loading &&
          (!data ||
            !data.surveyDatas ||
            !data.surveyDatas.items ||
            data.surveyDatas.items.length <= 0) &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {capitalizeFirstLetter(t(TXT_DATA_NOT_FOUND))}
              </CustomError>
            </td>
          </tr>
        }
      </tbody>
    );
  });

ManageSurveyDataTableBody.displayName = 'ManageSurveyDataTableBody';
