/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import {
  capitalizeFirstLetterEveryword
} from '../../../shared/stringFunctions';
import { TXT_ADD_PROSPECT, TXT_PROSPECT, TXT_PROSPECTS } from '../../../translations/translationConstants';
import {
  TXT_SEARCH_BY_NAME
} from '../../../translations/translationConstants';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import {
  addProspectInput,
  deleteProspectInput,
  getLithologiesInput,
  getProspectOutput,
  GET_PROSPECT,
  updateProspectInput
} from '../../../api';
import { useEffect } from 'react';
import { useState } from 'react';
import { ManageProspectsTable } from './ManageProspectTable';
import {
  DeleteConfirmation,
  ExportToExcel,
  globalPageSizeOptions,
  PaginationBar
} from '../../../components';
import { prospect } from '../../../entityModel';
import { useApolloErrorHandler, useAuthServices } from '../../../hooks';
import {
  ADD_PROSPECT,
  DELETE_PROSPECT,
  UPDATE_PROSPECT
} from '../../../api';
import { ManageProspectEditor } from './ManagerProspectEditor';

export const ManageProspects = () => {
  const { t } = useTranslation();
  const { isSignedIn } = useAuthServices();

  const [txtSearch, setTxtSearch] = useState('');
  const [alterationData, setAlterationData] = useState<prospect[] | undefined>([]);

  const onTxtSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTxtSearch(event.currentTarget.value);
  };

  const [selectedProspect, setSelectedProspect] = useState<prospect | undefined>(undefined);
  const [getProspects, { loading, data, error, refetch }] = useLazyQuery<getProspectOutput>(GET_PROSPECT);
  const [getAllAlterations] = useLazyQuery<getProspectOutput>(GET_PROSPECT, {
    onCompleted: (data) => setAlterationData(data.prospects.items)
  });

  useEffect(() => {
    getProspects();
    getAllAlterations();
  }, []);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(globalPageSizeOptions[0]);

  const [openEditor, setOpenEditor] = useState(false);
  const [savingError, setSavingError] = useState<string | undefined>(undefined);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const lblSearch = capitalizeFirstLetterEveryword(t(TXT_SEARCH_BY_NAME));

  const fetchProspects = () => {
    const variables: getLithologiesInput = {
      code: txtSearch,
      take: pageSize,
      skip: (pageNumber - 1) * pageSize
    };
    getProspects({
      variables
    });
  };

  const fetch = (resetPage?: boolean) => {
    if (!!resetPage && pageNumber !== 1) {
      setPageNumber(1);
    } else {
      fetchProspects();
    }
  };

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, error);
  }, [error]);

  useEffect(() => {
    fetch();
  }, [pageNumber]);

  useEffect(() => {
    fetch(true);
  }, [pageSize, txtSearch]);

  const openEditProspectEditor = (prospect: prospect) => {
    setSelectedProspect(prospect);
    setOpenEditor(true);
  };

  const openDeleteProspectConfirmation = (prospect: prospect) => {
    setSelectedProspect(prospect);
    setOpenDeleteConfirmation(true);
  };

  const openAddProspectEditor = () => {
    setSelectedProspect(undefined);
    setOpenEditor(true);
  };

  const closeEditor = () => {
    setOpenEditor(false);
  };

  const afterAddingOrUpdatingProspect = () => {
    setOpenEditor(false);
    if (refetch) {
      refetch();
    }
  };

  const handleAddingOrUpdatingProspectError = (error: ApolloError) => {
    if (error.graphQLErrors.length > 0 &&
      !!error.graphQLErrors[0].extensions) {
      setSavingError(error.graphQLErrors[0].extensions['message']);
    } else {
      setSavingError(error.message);
    }
  };

  const [addProspect, addProspectOperation] = useMutation<prospect>(ADD_PROSPECT, {
    onError: (e) => handleAddingOrUpdatingProspectError(e),
    onCompleted: (result) => afterAddingOrUpdatingProspect()
  });

  const [updateProspect, updateProspectOperation] = useMutation<prospect>(UPDATE_PROSPECT, {
    onError: (e) => handleAddingOrUpdatingProspectError(e),
    onCompleted: (result) => afterAddingOrUpdatingProspect()
  });

  const onEditorSave = (payload: addProspectInput | updateProspectInput) => {
    if (!selectedProspect) {
      addProspect({
        variables: {
          input: payload
        }
      });
    } else {
      updateProspect({
        variables: {
          input: payload
        }
      });
    }
  };

  const onDeleteCancel = () => setOpenDeleteConfirmation(false);

  const [deleteProspect, deleteProspectOperation] = useMutation<boolean>(DELETE_PROSPECT, {
    onError: (e) => { },
    onCompleted: (result) => {
      if (data && refetch) {
        if (data.prospects.items.length == 1) {
          const targetPage = pageNumber - 1 <= 0 ? 1 : pageNumber - 1;
          if (pageNumber !== targetPage) {
            setPageNumber(targetPage);
          } else {
            refetch();
          }
        } else {
          refetch();
        }
      }
      onDeleteCancel();
    }
  });

  const onDelete = () => {
    const input: deleteProspectInput = {
      id: selectedProspect!.id!
    };
    deleteProspect({
      variables: {
        input
      }
    });
  };

  const onPageSizeChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(+event.target.value);
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{t(capitalizeFirstLetterEveryword(TXT_PROSPECTS))}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          {
            openEditor &&
            <ManageProspectEditor
              prospect={selectedProspect}
              onClose={closeEditor}
              onSave={onEditorSave}
              isSaving={
                !selectedProspect ? addProspectOperation.loading :
                  updateProspectOperation.loading
              }
              savingError={savingError}
            />
          }
          {
            openDeleteConfirmation &&
            <DeleteConfirmation
              loading={deleteProspectOperation.loading}
              onYes={onDelete}
              onNo={onDeleteCancel}
              objectName={selectedProspect?.label || ''}
            />
          }
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className='row'>
                    <div className='col-6'>
                      <div className="form-group">
                        <label>{lblSearch}</label>
                        <input type='text' className='form-control' value={txtSearch} onChange={onTxtSearchChanged} />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        className='btn btn-primary'
                        data-target="#modal-prospect-editor"
                        data-toggle="modal"
                        disabled={loading}
                        onClick={openAddProspectEditor}>
                        {t(TXT_ADD_PROSPECT).toUpperCase()}
                      </button>
                    </div>
                    <div className="col-md-6" style={{ textAlign: 'right' }}>
                      <ExportToExcel filename={TXT_PROSPECT} data={alterationData} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ManageProspectsTable
                    loading={loading}
                    data={data}
                    error={error}
                    onEdit={openEditProspectEditor}
                    onDelete={openDeleteProspectConfirmation}
                  />
                </div>
                <PaginationBar
                  currentPage={pageNumber}
                  totalCount={data?.prospects.totalCount}
                  pageSize={pageSize}
                  onPageChanged={(page) => setPageNumber(page)}
                  onChangeRowsPerPage={onPageSizeChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
