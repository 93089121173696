
export const baseOrderCodeLabelQueryResult = `
  id,
  order,
  code,
  label
`;

export const baseCodeLabelQueryResult = `
  id,
  code,
  label
`;
