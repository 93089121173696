/* eslint-disable max-len */
import { gql } from '@apollo/client';
import { baseCodeLabelQueryResult } from '../../helper';

export const boreholeQueryResult = `
  id
  code
  projectCode
  projectNumber
  prospect{
    ${baseCodeLabelQueryResult}
    block{
      ${baseCodeLabelQueryResult}
    }
  }
  machine{
    ${baseCodeLabelQueryResult}
  }
  location
  norting
  easting
  collar
  collarAzimuth
  collarDip
  loggingStart
  loggingFinish
  drillStart
  drillFinish
  objective
  result
  significantGeology
  status
  length
`;

export const GET_BOREHOLE = gql`
  query getBoreholes($code: String, $skip: Int, $take: Int, $filter: BoreholeFilterInput) {
    boreholes(code: $code, skip: $skip, take: $take, where: $filter) {
      items {
        ${boreholeQueryResult}
      },
      totalCount
    }
  }
`;

