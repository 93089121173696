import { gql } from '@apollo/client';
import { boreholeQueryResult } from './boreholeQueries';

export const ADD_BOREHOLE = gql`
  mutation addBorehole($input: AddBoreholeInput!) {
    addBorehole(input: $input){
      ${boreholeQueryResult}
    }
  }
`;

export const UPDATE_BOREHOLE = gql`
  mutation updateBorehole($input: UpdateBoreholeInput!) {
    updateBorehole(input: $input){
      ${boreholeQueryResult}
    }
  }
`;

export const DELETE_BOREHOLE = gql`
  mutation deleteBorehole($input: DeleteBoreholeInput!) {
    deleteBorehole(input: $input)
  }
`;
