/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import { ManageOrderCodeLabelColumn } from '../../../../components';

export interface ManageWeatheringsTableHeadProps {
  columns: ManageOrderCodeLabelColumn[];
};

export const ManageWeatheringsTableHead = (props: ManageWeatheringsTableHeadProps) => {
  const { t } = useTranslation();
  return (
    <thead>
      <tr>
        {
          props.columns.map((col) => (
            <th
              key={`head-${col.id}`}
              style={{ width: col.maxWidth }}
            >
              {t(col.label).toUpperCase()}
            </th>
          ))
        }
      </tr>
    </thead>
  );
};
