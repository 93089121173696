import { useRecoilState } from 'recoil';
import { signInOutput } from '../api/auth/signIn';
import authState from '../recoil/authState';
import appLocalStorage, { setAppLocalStorage } from '../shared/appLocalStorage';

export const useAuth =
  (): [signInOutput, (value: signInOutput) => void] => {
    const [auth, setAuth] = useRecoilState(authState);

    const setAuthState = (value: signInOutput) => {
      setAuth({ ...value });
      setAppLocalStorage({
        ...appLocalStorage,
        ...value
      });
    };

    return [auth, setAuthState];
  };
