/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { memo } from 'react';
import { getPerpancinganOutput } from '../../../../api';
import { perpancingan } from '../../../../entityModel';
import { managePerpancinganColumns } from './managePerpancinganColumns';
import { ManagePerpancinganTableBody } from './ManagePerpancinganTableBody';
import { ManagePerpancinganTableHead } from './ManagePerpancinganTableHead';

interface ManagePerpancinganTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getPerpancinganOutput;
  onEdit: (user: perpancingan) => void;
  onDelete: (user: perpancingan) => void;
}

export const ManagePerpancinganTable = memo(({ error, loading, data, onEdit, onDelete, ...rest }: ManagePerpancinganTableProps) => {
  return (
    <table className="table table-bordered">
      <ManagePerpancinganTableHead
        columns={managePerpancinganColumns}
      />
      <ManagePerpancinganTableBody
        columns={managePerpancinganColumns}
        loading={loading}
        error={error}
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </table>
  );
});

ManagePerpancinganTable.displayName = 'ManagePerpancinganTable';

