import { atom } from 'recoil';
import appLocalStorage from '../shared/appLocalStorage';
import { signInOutput } from '../api/auth/signIn';

const authStateKey = 'auth';

const authState = atom({
  key: authStateKey,
  default: appLocalStorage as signInOutput
});

export default authState;
