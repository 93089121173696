/* eslint-disable max-len */
import { applicationRole } from '../../../../entityModel';
import { useTranslation } from 'react-i18next';
import {
  TXT_ADD_ROLE,
  TXT_CANCEL,
  TXT_EDIT_ROLE,
  TXT_NAME,
  TXT_SAVE,
  TXT_SAVING
} from '../../../../translations/translationConstants';
import {
  capitalizeFirstLetterEveryword
} from '../../../../shared/stringFunctions';
import {
  addApplicationRoleInput,
  updateApplicationRoleInput
} from '../../../../api';
import { useEffect } from 'react';
import { useState } from 'react';

export interface ManageRoleEditorProps {
  role?: applicationRole;
  onClose: () => void;
  onSave: (payload: addApplicationRoleInput | updateApplicationRoleInput) => void;
  isSaving: boolean;
  savingError?: string;
};

export const ManageRoleEditor = (props: ManageRoleEditorProps) => {
  const { t } = useTranslation();

  const [name, setName] = useState<string | undefined>(props.role ? props.role.name : '');

  const onNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };

  const validate = () => {
    return !!name;
  };

  const [disabledSaveButton, setDisabledSaveButton] = useState(!validate());

  useEffect(() => {
    setDisabledSaveButton(!validate());
  }, [name]);

  const createAddInput = (): addApplicationRoleInput => {
    return {
      name: name!
    };
  };

  const updateAddInput = (): updateApplicationRoleInput => {
    return {
      id: props.role!.id!,
      name: name!
    };
  };

  const onSaving = () => {
    if (!validate()) {
      return;
    }
    props.onSave(!props.role ? createAddInput() : updateAddInput());
  };

  const lblTitle = t(props.role ? TXT_EDIT_ROLE : TXT_ADD_ROLE).toUpperCase();
  const lblSave = t(TXT_SAVE);
  const lblSaving = t(TXT_SAVING);
  const lblCancel = t(TXT_CANCEL);

  const lblName = `${capitalizeFirstLetterEveryword(t(TXT_NAME))}*`;

  return (
    <div className="modal fade" id="modal-role-editor">
      <div className="modal-dialog">
        <form onSubmit={onSaving}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{lblTitle}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label>{lblName}</label>
                <input type="text" onChange={onNameChanged} value={name} className="form-control" />
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={props.onClose} className="btn btn-default" data-dismiss="modal">{lblCancel}</button>
              <button type="submit" disabled={disabledSaveButton || props.isSaving} className="btn btn-primary">{props.isSaving ? lblSaving : lblSave}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
