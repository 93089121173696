import { gql } from '@apollo/client';
import { geoPhotoQueryResult } from './geoPhotoQueries';

export const ADD_GEO_PHOTO = gql`
  mutation addGeoPhoto($input: AddGeoPhotoInput!) {
    addGeoPhoto(input: $input){
      ${geoPhotoQueryResult}
    }
  }
`;

export const UPDATE_GEO_PHOTO = gql`
  mutation updateGeoPhoto($input: UpdateGeoPhotoInput!) {
    updateGeoPhoto(input: $input){
      ${geoPhotoQueryResult}
    }
  }
`;

export const DELETE_GEO_PHOTO = gql`
  mutation deleteGeoPhoto($input: DeleteGeoPhotoInput!) {
    deleteGeoPhoto(input: $input)
  }
`;
