/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import { CustomError, ManageOrderCodeLabelColumn } from '../../../../components';
import { veinQualifier } from '../../../../entityModel';
import {
  TXT_DATA_NOT_FOUND,
  TXT_DELETE,
  TXT_EDIT,
  TXT_LOADING
} from '../../../../translations/translationConstants';
import { capitalizeFirstLetter } from '../../../../shared/stringFunctions';
import { getVeinQualifierOutput } from '../../../../api';
import { ApolloError } from '@apollo/client';

export interface ManageVeinQualifiersTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: getVeinQualifierOutput;
  columns: ManageOrderCodeLabelColumn[];
  onEdit: (veinQualifier: veinQualifier) => void;
  onDelete: (veinQualifier: veinQualifier) => void;
}

export const ManageVeinQualifiersTableBody =
  ({ error, columns, loading, data, onEdit, onDelete }: ManageVeinQualifiersTableBodyProps) => {
    const { t } = useTranslation();
    return (
      <tbody>
        {
          loading &&
          <tr>
            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
              <h3>
                {t(TXT_LOADING).toUpperCase()}
              </h3>
            </td>
          </tr>
        }
        {
          !loading && error && error.message &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {error.message}
              </CustomError>
            </td>
          </tr>
        }
        {
          !loading && data &&
          data.veinQualifiers && data.veinQualifiers.items &&
          data.veinQualifiers.items.length > 0 &&
          data.veinQualifiers.items.map((veinQualifier) => (
            <tr key={`row-${veinQualifier.id}`}>
              <td>
                {veinQualifier.order}
              </td>
              <td>
                {veinQualifier.code}
              </td>
              <td>
                {veinQualifier.label}
              </td>
              <td align='center'>
                <button
                  data-target="#modal-veinQualifier-editor"
                  data-toggle="modal"
                  className='btn btn-primary'
                  aria-label="edit veinQualifier"
                  onClick={() => onEdit(veinQualifier)}>
                  {t(TXT_EDIT).toUpperCase()}
                </button>
              </td>
              <td align='center'>
                <button
                  data-target="#modal-default"
                  data-toggle="modal"
                  className='btn btn-danger'
                  aria-label="delete veinQualifier"
                  type="button"
                  onClick={() => onDelete(veinQualifier)}>
                  {t(TXT_DELETE).toUpperCase()}
                </button>
              </td>
            </tr>
          ))
        }
        {
          !loading &&
          (!data ||
            !data.veinQualifiers ||
            !data.veinQualifiers.items ||
            data.veinQualifiers.items.length <= 0) &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {capitalizeFirstLetter(t(TXT_DATA_NOT_FOUND))}
              </CustomError>
            </td>
          </tr>
        }
      </tbody>
    );
  };
