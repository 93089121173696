/* eslint-disable max-len */
import {
  TXT_CORE_LENGTH,
  TXT_CORE_LOSE,
  TXT_CORE_REC,
  TXT_DELETE,
  TXT_DRILL_PROGRESS,
  TXT_DRILL_RPM,
  TXT_DRILL_TECHNIQUE,
  TXT_DRILL_WATER,
  TXT_D_SIZE,
  TXT_EDIT,
  TXT_FINISH_DATE,
  TXT_FROM,
  TXT_PUMP_RPM,
  TXT_REMARKS,
  TXT_ROCK_FORMATION,
  TXT_START_DATE,
  TXT_TO,
  TXT_TOTAL_TIME
} from '../../../../translations/translationConstants';

export interface ManagePerpancinganColumn {
  id: 'from' | 'to' | 'drillProgress' | 'startDate' | 'finishDate' | 'totalTime' | 'coreLength' | 'coreRec' |
  'coreLose' | 'dSize' | 'drillRpm' | 'pumpRpm' | 'drillWater' | 'rockFormation' | 'remarks' | 'drillTechnique' |
  'edit' | 'delete';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

export const managePerpancinganColumns: ManagePerpancinganColumn[] = [
  {
    id: 'from',
    label: TXT_FROM,
    maxWidth: '20%',
    align: 'center'
  },
  {
    id: 'to',
    label: TXT_TO,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'drillProgress',
    label: TXT_DRILL_PROGRESS,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'startDate',
    label: TXT_START_DATE,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'finishDate',
    label: TXT_FINISH_DATE,
    maxWidth: '35%',
    align: 'center'
  },
  {
    id: 'totalTime',
    label: TXT_TOTAL_TIME,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'coreLength',
    label: TXT_CORE_LENGTH,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'coreRec',
    label: TXT_CORE_REC,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'coreLose',
    label: TXT_CORE_LOSE,
    maxWidth: '35%',
    align: 'center'
  },
  {
    id: 'dSize',
    label: TXT_D_SIZE,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'drillRpm',
    label: TXT_DRILL_RPM,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'pumpRpm',
    label: TXT_PUMP_RPM,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'drillWater',
    label: TXT_DRILL_WATER,
    maxWidth: '35%',
    align: 'center'
  },
  {
    id: 'rockFormation',
    label: TXT_ROCK_FORMATION,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'remarks',
    label: TXT_REMARKS,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'drillTechnique',
    label: TXT_DRILL_TECHNIQUE,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '10%',
    align: 'center'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '10%',
    align: 'center'
  }
];
