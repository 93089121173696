/* eslint-disable max-len */
import { block, prospect } from '../../../../entityModel';
import { useTranslation } from 'react-i18next';
import {
  TXT_ADD_PROSPECT,
  TXT_BLOCK,
  TXT_CANCEL,
  TXT_CODE,
  TXT_EDIT_PROSPECT,
  TXT_LABEL,
  TXT_SAVE,
  TXT_SAVING
} from '../../../../translations/translationConstants';
import {
  capitalizeFirstLetterEveryword
} from '../../../../shared/stringFunctions';
import {
  addProspectInput,
  getBlockOutput,
  GET_BLOCK,
  updateProspectInput
} from '../../../../api';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';

export interface ManageProspectEditorProps {
  prospect?: prospect;
  onClose: () => void;
  onSave: (payload: addProspectInput | updateProspectInput) => void;
  isSaving: boolean;
  savingError?: string;
};

export const ManageProspectEditor = (props: ManageProspectEditorProps) => {
  const { t } = useTranslation();

  const [block, setBlock] = useState<block | undefined>(props.prospect && props.prospect.block);
  const [code, setCode] = useState<string | undefined>(props.prospect ? props.prospect.code : '');
  const [label, setLabel] = useState<string | undefined>(props.prospect ? props.prospect.label : '');

  const onCodeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.currentTarget.value);
  };

  const onLabelChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.currentTarget.value);
  };

  const onBlockChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const appBlock = blockOptions.find((block) => block.id === event.currentTarget.value);
    setBlock(appBlock);
  };

  const validate = () => {
    return !!code && !!label && !!block;
  };

  const [getBlocks, getBlocksOperation] = useLazyQuery<getBlockOutput>(GET_BLOCK);
  useEffect(() => {
    getBlocks();
  }, []);

  const blockOptions = getBlocksOperation.loading ||
    getBlocksOperation.data === undefined ||
    getBlocksOperation.data.blocks.items.length <= 0 ?
    [] : getBlocksOperation.data.blocks.items;

  const [disabledSaveButton, setDisabledSaveButton] = useState(!validate());

  useEffect(() => {
    setDisabledSaveButton(!validate());
  }, [code, label, block]);

  const createAddInput = (): addProspectInput => {
    return {
      blockId: block!.id!,
      code: code!,
      label: label!
    };
  };

  const updateAddInput = (): updateProspectInput => {
    return {
      id: props.prospect!.id!,
      blockId: block!.id!,
      code: code!,
      label: label!
    };
  };

  const onSaving = () => {
    if (!validate()) {
      return;
    }
    props.onSave(!props.prospect ? createAddInput() : updateAddInput());
  };

  const lblTitle = t(props.prospect ? TXT_EDIT_PROSPECT : TXT_ADD_PROSPECT).toUpperCase();
  const lblSave = t(TXT_SAVE);
  const lblSaving = t(TXT_SAVING);
  const lblCancel = t(TXT_CANCEL);

  const lblCode = `${capitalizeFirstLetterEveryword(t(TXT_CODE))}*`;
  const lblName = `${capitalizeFirstLetterEveryword(t(TXT_LABEL))}*`;
  const lblBlock = `${capitalizeFirstLetterEveryword(t(TXT_BLOCK))}*`;

  return (
    <div className="modal fade" id="modal-prospect-editor">
      <div className="modal-dialog">
        <form>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{lblTitle}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="inputStatus">{lblBlock}</label>
                <select onBlur={onBlockChanged} className="form-control custom-select" value={block?.id}>
                  {blockOptions && blockOptions.length > 0 && blockOptions.map((block: block) => (
                    <option key={block.id} value={block.id}>{block.label}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>{lblCode}</label>
                <input type="text" onChange={onCodeChanged} value={code} className="form-control" />
              </div>
              <div className="form-group">
                <label>{lblName}</label>
                <input type="text" onChange={onLabelChanged} value={label} className="form-control" />
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={props.onClose} className="btn btn-default" data-dismiss="modal">{lblCancel}</button>
              <button type="button" onClick={onSaving} data-dismiss="modal" disabled={disabledSaveButton || props.isSaving} className="btn btn-primary">{props.isSaving ? lblSaving : lblSave}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
