import { gql } from '@apollo/client';
import { defectTypeQueryResult } from './defectTypeQueries';

export const ADD_DEFECT_TYPE = gql`
  mutation addDefectType($input: AddDefectTypeInput!) {
    addDefectType(input: $input){
      ${defectTypeQueryResult}
    }
  }
`;

export const UPDATE_DEFECT_TYPE = gql`
  mutation updateDefectType($input: UpdateDefectTypeInput!) {
    updateDefectType(input: $input){
      ${defectTypeQueryResult}
    }
  }
`;

export const DELETE_DEFECT_TYPE = gql`
  mutation deleteDefectType($input: DeleteDefectTypeInput!) {
    deleteDefectType(input: $input)
  }
`;
