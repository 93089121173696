/* eslint-disable max-len */
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { alterationsPath, blocksPath, defecttypesPath, holesizesPath, homePath, infillalterationsPath, infillmineralsPath, lithologiesPath, machinePath, prospectsPath, rolesPath, roughnessPath, strengthPath, structuresPath, usersPath, veinqualifiersPath, weatheringPath } from '../../shared/links';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { TXT_ALTERATIONS, TXT_BLOCKS, TXT_BOREHOLE, TXT_DEFECT_TYPES, TXT_HOLE_SIZES, TXT_INFILL_ALTERATIONS, TXT_INFILL_MINERALS, TXT_LITHOLOGIES, TXT_MACHINE, TXT_MASTER_DATA, TXT_PROSPECTS, TXT_ROLES, TXT_ROUGHNESSES, TXT_STRENGTH, TXT_STRUCTURES, TXT_USERS, TXT_USERS_MANAGEMENT, TXT_VEIN_QUALIFIERS, TXT_WEATHERING } from '../../translations/translationConstants';
import { MenuType } from '../NavBar/MenuType';

interface MenuBarProps {
  selectedMenu?: string;
  children?: ReactNode;
}

const MenuBar = (props: MenuBarProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedMenu, setSelectedMenu] = useState<string>('/');
  const [auth] = useAuth();

  const menus: MenuType[] = [
    {
      key: '/',
      text: t(capitalizeFirstLetterEveryword(TXT_BOREHOLE)),
      link: homePath
    },
    {
      key: 'usersmanagement',
      text: t(capitalizeFirstLetterEveryword(TXT_USERS_MANAGEMENT)),
      children: [
        {
          key: 'users',
          text: t(capitalizeFirstLetterEveryword(TXT_USERS)),
          link: usersPath
        },
        {
          key: 'roles',
          text: t(capitalizeFirstLetterEveryword(TXT_ROLES)),
          link: rolesPath
        }
      ]
    },
    {
      key: 'masterdata',
      text: t(capitalizeFirstLetterEveryword(TXT_MASTER_DATA)),
      children: [
        {
          key: 'alterations',
          text: t(capitalizeFirstLetterEveryword(TXT_ALTERATIONS)),
          link: alterationsPath
        },
        {
          key: 'blocks',
          text: t(capitalizeFirstLetterEveryword(TXT_BLOCKS)),
          link: blocksPath
        },
        {
          key: 'prospects',
          text: t(capitalizeFirstLetterEveryword(TXT_PROSPECTS)),
          link: prospectsPath
        },
        {
          key: 'defecttypes',
          text: t(capitalizeFirstLetterEveryword(TXT_DEFECT_TYPES)),
          link: defecttypesPath
        },
        {
          key: 'holesizes',
          text: t(capitalizeFirstLetterEveryword(TXT_HOLE_SIZES)),
          link: holesizesPath
        },
        {
          key: 'infillalterations',
          text: t(capitalizeFirstLetterEveryword(TXT_INFILL_ALTERATIONS)),
          link: infillalterationsPath
        },
        {
          key: 'infillmineral',
          text: t(capitalizeFirstLetterEveryword(TXT_INFILL_MINERALS)),
          link: infillmineralsPath
        },
        {
          key: 'lithologies',
          text: t(capitalizeFirstLetterEveryword(TXT_LITHOLOGIES)),
          link: lithologiesPath
        },
        {
          key: 'roughness',
          text: t(capitalizeFirstLetterEveryword(TXT_ROUGHNESSES)),
          link: roughnessPath
        },
        {
          key: 'strength',
          text: t(capitalizeFirstLetterEveryword(TXT_STRENGTH)),
          link: strengthPath
        },
        {
          key: 'structures',
          text: t(capitalizeFirstLetterEveryword(TXT_STRUCTURES)),
          link: structuresPath
        },
        {
          key: 'veinqualifier',
          text: t(capitalizeFirstLetterEveryword(TXT_VEIN_QUALIFIERS)),
          link: veinqualifiersPath
        },
        {
          key: 'weathering',
          text: t(capitalizeFirstLetterEveryword(TXT_WEATHERING)),
          link: weatheringPath
        },
        {
          key: 'machine',
          text: t(capitalizeFirstLetterEveryword(TXT_MACHINE)),
          link: machinePath
        }
      ]
    }
  ];

  const onMenuClicked = (menu: MenuType) => {
    if (menu.link !== undefined) {
      setSelectedMenu(menu.key);
      history.push(menu.link);
    }
  };

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <div className="sidebar">
          <div className="mt-3 pb-3 mb-3" style={{ textAlign: 'center' }}>
            <div className="image">
              <img src={process.env.PUBLIC_URL + '/rinda.png'} className="img-circle elevation-2" style={{ width: '7.5rem' }} />
            </div>
            <div className="info">
              <h3><a href="#" className="d-block">{auth.fullName}</a></h3>
            </div>
            <div className="info">
              <h6><a href="#" className="d-block">{auth.role}</a></h6>
            </div>
          </div>
          <div className="user-panel mt-3 mb-3 d-flex">
          </div>
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              {
                menus.map((menu) => (
                  <li
                    key={menu.key}
                    className="nav-item">
                    <a
                      onClick={() => onMenuClicked(menu)}
                      className={`nav-link ${selectedMenu === menu.key ? 'active' : ''}`}
                      style={{ cursor: 'pointer' }}
                    >
                      <p>
                        {menu.text}
                        {menu.children && menu.children.length > 0 && (
                          <i className="fas fa-angle-left right" />
                        )}
                      </p>
                    </a>
                    {menu.children && menu.children.length > 0 && (
                      <ul className='nav nav-treeview'>
                        {menu.children.map((child) => (
                          <li
                            key={child.key}
                            className='nav-item'>
                            <a
                              onClick={() => onMenuClicked(child)}
                              className={`nav-link ${selectedMenu === child.key ? 'active' : ''}`}
                              style={{ cursor: 'pointer' }}
                            >
                              <p>
                                {child.text}
                              </p>
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))
              }
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default MenuBar;
