/* eslint-disable max-len */
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ManageLogHoleSizesTable } from '../..';
import { deleteGeologicalDataInput, deleteLogHoleSizeInput, deleteSurveyDataInput, DELETE_GEOLOGICAL_DATA, DELETE_LOG_HOLE_SIZE, DELETE_SURVEY_DATA, getBoreholeOutput, getBoreholesInput, getGeologicalDataOutput, getGeologicalDatasInput, getLogHoleSizeOutput, getLogHoleSizesInput, getSurveyDataOutput, getSurveyDatasInput, GET_BOREHOLE, GET_GEOLOGICAL_DATA, GET_LOG_HOLE_SIZE, GET_SURVEY_DATA, updateBoreholeInput, updateLogHoleSizeInput, updateSurveyDataInput, UPDATE_BOREHOLE, UPDATE_LOG_HOLE_SIZE, UPDATE_SURVEY_DATA } from '../../../../api';
import { DeleteConfirmation, globalPageSizeOptions, PaginationBar } from '../../../../components';
import { borehole, geologicalData, logHoleSize, surveyData } from '../../../../entityModel';
import { useApolloErrorHandler, useAuthServices } from '../../../../hooks';
import { capitalizeFirstLetterEveryword } from '../../../../shared/stringFunctions';
import { TXT_ASSAY, TXT_BOREHOLE_DETAIL, TXT_EDIT, TXT_LIST_GEOLOGICAL_DATA, TXT_LIST_HOLE_SIZE, TXT_LIST_SURVEY_DATA, TXT_PERPANCINGAN, TXT_PHOTO } from '../../../../translations/translationConstants';
import { ManageGeologicalDatasTable } from '../../ManageGeologicalData';
import { ManageLogHoleSizeEditor } from '../../ManageLogHoleSize';
import { ManageSurveyDataEditor, ManageSurveyDatasTable } from '../../ManageSurveyData';
import { ManageBoreholeEditor } from '../ManageBoreholeEditor';
import { ManageBoreholeDetailTop } from './ManageBoreholeDetailTop';

interface ManageBoreholeDetailProps {
  boreholeId?: string;
}

export const ManageBoreholeDetail = () => {
  const { t } = useTranslation();
  const { isSignedIn } = useAuthServices();
  const params = useParams<ManageBoreholeDetailProps>();
  const history = useHistory();

  const [selectedSurveyData, setSelectedSurveyData] = useState<surveyData | undefined>(undefined);
  const [selectedLogHoleSize, setSelectedLogHoleSize] = useState<logHoleSize | undefined>(undefined);
  const [selectedGeologicalData, setSelectedGeologicalData] = useState<geologicalData | undefined>(undefined);

  const [getBoreholes, { loading: loadingBorehole, data, error }] = useLazyQuery<getBoreholeOutput>(GET_BOREHOLE);
  const [getSurveyDatas, { loading: loadingSurveyData, data: surveyData, error: errorSD, refetch: refetchSurveyData }] = useLazyQuery<getSurveyDataOutput>(GET_SURVEY_DATA);
  const [getLogHoleSizes, { loading: loadingLogHoleSize, data: logHoleSize, error: errorLHS, refetch: refetchLogHoleSize }] = useLazyQuery<getLogHoleSizeOutput>(GET_LOG_HOLE_SIZE);
  const [getGeologicalDatas, { loading: loadingGeologicalData, data: geologicalData, error: errorGD, refetch: refetchGeologicalData }] = useLazyQuery<getGeologicalDataOutput>(GET_GEOLOGICAL_DATA);

  const [openSurveyDataDeleteConfirmation, setSurveyDataOpenDeleteConfirmation] = useState(false);
  const [openLogHoleSizeDeleteConfirmation, setLogHoleSizeOpenDeleteConfirmation] = useState(false);
  const [openGeologicalDataDeleteConfirmation, setGeologicalDataOpenDeleteConfirmation] = useState(false);

  const [surveyDataPageNumber, setSurveyDataPageNumber] = useState(1);
  const [logHoleSizePageNumber, setLogHoleSizePageNumber] = useState(1);
  const [geologicalDataPageNumber, setGeologicalDataPageNumber] = useState(1);

  const [pageSize, setPageSize] = useState(globalPageSizeOptions[0]);

  const [openSurveyDataEditor, setOpenSurveyDataEditor] = useState(false);
  const [openLogHoleSizeEditor, setOpenLogHoleSizeEditor] = useState(false);
  const [openGeologicalDataEditor, setOpenGeologicalDataEditor] = useState(false);
  const [openBoreholeEditor, setOpenBoreholeEditor] = useState(false);

  const [savingBoreholeError, setSavingBoreholeError] = useState<string | undefined>(undefined);
  const [savingSurveyDataError, setSavingSurveyDataError] = useState<string | undefined>(undefined);
  const [savingLogHoleSizeError, setSavingLogHoleSizeError] = useState<string | undefined>(undefined);

  const fetchBoreholes = () => {
    const variables: getBoreholesInput = {
      filter: {
        id: {
          eq: params.boreholeId
        }
      }
    };
    getBoreholes({
      variables
    });
  };

  const fetchSurveyData = () => {
    const variables: getSurveyDatasInput = {
      take: pageSize,
      skip: (surveyDataPageNumber - 1) * pageSize,
      filter: {
        boreholeId: {
          eq: params.boreholeId
        }
      }
    };
    getSurveyDatas({
      variables
    });
  };

  const fetchLogHoleSize = () => {
    const variables: getLogHoleSizesInput = {
      take: pageSize,
      skip: (logHoleSizePageNumber - 1) * pageSize,
      filter: {
        boreholeId: {
          eq: params.boreholeId
        }
      }
    };
    getLogHoleSizes({
      variables
    });
  };

  const fetchGeologicalData = () => {
    const variables: getGeologicalDatasInput = {
      take: pageSize,
      skip: (geologicalDataPageNumber - 1) * pageSize,
      filter: {
        boreholeId: {
          eq: params.boreholeId
        }
      }
    };
    getGeologicalDatas({
      variables
    });
  };

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, error);
  }, [error]);

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, errorSD);
  }, [errorSD]);

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, errorLHS);
  }, [errorLHS]);

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, errorGD);
  }, [errorGD]);

  useEffect(() => {
    fetchBoreholes();
  }, [data]);

  useEffect(() => {
    fetchGeologicalData();
  }, [geologicalData, geologicalDataPageNumber]);

  useEffect(() => {
    fetchSurveyData();
  }, [surveyData, surveyDataPageNumber]);

  useEffect(() => {
    fetchLogHoleSize();
  }, [logHoleSize, logHoleSizePageNumber]);

  const openDeleteSurveyDataConfirmation = (surveyData: surveyData) => {
    setSelectedSurveyData(surveyData);
    setSurveyDataOpenDeleteConfirmation(true);
  };

  const onDeleteCancel = () => setSurveyDataOpenDeleteConfirmation(false);

  const [deleteSurveyData, deleteSurveyDataOperation] = useMutation<boolean>(DELETE_SURVEY_DATA, {
    onError: (e) => { },
    onCompleted: (result) => {
      if (data && refetchSurveyData) {
        if (data.boreholes.items.length == 1) {
          const targetPage = surveyDataPageNumber - 1 <= 0 ? 1 : surveyDataPageNumber - 1;
          if (surveyDataPageNumber !== targetPage) {
            setSurveyDataPageNumber(targetPage);
          } else {
            refetchSurveyData();
          }
        } else {
          refetchSurveyData();
        }
      }
      onDeleteCancel();
    }
  });

  const openDeleteLogHoleSizeConfirmation = (logHoleSize: logHoleSize) => {
    setSelectedLogHoleSize(logHoleSize);
    setLogHoleSizeOpenDeleteConfirmation(true);
  };

  const onDeleteLogHoleSizeCancel = () => setLogHoleSizeOpenDeleteConfirmation(false);

  const [deleteLogHoleSize, deleteLogHoleSizeOperation] = useMutation<boolean>(DELETE_LOG_HOLE_SIZE, {
    onError: (e) => { },
    onCompleted: (result) => {
      if (data && refetchLogHoleSize) {
        if (data.boreholes.items.length == 1) {
          const targetPage = logHoleSizePageNumber - 1 <= 0 ? 1 : logHoleSizePageNumber - 1;
          if (logHoleSizePageNumber !== targetPage) {
            setLogHoleSizePageNumber(targetPage);
          } else {
            refetchLogHoleSize();
          }
        } else {
          refetchLogHoleSize();
        }
      }
      onDeleteLogHoleSizeCancel();
    }
  });

  const openDeleteGeologicalDataConfirmation = (logHoleSize: geologicalData) => {
    setSelectedGeologicalData(logHoleSize);
    setGeologicalDataOpenDeleteConfirmation(true);
  };

  const onDeleteGeologicalDataCancel = () => setGeologicalDataOpenDeleteConfirmation(false);

  const [deleteGeologicalData, deleteGeologicalDataOperation] = useMutation<boolean>(DELETE_GEOLOGICAL_DATA, {
    onError: (e) => { },
    onCompleted: (result) => {
      if (data && refetchGeologicalData) {
        if (data.boreholes.items.length == 1) {
          const targetPage = geologicalDataPageNumber - 1 <= 0 ? 1 : geologicalDataPageNumber - 1;
          if (geologicalDataPageNumber !== targetPage) {
            setGeologicalDataPageNumber(targetPage);
          } else {
            refetchGeologicalData();
          }
        } else {
          refetchGeologicalData();
        }
      }
      onDeleteGeologicalDataCancel();
    }
  });

  const onDelete = () => {
    const input: deleteSurveyDataInput = {
      id: selectedSurveyData!.id!
    };
    deleteSurveyData({
      variables: {
        input
      }
    });
  };

  const onDeleteLogHoleSize = () => {
    const input: deleteLogHoleSizeInput = {
      id: selectedLogHoleSize!.id!
    };
    deleteLogHoleSize({
      variables: {
        input
      }
    });
  };

  const onDeleteGeologicalData = () => {
    const input: deleteGeologicalDataInput = {
      id: selectedGeologicalData!.id!
    };
    deleteGeologicalData({
      variables: {
        input
      }
    });
  };

  const openEditSurveyDataEditor = (sd: surveyData) => {
    setSelectedSurveyData(sd);
    setOpenSurveyDataEditor(true);
  };

  const openEditLogHoleSizeEditor = (logHoleSize: logHoleSize) => {
    setSelectedLogHoleSize(logHoleSize);
    setOpenLogHoleSizeEditor(true);
  };

  const openEditGeologicalDataEditor = (geologicalData: geologicalData) => {
    setSelectedGeologicalData(geologicalData);
    setOpenGeologicalDataEditor(true);
  };

  const onPageSizeChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(+event.target.value);
  };

  const openGeotechnicalData = (geologicalData: geologicalData) => {
    history.push(`/boreholeDetail/${params.boreholeId}/geotechnical/${geologicalData?.id}`);
  };

  const openDetailAssay = () => {
    history.push(`/boreholeDetail/${params.boreholeId}/assay`);
  };

  const openDetailPhoto = () => {
    history.push(`/boreholeDetail/${params.boreholeId}/photo`);
  };

  const openDetailPerpancingan = () => {
    history.push(`/boreholeDetail/${params.boreholeId}/perpancingan`);
  };

  const onOpenBoreholeEditor = () => {
    setOpenBoreholeEditor(true);
  };

  const closeBoreholeEditor = () => {
    setOpenBoreholeEditor(false);
  };

  const [updateBorehole, updateBoreholeOperation] = useMutation<borehole>(UPDATE_BOREHOLE, {
    onError: (e) => handleAddingOrUpdatingBoreholeError(e),
    onCompleted: (result) => afterAddingOrUpdatingBorehole()
  });

  const afterAddingOrUpdatingBorehole = () => {
    setOpenBoreholeEditor(false);
  };

  const handleAddingOrUpdatingBoreholeError = (error: ApolloError) => {
    if (error.graphQLErrors.length > 0 &&
      !!error.graphQLErrors[0].extensions) {
      setSavingBoreholeError(error.graphQLErrors[0].extensions['message']);
    } else {
      setSavingBoreholeError(error.message);
    }
  };

  const onEditorBoreholeSave = (payload: updateBoreholeInput) => {
    updateBorehole({
      variables: {
        input: payload
      }
    });
  };

  const closeSurveyDataEditor = () => {
    setOpenSurveyDataEditor(false);
  };

  const [updateSurveyData, updateSurveyDataOperation] = useMutation<surveyData>(UPDATE_SURVEY_DATA, {
    onError: (e) => handleAddingOrUpdatingSurveyDataError(e),
    onCompleted: (result) => afterAddingOrUpdatingSurveyData()
  });

  const afterAddingOrUpdatingSurveyData = () => {
    setOpenSurveyDataEditor(false);
  };

  const handleAddingOrUpdatingSurveyDataError = (error: ApolloError) => {
    if (error.graphQLErrors.length > 0 &&
      !!error.graphQLErrors[0].extensions) {
      setSavingSurveyDataError(error.graphQLErrors[0].extensions['message']);
    } else {
      setSavingSurveyDataError(error.message);
    }
  };

  const onEditorSurveyDataSave = (payload: updateSurveyDataInput) => {
    updateSurveyData({
      variables: {
        input: payload
      }
    });
  };

  const closeLogHoleSizeEditor = () => {
    setOpenLogHoleSizeEditor(false);
  };

  const [updateLogHoleSize, updateLogHoleSizeOperation] = useMutation<logHoleSize>(UPDATE_LOG_HOLE_SIZE, {
    onError: (e) => handleAddingOrUpdatingLogHoleSizeError(e),
    onCompleted: (result) => afterAddingOrUpdatingLogHoleSize()
  });

  const afterAddingOrUpdatingLogHoleSize = () => {
    setOpenLogHoleSizeEditor(false);
  };

  const handleAddingOrUpdatingLogHoleSizeError = (error: ApolloError) => {
    if (error.graphQLErrors.length > 0 &&
      !!error.graphQLErrors[0].extensions) {
      setSavingLogHoleSizeError(error.graphQLErrors[0].extensions['message']);
    } else {
      setSavingLogHoleSizeError(error.message);
    }
  };

  const onEditorLogHoleSizeSave = (payload: updateLogHoleSizeInput) => {
    updateLogHoleSize({
      variables: {
        input: payload
      }
    });
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          {
            openSurveyDataEditor &&
            <ManageSurveyDataEditor
              surveyData={selectedSurveyData}
              onClose={closeSurveyDataEditor}
              onSave={onEditorSurveyDataSave}
              isSaving={updateSurveyDataOperation.loading}
              savingError={savingSurveyDataError}
            />
          }
          {
            openLogHoleSizeEditor &&
            <ManageLogHoleSizeEditor
              logHoleSize={selectedLogHoleSize}
              onClose={closeLogHoleSizeEditor}
              onSave={onEditorLogHoleSizeSave}
              isSaving={updateLogHoleSizeOperation.loading}
              savingError={savingLogHoleSizeError}
            />
          }
          {
            openGeologicalDataEditor &&
            { alert }
          }
          {
            openBoreholeEditor &&
            <ManageBoreholeEditor
              borehole={data && data.boreholes && data.boreholes.items && data.boreholes.items[0]}
              onClose={closeBoreholeEditor}
              onSave={onEditorBoreholeSave}
              isSaving={updateBoreholeOperation.loading}
              savingError={savingBoreholeError}
            />
          }
          {
            openSurveyDataDeleteConfirmation &&
            <DeleteConfirmation
              loading={deleteSurveyDataOperation.loading}
              onYes={onDelete}
              onNo={onDeleteCancel}
              objectName={selectedSurveyData?.code || ''}
            />
          }
          {
            openLogHoleSizeDeleteConfirmation &&
            <DeleteConfirmation
              loading={deleteLogHoleSizeOperation.loading}
              onYes={onDeleteLogHoleSize}
              onNo={onDeleteLogHoleSizeCancel}
              objectName={selectedLogHoleSize?.code || ''}
            />
          }
          {
            openGeologicalDataDeleteConfirmation &&
            <DeleteConfirmation
              loading={deleteGeologicalDataOperation.loading}
              onYes={onDeleteGeologicalData}
              onNo={onDeleteGeologicalDataCancel}
              objectName={selectedGeologicalData?.code || ''}
            />
          }
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{t(capitalizeFirstLetterEveryword(TXT_BOREHOLE_DETAIL))}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="card">
          <div className="card-header">
            <div className="card-tools">
              <button
                onClick={onOpenBoreholeEditor}
                data-target="#modal-borehole-editor"
                data-toggle="modal"
                className='btn btn-primary'>
                {t(TXT_EDIT).toUpperCase()}
              </button>
            </div>
          </div>
          <div className="card-body">
            <ManageBoreholeDetailTop
              borehole={data?.boreholes.items[0]}
            />
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header">
                    <h4>{t(capitalizeFirstLetterEveryword(TXT_LIST_SURVEY_DATA))}</h4>
                  </div>
                  <div className="card-body">
                    <ManageSurveyDatasTable
                      data={surveyData}
                      error={errorSD}
                      loading={loadingSurveyData}
                      onDelete={openDeleteSurveyDataConfirmation}
                      onEdit={openEditSurveyDataEditor}
                    />
                  </div>
                  <PaginationBar
                    currentPage={surveyDataPageNumber}
                    totalCount={surveyData?.surveyDatas.totalCount}
                    pageSize={pageSize}
                    onPageChanged={(page) => setSurveyDataPageNumber(page)}
                    onChangeRowsPerPage={onPageSizeChanged}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header">
                    <h4>{t(capitalizeFirstLetterEveryword(TXT_LIST_HOLE_SIZE))}</h4>
                  </div>
                  <div className="card-body">
                    <ManageLogHoleSizesTable
                      data={logHoleSize}
                      error={errorLHS}
                      loading={loadingLogHoleSize}
                      onDelete={openDeleteLogHoleSizeConfirmation}
                      onEdit={openEditLogHoleSizeEditor}
                    />
                  </div>
                  <PaginationBar
                    currentPage={logHoleSizePageNumber}
                    totalCount={logHoleSize?.logHoleSizes.totalCount}
                    pageSize={pageSize}
                    onPageChanged={(page) => setLogHoleSizePageNumber(page)}
                    onChangeRowsPerPage={onPageSizeChanged}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card" style={{ overflowX: 'scroll' }}>
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>{t(capitalizeFirstLetterEveryword(TXT_LIST_GEOLOGICAL_DATA))}</h4>
                    </div>
                    <div className="col-md-6" style={{ textAlign: 'right' }}>
                      <div className="card-tools">
                        <button
                          onClick={openDetailPhoto}
                          style={{ marginRight: '10px' }}
                          className='btn btn-primary btn-sm'>
                          {t(TXT_PHOTO).toUpperCase()}
                        </button>
                        <button
                          onClick={openDetailAssay}
                          style={{ marginRight: '10px' }}
                          className='btn btn-primary btn-sm'>
                          {t(TXT_ASSAY).toUpperCase()}
                        </button>
                        <button
                          onClick={openDetailPerpancingan}
                          className='btn btn-primary btn-sm'>
                          {t(TXT_PERPANCINGAN).toUpperCase()}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ManageGeologicalDatasTable
                    data={geologicalData}
                    error={errorGD}
                    loading={loadingGeologicalData}
                    onDelete={openDeleteGeologicalDataConfirmation}
                    onEdit={openEditGeologicalDataEditor}
                    onDetail={openGeotechnicalData}
                  />
                </div>
                <PaginationBar
                  currentPage={geologicalDataPageNumber}
                  totalCount={geologicalData?.geologicalDatas.totalCount}
                  pageSize={pageSize}
                  onPageChanged={(page) => setGeologicalDataPageNumber(page)}
                  onChangeRowsPerPage={onPageSizeChanged}
                />
              </div>
            </div>
          </div>
          {loadingBorehole &&
          <div className="overlay">
            <i style={{ fontSize: '75px' }} className="fas fa-spinner fa-pulse"></i>
          </div>
          }
        </div>
      </section>
    </div>
  );
};
