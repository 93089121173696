import { gql } from '@apollo/client';
import { surveyDataQueryResult } from './surveyDataQueries';

export const ADD_SURVEY_DATA = gql`
  mutation addSurveyData($input: AddSurveyDataInput!) {
    addSurveyData(input: $input){
      ${surveyDataQueryResult}
    }
  }
`;

export const UPDATE_SURVEY_DATA = gql`
  mutation updateSurveyData($input: UpdateSurveyDataInput!) {
    updateSurveyData(input: $input){
      ${surveyDataQueryResult}
    }
  }
`;

export const DELETE_SURVEY_DATA = gql`
  mutation deleteSurveyData($input: DeleteSurveyDataInput!) {
    deleteSurveyData(input: $input)
  }
`;
