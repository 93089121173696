/* eslint-disable max-len */
import { block, borehole, LOCATION, machine, prospect, STATUS } from '../../../../entityModel';
import { useTranslation } from 'react-i18next';
import {
  TXT_BLOCK,
  TXT_CANCEL,
  TXT_CODE,
  TXT_COLLAR_AZIMUTH,
  TXT_COLLAR_DIP,
  TXT_COLLAR_RL,
  TXT_DRILL_FINISH,
  TXT_DRILL_START,
  TXT_EASTING,
  TXT_EDIT_BOREHOLE,
  TXT_LENGTH,
  TXT_LOCATION,
  TXT_LOGGIN_FINISH,
  TXT_LOGGIN_START,
  TXT_MACHINE,
  TXT_NORTING,
  TXT_NUMBER,
  TXT_OBJECTIVE,
  TXT_PROSPECT,
  TXT_RESULT,
  TXT_SAVE,
  TXT_SAVING,
  TXT_SIGNIFICANT_GEOLOGY,
  TXT_STATUS
} from '../../../../translations/translationConstants';
import {
  capitalizeFirstLetterEveryword
} from '../../../../shared/stringFunctions';
import {
  getBlockOutput,
  getMachineOutput,
  getProspectOutput,
  GET_BLOCK,
  GET_MACHINE,
  GET_PROSPECT,
  updateBoreholeInput
} from '../../../../api';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { useApolloErrorHandler, useAuthServices } from '../../../../hooks';
import DatePicker from 'react-datepicker';

export interface ManageBoreholeEditorProps {
  borehole?: borehole;
  onClose: () => void;
  onSave: (payload: updateBoreholeInput) => void;
  isSaving: boolean;
  savingError?: string;
};

export const ManageBoreholeEditor = (props: ManageBoreholeEditorProps) => {
  const { t } = useTranslation();
  const { isSignedIn } = useAuthServices();

  const [block, setBlock] = useState<block | undefined>(props.borehole && props.borehole.prospect && props.borehole.prospect.block);
  const [prospect, setProspect] = useState<prospect | undefined>(props.borehole && props.borehole.prospect);
  const [machine, setMachine] = useState<machine | undefined>(props.borehole && props.borehole.machine);
  const [projectCode, setProjectCode] = useState<string | undefined>(props.borehole ? props.borehole.code : '');
  const [projectNumber, setProjectNumber] = useState<number | undefined>(props.borehole && props.borehole.projectNumber);
  const [loggingStart, setLoggingStart] = useState<Date | undefined>(props.borehole && moment(props.borehole.loggingStart).toDate());
  const [loggingFinish, setLoggingFinish] = useState<Date | undefined>(props.borehole && moment(props.borehole.loggingFinish).toDate());
  const [drillStart, setDrillStart] = useState<Date | undefined>(props.borehole && moment(props.borehole.drillStart).toDate());
  const [drillFinish, setDrillFinish] = useState<Date | undefined>(props.borehole && moment(props.borehole.drillFinish).toDate());
  const [location, setLocation] = useState(props.borehole && props.borehole.location);
  const [length, setLength] = useState(props.borehole && props.borehole.length);
  const [northing, setNorthing] = useState(props.borehole && props.borehole.norting);
  const [easting, setEasting] = useState(props.borehole && props.borehole.easting);
  const [collar, setCollar] = useState(props.borehole && props.borehole.collar);
  const [collarAzimuth, setCollarAzimuth] = useState(props.borehole && props.borehole.collarAzimuth);
  const [collarDip, setCollarDip] = useState(props.borehole && props.borehole.collarDip);
  const [objective, setObjective] = useState(props.borehole && props.borehole.objective);
  const [result, setResult] = useState(props.borehole && props.borehole.result);
  const [significantGeology, setSignificantGeology] = useState(props.borehole && props.borehole.significantGeology);
  const [status, setStatus] = useState(props.borehole && props.borehole.status);

  const onCodeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectCode(event.currentTarget.value);
  };

  const onLoggingStartChanged = (date: Date) => {
    setLoggingStart(date);
  };

  const onLoggingFinishChanged = (date: Date) => {
    setLoggingFinish(date);
  };

  const onDrillingStartChanged = (date: Date) => {
    setDrillStart(date);
  };

  const onDrillingFinishChanged = (date: Date) => {
    setDrillFinish(date);
  };

  const onNumberChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(event.currentTarget.value))) {
      setProjectNumber(Number(event.currentTarget.value));
    }
  };

  const onLengthChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(event.currentTarget.value))) {
      setLength(Number(event.currentTarget.value));
    }
  };

  const onNorthingChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(event.currentTarget.value))) {
      setNorthing(Number(event.currentTarget.value));
    }
  };

  const onEastingChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(event.currentTarget.value))) {
      setEasting(Number(event.currentTarget.value));
    }
  };

  const onCollarChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(event.currentTarget.value))) {
      setCollar(Number(event.currentTarget.value));
    }
  };

  const onCollarAzimuthChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCollarAzimuth(event.currentTarget.value);
  };

  const onCollarDipChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(event.currentTarget.value))) {
      setCollarDip(Number(event.currentTarget.value));
    }
  };

  const onObjectiveChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setObjective(event.currentTarget.value);
  };

  const onSignificantGeologyChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSignificantGeology(event.currentTarget.value);
  };

  const onResultChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setResult(event.currentTarget.value);
  };

  const onBlockChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.currentTarget.value !== undefined || event.currentTarget.value !== '') {
      const appBlock = blocksData && blocksData.blocks && blocksData.blocks.items &&
        blocksData.blocks.items.find((block) => block.id === event.currentTarget.value);

      setBlock(appBlock);

      if (refetchProspect) {
        refetchProspect();
      }
    }
  };

  const onProspectChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.currentTarget.value !== undefined || event.currentTarget.value !== '') {
      const appBlock = prospectsData && prospectsData.prospects && prospectsData.prospects.items &&
        prospectsData.prospects.items.find((prospect) => prospect.id === event.currentTarget.value);
      setProspect(appBlock);
    }
  };

  const onMachineChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.currentTarget.value !== undefined || event.currentTarget.value !== '') {
      const appBlock = machinesData && machinesData.machines && machinesData.machines.items &&
        machinesData.machines.items.find((machine) => machine.id === event.currentTarget.value);
      setMachine(appBlock);
    }
  };

  const onLocationChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLocation(event.currentTarget.value);
  };

  const onStatusChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(event.currentTarget.value);
    // if (!isNaN(Number(event.currentTarget.value))) {
    //   setStatus(Number(event.currentTarget.value));
    // }
  };

  const validate = () => {
    return !!projectCode &&
      !!machine &&
      !!prospect &&
      !!location &&
      !!collarAzimuth &&
      !!objective &&
      !!result;
  };

  const [getBlocks, { data: blocksData, error: errorBlock }] = useLazyQuery<getBlockOutput>(GET_BLOCK);
  const [getProspects, { data: prospectsData, error: errorProspect, refetch: refetchProspect }] = useLazyQuery<getProspectOutput>(GET_PROSPECT, {
    variables: {
      filter: {
        blockId: {
          eq: block && block.id
        }
      }
    }
  });
  const [getMachines, { data: machinesData, error: errorMachine }] = useLazyQuery<getMachineOutput>(GET_MACHINE);

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, errorBlock);
  }, [errorBlock]);

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, errorProspect);
  }, [errorProspect]);

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, errorMachine);
  }, [errorMachine]);

  useEffect(() => {
    getBlocks();
  }, [blocksData]);

  useEffect(() => {
    getProspects();
  }, [prospectsData]);

  useEffect(() => {
    getMachines();
  }, [machinesData]);

  const [disabledSaveButton, setDisabledSaveButton] = useState(!validate());

  useEffect(() => {
    setDisabledSaveButton(!validate());
  }, [projectCode, machine, location, collarAzimuth, prospect, objective, result]);

  const updateAddInput = (): updateBoreholeInput => {
    return {
      id: props.borehole!.id!,
      code: props.borehole!.code!,
      projectCode,
      projectNumber,
      prospectCode: prospect && prospect.code,
      machineCode: machine && machine.code,
      location: location,
      norting: northing,
      easting,
      collar,
      collarAzimuth,
      collarDip,
      length,
      loggingStart: loggingStart?.toISOString(),
      loggingFinish: loggingFinish?.toISOString(),
      drillStart: drillStart?.toISOString(),
      drillFinish: drillFinish?.toISOString(),
      objective,
      result,
      significantGeology,
      status: status
    };
  };

  const onSaving = () => {
    if (!validate()) {
      return;
    }
    props.onSave(updateAddInput());
  };

  const lblTitle = t(TXT_EDIT_BOREHOLE).toUpperCase();
  const lblSave = t(TXT_SAVE);
  const lblSaving = t(TXT_SAVING);
  const lblCancel = t(TXT_CANCEL);

  const lblCode = `${capitalizeFirstLetterEveryword(t(TXT_CODE))}*`;
  const lblNumber = `${capitalizeFirstLetterEveryword(t(TXT_NUMBER))}*`;
  const lblBlock = `${capitalizeFirstLetterEveryword(t(TXT_BLOCK))}*`;
  const lblProspect = `${capitalizeFirstLetterEveryword(t(TXT_PROSPECT))}*`;
  const lblLoggingStart = `${capitalizeFirstLetterEveryword(t(TXT_LOGGIN_START))}*`;
  const lblLoggingFinish = `${capitalizeFirstLetterEveryword(t(TXT_LOGGIN_FINISH))}*`;
  const lblDrillStart = `${capitalizeFirstLetterEveryword(t(TXT_DRILL_START))}*`;
  const lblDrillFinish = `${capitalizeFirstLetterEveryword(t(TXT_DRILL_FINISH))}*`;
  const lblLocation = `${capitalizeFirstLetterEveryword(t(TXT_LOCATION))}*`;
  const lblLength = `${capitalizeFirstLetterEveryword(t(TXT_LENGTH))}*`;
  const lblNorthing = `${capitalizeFirstLetterEveryword(t(TXT_NORTING))}*`;
  const lblEasting = `${capitalizeFirstLetterEveryword(t(TXT_EASTING))}*`;
  const lblCollarRl = `${capitalizeFirstLetterEveryword(t(TXT_COLLAR_RL))}*`;
  const lblCollarAzimuth = `${capitalizeFirstLetterEveryword(t(TXT_COLLAR_AZIMUTH))}*`;
  const lblCollarDip = `${capitalizeFirstLetterEveryword(t(TXT_COLLAR_DIP))}*`;
  const lblMachine = `${capitalizeFirstLetterEveryword(t(TXT_MACHINE))}*`;
  const lblObjective = `${capitalizeFirstLetterEveryword(t(TXT_OBJECTIVE))}*`;
  const lblResult = `${capitalizeFirstLetterEveryword(t(TXT_RESULT))}*`;
  const lblSignificantGeology = `${capitalizeFirstLetterEveryword(t(TXT_SIGNIFICANT_GEOLOGY))}*`;
  const lblStatus = `${capitalizeFirstLetterEveryword(t(TXT_STATUS))}*`;

  return (
    <div className="modal" id="modal-borehole-editor">
      <div className="modal-dialog">
        <form>
          <div className="modal-content" style={{ width: '70rem' }}>
            <div className="modal-header">
              <h4 className="modal-title">{lblTitle}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>{lblCode}</label>
                    <input type="text" onChange={onCodeChanged} value={projectCode} className="form-control" />
                  </div>
                  <div className="form-group">
                    <label>{lblNumber}</label>
                    <input type="text" value={projectNumber} onChange={onNumberChanged} className="form-control" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputStatus">{lblBlock}</label>
                    <select onChange={onBlockChanged} onBlur={onBlockChanged} className="form-control custom-select" value={block?.id}>
                      {blocksData && blocksData.blocks &&
                        blocksData.blocks.items && blocksData.blocks.items.map((block: block) => (
                        <option key={block.id} value={block.id}>{block.label}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputStatus">{lblProspect}</label>
                    <select onChange={onProspectChanged} onBlur={onProspectChanged} className="form-control custom-select" value={prospect?.id}>
                      {prospectsData && prospectsData.prospects &&
                        prospectsData.prospects.items && prospectsData.prospects.items.map((prospect: prospect) => (
                        <option key={prospect.id} value={prospect.id}>{prospect.label}</option>
                      ))}
                    </select>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{lblLoggingStart}</label>
                        <div className="input-group">
                          <DatePicker selected={loggingStart} onChange={onLoggingStartChanged} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{lblLoggingFinish}</label>
                        <div className="input-group">
                          <DatePicker selected={loggingFinish} onChange={onLoggingFinishChanged} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{lblDrillStart}</label>
                        <div className="input-group">
                          <DatePicker selected={drillStart} onChange={onDrillingStartChanged} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{lblDrillFinish}</label>
                        <div className="input-group">
                          <DatePicker selected={drillFinish} onChange={onDrillingFinishChanged} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="inputStatus">{lblLocation}</label>
                        <select onChange={onLocationChanged} onBlur={onLocationChanged} className="form-control custom-select" value={location}>
                          {Object.keys(LOCATION).filter((x) => isNaN(Number(x)) === true).map((text, index) => (
                            <option key={index} value={text}>{text}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{lblLength}</label>
                        <div className="input-group">
                          <input type="text" value={length} onChange={onLengthChanged} className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{lblNorthing}</label>
                        <div className="input-group">
                          <input type="text" value={northing} onChange={onNorthingChanged} className="form-control" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{lblEasting}</label>
                        <div className="input-group">
                          <input type="text" value={easting} onChange={onEastingChanged} className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{lblCollarRl}</label>
                    <input type="text" value={collar} onChange={onCollarChanged} className="form-control" />
                  </div>
                  <div className="form-group">
                    <label>{lblCollarAzimuth}</label>
                    <input type="text" value={collarAzimuth} onChange={onCollarAzimuthChanged} className="form-control" />
                  </div>
                  <div className="form-group">
                    <label>{lblCollarDip}</label>
                    <input type="text" value={collarDip} onChange={onCollarDipChanged} className="form-control" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputStatus">{lblMachine}</label>
                    <select className="form-control custom-select" onChange={onMachineChanged} onBlur={onMachineChanged} value={machine?.id}>
                      {machinesData && machinesData.machines &&
                        machinesData.machines.items && machinesData.machines.items.map((machine: machine) => (
                        <option key={machine.id} value={machine.id}>{machine.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>{lblObjective}</label>
                <textarea rows={3} value={objective} onChange={onObjectiveChanged} className="form-control" />
              </div>
              <div className="form-group">
                <label>{lblResult}</label>
                <textarea rows={3} value={result} onChange={onResultChanged} className="form-control" />
              </div>
              <div className="form-group">
                <label>{lblSignificantGeology}</label>
                <textarea rows={3} value={significantGeology} onChange={onSignificantGeologyChanged} className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="inputStatus">{lblStatus}</label>
                <select className="form-control custom-select" onChange={onStatusChanged} onBlur={onStatusChanged} value={status}>
                  {Object.keys(STATUS).filter((x) => isNaN(Number(x)) === true).map((text, index) => (
                    <option key={index} value={text}>{text}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={props.onClose} className="btn btn-default" data-dismiss="modal">{lblCancel}</button>
              <button type="button" onClick={onSaving} data-dismiss="modal" disabled={disabledSaveButton || props.isSaving} className="btn btn-primary">{props.isSaving ? lblSaving : lblSave}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
