/* eslint-disable max-len */
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deletePerpancinganInput, DELETE_PERPANCINGAN, getPerpancinganOutput, getPerpancinganInput, GET_PERPANCINGAN } from '../../../api';
import { DeleteConfirmation, globalPageSizeOptions, PaginationBar } from '../../../components';
import { perpancingan } from '../../../entityModel';
import { useApolloErrorHandler, useAuthServices } from '../../../hooks';
import { capitalizeFirstLetterEveryword } from '../../../shared/stringFunctions';
import { TXT_LIST_PERPANCINGAN, TXT_SEARCH_BY_NAME } from '../../../translations/translationConstants';
import { ManagePerpancinganTable } from './ManagePerpancinganTable';

interface ManagePerpancinganProps {
  boreholeId?: string;
}

export const ManagePerpancingan = () => {
  const { t } = useTranslation();
  const params = useParams<ManagePerpancinganProps>();
  const { isSignedIn } = useAuthServices();

  const [selectedPerpancingan, setSelectedPerpancingan] = useState<perpancingan | undefined>(undefined);

  const [getPerpancingan, { loading, data, error, refetch }] = useLazyQuery<getPerpancinganOutput>(GET_PERPANCINGAN);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(globalPageSizeOptions[0]);
  const [openEditor, setOpenEditor] = useState(false);
  const [txtSearch, setTxtSearch] = useState('');

  const lblSearch = capitalizeFirstLetterEveryword(t(TXT_SEARCH_BY_NAME));

  const onTxtSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTxtSearch(event.currentTarget.value);
  };

  const fetchPerpancingan = () => {
    const variables: getPerpancinganInput = {
      code: txtSearch,
      take: pageSize,
      skip: (pageNumber - 1) * pageSize,
      filter: {
        boreholeId: {
          eq: params.boreholeId
        }
      }
    };
    getPerpancingan({
      variables
    });
  };

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, error);
  }, [error]);

  useEffect(() => {
    fetchPerpancingan();
  }, [data, pageNumber]);

  const onOpenDeleteConfirmation = (perpancingan: perpancingan) => {
    setSelectedPerpancingan(perpancingan);
    setOpenDeleteConfirmation(true);
  };

  const onDeleteCancel = () => setOpenDeleteConfirmation(false);

  const [deletePerpancingan, deletePerpancinganOperation] = useMutation<boolean>(DELETE_PERPANCINGAN, {
    onError: (e) => { },
    onCompleted: (result) => {
      if (data && refetch) {
        if (data.perpancingan.items.length == 1) {
          const targetPage = pageNumber - 1 <= 0 ? 1 : pageNumber - 1;
          if (pageNumber !== targetPage) {
            setPageNumber(targetPage);
          } else {
            refetch();
          }
        } else {
          refetch();
        }
      }
      onDeleteCancel();
    }
  });

  const onDelete = () => {
    const input: deletePerpancinganInput = {
      id: selectedPerpancingan!.id!
    };
    deletePerpancingan({
      variables: {
        input
      }
    });
  };

  const onOpenEditPerpancinganEditor = (perpancingan: perpancingan) => {
    setSelectedPerpancingan(perpancingan);
    setOpenEditor(true);
  };

  const onPageSizeChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(+event.target.value);
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{t(capitalizeFirstLetterEveryword(TXT_LIST_PERPANCINGAN))}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          {
            openEditor &&
            { alert }
          }
          {
            openDeleteConfirmation &&
            <DeleteConfirmation
              loading={deletePerpancinganOperation.loading}
              onYes={onDelete}
              onNo={onDeleteCancel}
              objectName={selectedPerpancingan?.code || ''}
            />
          }
          <div className="row">
            <div className="col-12">
              <div className="card" style={{ overflowX: 'scroll' }}>
                <div className="card-header">
                  <div className='row'>
                    <div className='col-6'>
                      <div className="form-group">
                        <label>{lblSearch}</label>
                        <input type='text' className='form-control' value={txtSearch} onChange={onTxtSearchChanged} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ManagePerpancinganTable
                    loading={loading}
                    data={data}
                    error={error}
                    onEdit={onOpenEditPerpancinganEditor}
                    onDelete={onOpenDeleteConfirmation}
                  />
                </div>
                <PaginationBar
                  currentPage={pageNumber}
                  totalCount={data?.perpancingan.totalCount}
                  pageSize={pageSize}
                  onPageChanged={(page) => setPageNumber(page)}
                  onChangeRowsPerPage={onPageSizeChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
