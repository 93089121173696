/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import { CustomError, ManageCodeLabelColumn } from '../../../../components';
import { prospect } from '../../../../entityModel';
import {
  TXT_DATA_NOT_FOUND,
  TXT_DELETE,
  TXT_EDIT,
  TXT_LOADING
} from '../../../../translations/translationConstants';
import { capitalizeFirstLetter } from '../../../../shared/stringFunctions';
import { getProspectOutput } from '../../../../api';
import { ApolloError } from '@apollo/client';

export interface ManageProspectsTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: getProspectOutput;
  columns: ManageCodeLabelColumn[];
  onEdit: (prospect: prospect) => void;
  onDelete: (prospect: prospect) => void;
}

export const ManageProspectsTableBody =
  ({ error, columns, loading, data, onEdit, onDelete }: ManageProspectsTableBodyProps) => {
    const { t } = useTranslation();
    return (
      <tbody>
        {
          loading &&
          <tr>
            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
              <h3>
                {t(TXT_LOADING).toUpperCase()}
              </h3>
            </td>
          </tr>
        }
        {
          !loading && error && error.message &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {error.message}
              </CustomError>
            </td>
          </tr>
        }
        {
          !loading && data &&
          data.prospects && data.prospects.items &&
          data.prospects.items.length > 0 &&
          data.prospects.items.map((prospect) => (
            <tr key={`row-${prospect.id}`}>
              <td>
                {prospect.code}
              </td>
              <td>
                {prospect.label}
              </td>
              <td align='center'>
                <button
                  data-target="#modal-prospect-editor"
                  data-toggle="modal"
                  className='btn btn-primary'
                  aria-label="edit prospect"
                  onClick={() => onEdit(prospect)}>
                  {t(TXT_EDIT).toUpperCase()}
                </button>
              </td>
              <td align='center'>
                <button
                  data-target="#modal-default"
                  data-toggle="modal"
                  className='btn btn-danger'
                  aria-label="delete prospect"
                  type="button"
                  onClick={() => onDelete(prospect)}>
                  {t(TXT_DELETE).toUpperCase()}
                </button>
              </td>
            </tr>
          ))
        }
        {
          !loading &&
          (!data ||
            !data.prospects ||
            !data.prospects.items ||
            data.prospects.items.length <= 0) &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {capitalizeFirstLetter(t(TXT_DATA_NOT_FOUND))}
              </CustomError>
            </td>
          </tr>
        }
      </tbody>
    );
  };
