import { gql } from '@apollo/client';
import { weatheringQueryResult } from './weatheringQueries';

export const ADD_WEATHERING = gql`
  mutation addWeathering($input: AddWeatheringInput!) {
    addWeathering(input: $input){
      ${weatheringQueryResult}
    }
  }
`;

export const UPDATE_WEATHERING = gql`
  mutation updateWeathering($input: UpdateWeatheringInput!) {
    updateWeathering(input: $input){
      ${weatheringQueryResult}
    }
  }
`;

export const DELETE_WEATHERING = gql`
  mutation deleteWeathering($input: DeleteWeatheringInput!) {
    deleteWeathering(input: $input)
  }
`;
