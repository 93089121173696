import { gql } from '@apollo/client';

export const blockQueryResult = `
  id,
  code,
  label
`;

export const GET_BLOCK = gql`
  query getBlocks($code: String, $skip: Int, $take: Int) {
    blocks(code: $code, skip: $skip, take: $take) {
      items {
        ${blockQueryResult}
      },
      totalCount
    }
  }
`;
