import { gql } from '@apollo/client';
import { infillAlterationQueryResult } from './infillAlterationQueries';

export const ADD_INFILL_ALTERATION = gql`
  mutation addInfillAlteration($input: AddInfillAlterationInput!) {
    addInfillAlteration(input: $input){
      ${infillAlterationQueryResult}
    }
  }
`;

export const UPDATE_INFILL_ALTERATION = gql`
  mutation updateInfillAlteration($input: UpdateInfillAlterationInput!) {
    updateInfillAlteration(input: $input){
      ${infillAlterationQueryResult}
    }
  }
`;

export const DELETE_INFILL_ALTERATION = gql`
  mutation deleteInfillAlteration($input: DeleteInfillAlterationInput!) {
    deleteInfillAlteration(input: $input)
  }
`;
