/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { getInfillMineralOutput } from '../../../../api';
import { manageCodeLabelColumns } from '../../../../components';
import { infillMineral } from '../../../../entityModel';
import { ManageInfillMineralsTableBody } from './ManageInfillMineralsTableBody';
import { ManageInfillMineralsTableHead } from './ManageInfillMineralsTableHead';

interface ManageInfillMineralsTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getInfillMineralOutput;
  onEdit: (infillMineral: infillMineral) => void;
  onDelete: (infillMineral: infillMineral) => void;
}

export const ManageInfillMineralsTable = ({ error, loading, data, onEdit, onDelete, ...rest }: ManageInfillMineralsTableProps) => {
  return (
    <table className="table table-bordered">
      <ManageInfillMineralsTableHead
        columns={manageCodeLabelColumns}
      />
      <ManageInfillMineralsTableBody
        columns={manageCodeLabelColumns}
        loading={loading}
        error={error}
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </table>
  );
};
