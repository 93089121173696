/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import { CustomError, ManageOrderCodeLabelColumn } from '../../../../components';
import { strength } from '../../../../entityModel';
import {
  TXT_DATA_NOT_FOUND,
  TXT_DELETE,
  TXT_EDIT,
  TXT_LOADING
} from '../../../../translations/translationConstants';
import { capitalizeFirstLetter } from '../../../../shared/stringFunctions';
import { getStrengthOutput } from '../../../../api';
import { ApolloError } from '@apollo/client';

export interface ManageStrengthsTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: getStrengthOutput;
  columns: ManageOrderCodeLabelColumn[];
  onEdit: (strength: strength) => void;
  onDelete: (strength: strength) => void;
}

export const ManageStrengthsTableBody =
  ({ error, columns, loading, data, onEdit, onDelete }: ManageStrengthsTableBodyProps) => {
    const { t } = useTranslation();
    return (
      <tbody>
        {
          loading &&
          <tr>
            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
              <h3>
                {t(TXT_LOADING).toUpperCase()}
              </h3>
            </td>
          </tr>
        }
        {
          !loading && error && error.message &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {error.message}
              </CustomError>
            </td>
          </tr>
        }
        {
          !loading && data &&
          data.strengths && data.strengths.items &&
          data.strengths.items.length > 0 &&
          data.strengths.items.map((strength) => (
            <tr key={`row-${strength.id}`}>
              <td>
                {strength.order}
              </td>
              <td>
                {strength.code}
              </td>
              <td>
                {strength.label}
              </td>
              <td align='center'>
                <button
                  data-target="#modal-strength-editor"
                  data-toggle="modal"
                  className='btn btn-primary'
                  aria-label="edit strength"
                  onClick={() => onEdit(strength)}>
                  {t(TXT_EDIT).toUpperCase()}
                </button>
              </td>
              <td align='center'>
                <button
                  data-target="#modal-default"
                  data-toggle="modal"
                  className='btn btn-danger'
                  aria-label="delete strength"
                  type="button"
                  onClick={() => onDelete(strength)}>
                  {t(TXT_DELETE).toUpperCase()}
                </button>
              </td>
            </tr>
          ))
        }
        {
          !loading &&
          (!data ||
            !data.strengths ||
            !data.strengths.items ||
            data.strengths.items.length <= 0) &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {capitalizeFirstLetter(t(TXT_DATA_NOT_FOUND))}
              </CustomError>
            </td>
          </tr>
        }
      </tbody>
    );
  };
