/* eslint-disable max-len */
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ManageGeotechnicalDataColumn } from '.';

export interface ManageGeotechnicalDataTableHeadProps {
  columns: ManageGeotechnicalDataColumn[];
};

export const ManageGeotechnicalDataTableHead = memo((props: ManageGeotechnicalDataTableHeadProps) => {
  const { t } = useTranslation();
  return (
    <thead>
      <tr>
        {
          props.columns.map((col) => (
            <th
              key={`head-${col.id}`}
              style={{ width: col.maxWidth, textAlign: col.align }}
            >
              {t(col.label).toUpperCase()}
            </th>
          ))
        }
      </tr>
    </thead>
  );
});

ManageGeotechnicalDataTableHead.displayName = 'ManageGeotechnicalDataTableHead';
