import {
  TXT_DELETE,
  TXT_EDIT,
  TXT_EMAIL,
  TXT_ENABLED,
  TXT_MOBILE_USER,
  TXT_NAME,
  TXT_PHONE,
  TXT_ROLE,
  TXT_USERNAME
} from '../../../../translations/translationConstants';

export interface ManageUsersColumn {
  id: 'username' | 'name' | 'email' | 'phone' |
  'role' | 'enabled' | 'userMobile' | 'edit' | 'delete';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

export const manageUserColumns: ManageUsersColumn[] = [
  {
    id: 'username',
    label: TXT_USERNAME,
    maxWidth: '15%',
    align: 'left'
  },
  {
    id: 'name',
    label: TXT_NAME,
    maxWidth: '25%',
    align: 'left'
  },
  {
    id: 'email',
    label: TXT_EMAIL,
    maxWidth: '10%',
    align: 'left'
  },
  {
    id: 'phone',
    label: TXT_PHONE,
    maxWidth: '10%',
    align: 'left'
  },
  {
    id: 'role',
    label: TXT_ROLE,
    maxWidth: '10%'
  },
  {
    id: 'enabled',
    label: TXT_ENABLED,
    maxWidth: '5%'
  },
  {
    id: 'userMobile',
    label: TXT_MOBILE_USER,
    maxWidth: '5%'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '5%'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '5%'
  }
];
