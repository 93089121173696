import { gql } from '@apollo/client';

export const roughnessQueryResult = `
  id,
  code,
  label
`;

export const GET_ROUGHNESS = gql`
  query getRoughnesses($code: String, $skip: Int, $take: Int) {
    roughnesses(code: $code, skip: $skip, take: $take) {
      items {
        ${roughnessQueryResult}
      },
      totalCount
    }
  }
`;
