/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  getApplicationUsersOutput
} from '../../../../api';
import { CustomError } from '../../../../components';
import { applicationUser } from '../../../../entityModel';
import { capitalizeFirstLetter } from '../../../../shared/stringFunctions';
import { TXT_DATA_NOT_FOUND, TXT_DELETE, TXT_EDIT, TXT_LOADING } from '../../../../translations/translationConstants';
import { ManageUsersColumn } from './manageUsersColumns';

export interface ManageUsersTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: getApplicationUsersOutput;
  columns: ManageUsersColumn[];
  onEdit: (user: applicationUser) => void;
  onDelete: (user: applicationUser) => void;
  changeEnablement: (user: applicationUser) => void;
}

export const ManageUsersTableBody =
  ({ error, columns, loading, data, onEdit, onDelete, changeEnablement }: ManageUsersTableBodyProps) => {
    const { t } = useTranslation();
    return (
      <tbody>
        {
          loading &&
          <tr>
            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
              <h3>
                {t(TXT_LOADING).toUpperCase()}
              </h3>
            </td>
          </tr>
        }
        {
          !loading && error && error.message &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {error.message}
              </CustomError>
            </td>
          </tr>
        }
        {
          !loading && data &&
          data.users && data.users.items &&
          data.users.items.length > 0 &&
          data.users.items.map((user) => (
            <tr key={`row-${user.username}`}>
              <td>
                {user.username}
              </td>
              <td>
                {user.profile?.fullName}
              </td>
              <td>
                {
                  user.profile && user.profile.email &&
                  <a href={`mailto:${user.profile.email}`}>
                    {user.profile.email}
                  </a>
                }
              </td>
              <td>
                {user.profile?.phone}
              </td>
              <td>
                {user.role?.name}
              </td>
              <td align='center'>
                <input
                  type='checkbox'
                  checked={!!user.isEnabled}
                  onChange={() => changeEnablement(user)}
                  data-bootstrap-switch
                />
              </td>
              <td align='center'>
                <input
                  type='checkbox'
                  checked={!!user.isMobile}
                  data-bootstrap-switch
                  readOnly
                />
              </td>
              <td align='center'>
                <button
                  data-target="#modal-user-editor"
                  data-toggle="modal"
                  className='btn btn-primary'
                  aria-label="edit user"
                  onClick={() => onEdit(user)}>
                  {t(TXT_EDIT).toUpperCase()}
                </button>
              </td>
              <td align='center'>
                <button
                  data-target="#modal-default"
                  data-toggle="modal"
                  className='btn btn-danger'
                  aria-label="delete user"
                  type="button"
                  onClick={() => onDelete(user)}>
                  {t(TXT_DELETE).toUpperCase()}
                </button>
              </td>
            </tr>
          ))
        }
        {
          !loading &&
          (!data ||
            !data.users ||
            !data.users.items ||
            data.users.items.length <= 0) &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {capitalizeFirstLetter(t(TXT_DATA_NOT_FOUND))}
              </CustomError>
            </td>
          </tr>
        }
      </tbody>
    );
  };
