import { gql } from '@apollo/client';

export const applicationRoleQueryResult = `
  id,
  name
`;

export const GET_APPLICATION_ROLES = gql`
  query getRoles($name: String, $skip: Int, $take: Int) {
    roles(name: $name, skip: $skip, take: $take) {
      items {
        ${applicationRoleQueryResult}
      },
      totalCount
    }
  }
`;
