import {
  TXT_SIGN_IN,
  TXT_USERNAME,
  TXT_PASSWORD,
  TXT_SIGNING_IN,
  TXT_FIELD_IS_REQUIRED,
  TXT_SIGNING_OUT,
  TXT_DARK_THEME,
  TXT_LIGHT_THEME,
  TXT_HOME,
  TXT_SIGN_OUT,
  TXT_SEARCH_BY_USERNAME_OR_NAME,
  TXT_USERS_MANAGEMENT,
  TXT_WELCOME,
  TXT_MENUS,
  TXT_ROLES,
  TXT_USERS,
  TXT_DELETE,
  TXT_EDIT,
  TXT_EMAIL,
  TXT_ENABLED,
  TXT_NAME,
  TXT_PHONE,
  TXT_CHANGE_PASSWORD,
  TXT_ROLE,
  TXT_LOADING,
  TXT_DATA_NOT_FOUND,
  TXT_ROWS_PER_PAGE,
  TXT_DISPLAYED_ROWS,
  TXT_ADD_USER,
  TXT_SAVE,
  TXT_SAVING,
  TXT_EDIT_USER,
  TXT_REPASSWORD,
  TXT_PASSWORD_REPASSWORD_MISMATCH,
  TXT_DELETE_CONFIRMATION,
  TXT_NO,
  TXT_YES,
  TXT_DELETE_CONFIRMATION_TITLE,
  TXT_ALL,
  TXT_SEARCH_BY_NAME,
  TXT_ADD_ROLE,
  TXT_EDIT_ROLE,
  TXT_CANCEL,
  TXT_OLD_PASSWORD
} from './translationConstants';

const idID = {
  [TXT_WELCOME]: 'selamat datang, {{name}}',
  [TXT_SIGN_IN]: 'masuk',
  [TXT_USERNAME]: 'nama pengguna',
  [TXT_PASSWORD]: 'kata sandi',
  [TXT_SIGNING_IN]: 'masuk',
  [TXT_SIGNING_OUT]: 'keluar',
  [TXT_SIGN_OUT]: 'keluar',

  [TXT_LIGHT_THEME]: 'tema terang',
  [TXT_DARK_THEME]: 'tema gelap',

  [TXT_HOME]: 'asal',

  [TXT_FIELD_IS_REQUIRED]: 'bagian ini harus diisi',

  [TXT_USERS_MANAGEMENT]: 'pengaturan pengguna',

  [TXT_SEARCH_BY_USERNAME_OR_NAME]: 'pencarian berdasarkan nama pengguna',

  [TXT_USERS]: 'pengguna',
  [TXT_ROLES]: 'wewenang',
  [TXT_MENUS]: 'menu',

  [TXT_NAME]: 'name',
  [TXT_EMAIL]: 'surat elektronik',
  [TXT_PHONE]: 'telepon',
  [TXT_ROLE]: 'wewenang',
  [TXT_ENABLED]: 'aktif',
  [TXT_REPASSWORD]: 'ketik ulang kata sandi',

  [TXT_CHANGE_PASSWORD]: 'ubah sandi',
  [TXT_EDIT]: 'ubah',
  [TXT_DELETE]: 'hapus',

  [TXT_LOADING]: 'memuat',

  [TXT_DATA_NOT_FOUND]: 'data tidak ditemukan',

  [TXT_ROWS_PER_PAGE]: 'baris per halaman',
  [TXT_DISPLAYED_ROWS]: '{{from}} - {{to}} dari {{count}}',

  [TXT_ADD_USER]: 'tambah pengguna baru',
  [TXT_EDIT_USER]: 'ubah data pengguna',

  [TXT_SAVE]: 'simpan',
  [TXT_SAVING]: 'menyimpan',
  [TXT_CANCEL]: 'batal',

  // eslint-disable-next-line max-len
  [TXT_PASSWORD_REPASSWORD_MISMATCH]: 'kata sandi tidak sama dengan ketik ulang kata sandi',

  [TXT_DELETE_CONFIRMATION_TITLE]: 'konfirmasi penghapusan',
  [TXT_DELETE_CONFIRMATION]: 'apakah anda yakin ingin menghapus "{{name}}"?',

  [TXT_YES]: 'ya',
  [TXT_NO]: 'tidak',

  [TXT_ALL]: 'semua',

  [TXT_SEARCH_BY_NAME]: 'pencarian berdasarkan nama',

  [TXT_ADD_ROLE]: 'tambah wewenang baru',
  [TXT_EDIT_ROLE]: 'ubah wewenang',

  [TXT_OLD_PASSWORD]: 'kata sandi sebelumnya'
};

export default idID;
