import { gql } from '@apollo/client';

export const infillMineralQueryResult = `
  id,
  code,
  label
`;

export const GET_INFILL_MINERAL = gql`
  query getInfillMinerals($code: String, $skip: Int, $take: Int) {
    infillMinerals(code: $code, skip: $skip, take: $take) {
      items {
        ${infillMineralQueryResult}
      },
      totalCount
    }
  }
`;
