/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { memo } from 'react';
import { getLogHoleSizeOutput } from '../../../../api';
import { logHoleSize } from '../../../../entityModel';
import { manageLogHoleSizeColumns } from './manageLogHoleSizeColumns';
import { ManageLogHoleSizeTableBody } from './ManageLogHoleSizeTableBody';
import { ManageLogHoleSizeTableHead } from './ManageLogHoleSizeTableHead';

interface ManageLogHoleSizesTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getLogHoleSizeOutput;
  onEdit: (user: logHoleSize) => void;
  onDelete: (user: logHoleSize) => void;
}

export const ManageLogHoleSizesTable = memo(({ error, loading, data, onEdit, onDelete, ...rest }: ManageLogHoleSizesTableProps) => {
  return (
    <table className="table table-bordered">
      <ManageLogHoleSizeTableHead
        columns={manageLogHoleSizeColumns}
      />
      <ManageLogHoleSizeTableBody
        columns={manageLogHoleSizeColumns}
        loading={loading}
        error={error}
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </table>
  );
});

ManageLogHoleSizesTable.displayName = 'ManageLogHoleSizesTable';
