import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { graphqlUrl } from '../../../shared/links';

const httpLink = createHttpLink({
  uri: graphqlUrl
});

const getAuthLink = (token: string) => {
  return setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    };
  });
};

export const createGraphQLClient = (token: string) => {
  return new ApolloClient({
    link: getAuthLink(token).concat(httpLink),
    cache: new InMemoryCache()
  });
};
