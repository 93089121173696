/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import { apiBaseUrl } from '../../shared/links';
import { capitalizeFirstLetter } from '../../shared/stringFunctions';
import {
  TXT_SHOW_PHOTO
} from '../../translations/translationConstants';

export interface PhotoModalProps {
  filePath: string;
  objectName: string;
};

export const PhotoModal = (props: PhotoModalProps) => {
  const { t } = useTranslation();
  // const theme = useTheme();
  const title = capitalizeFirstLetter(t(TXT_SHOW_PHOTO, {
    name: props.objectName
  }));

  return (
    <div className="modal fade" id="modal-photo-default">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <img className="product-image" src={apiBaseUrl !== undefined ? apiBaseUrl + props.filePath : '#'} />
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>

  );
};
