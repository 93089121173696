import {
  TXT_CORE_SIZE,
  TXT_DELETE,
  TXT_EDIT,
  TXT_FROM,
  TXT_TO
} from '../../../../translations/translationConstants';

export interface ManageLogHoleSizeColumn {
  id: 'coreSize' | 'from' | 'to' | 'edit' | 'delete';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

export const manageLogHoleSizeColumns: ManageLogHoleSizeColumn[] = [
  {
    id: 'coreSize',
    label: TXT_CORE_SIZE,
    maxWidth: '30%',
    align: 'left'
  },
  {
    id: 'from',
    label: TXT_FROM,
    maxWidth: '30%',
    align: 'left'
  },
  {
    id: 'to',
    label: TXT_TO,
    maxWidth: '30%',
    align: 'left'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '5%'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '5%'
  }
];
