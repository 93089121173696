/* eslint-disable max-len */
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ManageGeologicalDataColumn } from '.';

export interface ManageGeologicalDataTableHeadProps {
  columns: ManageGeologicalDataColumn[];
};

export const ManageGeologicalDataTableHead = memo((props: ManageGeologicalDataTableHeadProps) => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        {
          props.columns.map((col, index, array) => {
            const prevColSpan = index > 0 ? array[index-1].colSpan : undefined;

            let colSpan = 0;
            if (col.colSpan !== 'none') {
              colSpan = array.filter((x) => x.colSpan === col.colSpan).length;
            }

            if (prevColSpan === col.colSpan && colSpan > 0) {
              return null;
            }

            return (
              <th
                key={`head-${col.id}`}
                style={{ width: col.maxWidth, textAlign: col.align, verticalAlign: 'middle' }}
                {...col.colSpan === 'none' && { rowSpan: 2 }}
                {...colSpan > 0 && { colSpan: colSpan }}
              >
                {colSpan > 0 ? t(col.colSpan!).toUpperCase() : t(col.label).toUpperCase()}
              </th>
            );
          })
        }
      </tr>
      <tr>
        {
          props.columns.map((col) => (
            col.colSpan !== 'none' &&
            <th
              key={`head-${col.id}`}
              style={{ width: col.maxWidth, textAlign: col.align, verticalAlign: 'middle' }}
            >
              {t(col.label).toUpperCase()}
            </th>
          ))
        }
      </tr>
    </thead>
  );
});

ManageGeologicalDataTableHead.displayName = 'ManageGeologicalDataTableHead';
