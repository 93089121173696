import { gql } from '@apollo/client';
import { applicationRoleQueryResult } from '.';

export const ADD_APPLICATION_ROLE = gql`
  mutation addRole($input: AddApplicationRoleInput!) {
    addRole(input: $input){
      ${applicationRoleQueryResult}
    }
  }
`;

export const UPDATE_APPLICATION_ROLE = gql`
  mutation updateRole($input: UpdateApplicationRoleInput!) {
    updateRole(input: $input){
      ${applicationRoleQueryResult}
    }
  }
`;

export const DELETE_APPLICATION_ROLE = gql`
  mutation deleteRole($input: DeleteApplicationRoleInput!) {
    deleteRole(input: $input)
  }
`;
