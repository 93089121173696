/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import { CustomError } from '../../../../components';
import { geologicalData } from '../../../../entityModel';
import {
  TXT_DATA_NOT_FOUND,
  TXT_DELETE,
  TXT_EDIT,
  TXT_GEOTECHNICAL,
  TXT_LOADING
} from '../../../../translations/translationConstants';
import { capitalizeFirstLetter } from '../../../../shared/stringFunctions';
import { ApolloError } from '@apollo/client';
import { ManageGeologicalDataColumn } from './manageGeologicalDataColumns';
import { getGeologicalDataOutput } from '../../../../api';
import { memo } from 'react';

export interface ManageGeologicalDatasTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: getGeologicalDataOutput;
  columns: ManageGeologicalDataColumn[];
  onEdit: (geologicalData: geologicalData) => void;
  onDetail: (geologicalData: geologicalData) => void;
  onDelete: (geologicalData: geologicalData) => void;
}

export const ManageGeologicalDataTableBody =
  memo(({ error, columns, loading, data, onEdit, onDetail, onDelete }: ManageGeologicalDatasTableBodyProps) => {
    const { t } = useTranslation();
    return (
      <tbody>
        {
          loading &&
          <tr>
            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
              <h3>
                {t(TXT_LOADING).toUpperCase()}
              </h3>
            </td>
          </tr>
        }
        {
          !loading && error && error.message &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {error.message}
              </CustomError>
            </td>
          </tr>
        }
        {
          !loading && data &&
          data.geologicalDatas && data.geologicalDatas.items &&
          data.geologicalDatas.items.length > 0 &&
          data.geologicalDatas.items.map((geologicalData) => (
            <tr key={`row-${geologicalData.id}`}>
              <td>
                {geologicalData.from}
              </td>
              <td>
                {geologicalData.to}
              </td>
              <td>
                {geologicalData.lithology && geologicalData.lithology.code}
              </td>
              <td>
                {geologicalData.veinQual1 && geologicalData.veinQual1.code}
              </td>
              <td>
                {geologicalData.veinQual2 && geologicalData.veinQual2.code}
              </td>
              <td>
                {geologicalData.veinQual3 && geologicalData.veinQual3.code}
              </td>
              <td>
                {geologicalData.alteration1 && geologicalData.alteration1.code}
              </td>
              <td>
                {geologicalData.alteration2 && geologicalData.alteration2.code}
              </td>
              <td>
                {geologicalData.stockwork}
              </td>
              <td>
                {geologicalData.minCy}
              </td>
              <td>
                {geologicalData.minQz}
              </td>
              <td>
                {geologicalData.minCh}
              </td>
              <td>
                {geologicalData.minCb}
              </td>
              <td>
                {geologicalData.minSx}
              </td>
              <td>
                {geologicalData.minGs}
              </td>
              <td>
                {geologicalData.minSnGn}
              </td>
              <td>
                {geologicalData.minCp}
              </td>
              <td>
                {geologicalData.minAu}
              </td>
              <td>
                {geologicalData.coreRecLength}
              </td>
              <td>
                {geologicalData.coreRecPrc}
              </td>
              <td>
                {geologicalData.coreLength}
              </td>
              <td>
                {geologicalData.remarks}
              </td>
              <td align='center'>
                <button
                  className='btn btn-primary btn-sm'
                  onClick={() => onDetail(geologicalData)}>
                  {t(TXT_GEOTECHNICAL).toUpperCase()}
                </button>
              </td>
              <td align='center'>
                <button
                  data-target="#modal-geologicalData-editor"
                  data-toggle="modal"
                  className='btn btn-primary btn-sm'
                  aria-label="edit geologicalData"
                  onClick={() => onEdit(geologicalData)}>
                  {t(TXT_EDIT).toUpperCase()}
                </button>
              </td>
              <td align='center'>
                <button
                  data-target="#modal-default"
                  data-toggle="modal"
                  className='btn btn-danger btn-sm'
                  aria-label="delete geologicalData"
                  type="button"
                  onClick={() => onDelete(geologicalData)}>
                  {t(TXT_DELETE).toUpperCase()}
                </button>
              </td>
            </tr>
          ))
        }
        {
          !loading &&
          (!data ||
            !data.geologicalDatas ||
            !data.geologicalDatas.items ||
            data.geologicalDatas.items.length <= 0) &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {capitalizeFirstLetter(t(TXT_DATA_NOT_FOUND))}
              </CustomError>
            </td>
          </tr>
        }
      </tbody>
    );
  });

ManageGeologicalDataTableBody.displayName = 'ManageGeologicalDataTableBody';

