/* eslint-disable max-len */
import { gql } from '@apollo/client';
import { baseCodeLabelQueryResult } from '../..';
import { boreholeQueryResult } from '../borehole';

export const logHoleSizeQueryResult = `
  id
  code
  from
  to
  holeSize {
    ${baseCodeLabelQueryResult}
  }
  borehole {
    ${boreholeQueryResult}
  }
`;

export const GET_LOG_HOLE_SIZE = gql`
  query getLogHoleSizes($code: String, $skip: Int, $take: Int, $filter: LogHoleSizeFilterInput) {
    logHoleSizes(code: $code, skip: $skip, take: $take, where: $filter) {
      items {
        ${logHoleSizeQueryResult}
      },
      totalCount
    }
  }
`;
