import {
  TXT_BLOCK,
  TXT_CODE,
  TXT_COLLAR,
  TXT_DELETE,
  TXT_EASTING,
  TXT_EDIT,
  TXT_EXPORT,
  TXT_LENGTH,
  TXT_NORTING,
  TXT_PROSPECT,
  TXT_STATUS
} from '../../../../translations/translationConstants';

export interface ManageBoreholesColumn {
  id: 'code' | 'block' | 'prospect' | 'length' |
  'norting' | 'easting' | 'collar' | 'status' | 'edit' | 'delete' | 'export';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

export const manageBoreholeColumns: ManageBoreholesColumn[] = [
  {
    id: 'code',
    label: TXT_CODE,
    maxWidth: '15%',
    align: 'left'
  },
  {
    id: 'block',
    label: TXT_BLOCK,
    maxWidth: '15%',
    align: 'left'
  },
  {
    id: 'prospect',
    label: TXT_PROSPECT,
    maxWidth: '15%',
    align: 'left'
  },
  {
    id: 'length',
    label: TXT_LENGTH,
    maxWidth: '5%',
    align: 'left'
  },
  {
    id: 'norting',
    label: TXT_NORTING,
    maxWidth: '10%'
  },
  {
    id: 'easting',
    label: TXT_EASTING,
    maxWidth: '10%'
  },
  {
    id: 'collar',
    label: TXT_COLLAR,
    maxWidth: '10%'
  },
  {
    id: 'status',
    label: TXT_STATUS,
    maxWidth: '5%'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '5%'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '5%'
  },
  {
    id: 'export',
    label: TXT_EXPORT,
    maxWidth: '5%'
  }
];
