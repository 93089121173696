import { gql } from '@apollo/client';
import { geologicalDataQueryResult } from './geologicalDataQueries';

export const ADD_GEOLOGICAL_DATA = gql`
  mutation addGeologicalData($input: AddGeologicalDataInput!) {
    addGeologicalData(input: $input){
      ${geologicalDataQueryResult}
    }
  }
`;

export const UPDATE_GEOLOGICAL_DATA = gql`
  mutation updateGeologicalData($input: UpdateGeologicalDataInput!) {
    updateGeologicalData(input: $input){
      ${geologicalDataQueryResult}
    }
  }
`;

export const DELETE_GEOLOGICAL_DATA = gql`
  mutation deleteGeologicalData($input: DeleteGeologicalDataInput!) {
    deleteGeologicalData(input: $input)
  }
`;
