/* eslint-disable max-len */

import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  addApplicationUserInput,
  ADD_APPLICATION_USER,
  CHANGE_USER_ENABLEMENT,
  deleteApplicationUserInput,
  DELETE_APPLICATION_USER,
  getApplicationRolesOutput,
  getApplicationUsersInput,
  getApplicationUsersOutput,
  GET_APPLICATION_ROLES,
  GET_APPLICATION_USERS,
  updateApplicationUserInput,
  UPDATE_APPLICATION_USER,
  UserEnablementInput
} from '../../../api';
import { applicationRole, applicationUser } from '../../../entityModel';
import { useApolloErrorHandler, useAuthServices } from '../../../hooks';
import { ManageUsersTable } from './ManageUsersTable';
import { ManageUserEditor } from './ManageUserEditor';
import { DeleteConfirmation, globalPageSizeOptions, PaginationBar } from '../../../components';
import { ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { TXT_ADD_USER, TXT_ALL, TXT_ROLE, TXT_SEARCH_BY_USERNAME_OR_NAME, TXT_USERS_MANAGEMENT } from '../../../translations/translationConstants';
import { capitalizeFirstLetter, capitalizeFirstLetterEveryword } from '../../../shared/stringFunctions';

export const ManageUsers = () => {
  const { t } = useTranslation();

  const emptyRole: applicationRole = {
    id: 'all',
    name: capitalizeFirstLetter(t(TXT_ALL))
  };
  const [role, setRole] = useState<string | undefined>(emptyRole.id);
  const [roles, setRoles] = useState<applicationRole[]>([]);
  const [txtSearch, setTxtSearch] = useState<string | undefined>('');
  const { isSignedIn } = useAuthServices();

  const [getUsers, { loading, error, data, refetch }] = useLazyQuery<getApplicationUsersOutput>(GET_APPLICATION_USERS, {
    fetchPolicy: 'cache-and-network'
  });

  const getRolesOperation = useQuery<getApplicationRolesOutput>(GET_APPLICATION_ROLES, {
    onCompleted: (result) => {
      setRoles([
        emptyRole,
        ...result.roles.items
      ]);
    }
  });

  const [setEnablement, setEnablementVariables] = useMutation(CHANGE_USER_ENABLEMENT, {
    onCompleted: () => {
      if (refetch) {
        refetch();
      }
    }
  });

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(globalPageSizeOptions[0]);

  const [selectedUser, setSelectedUser] = useState<applicationUser | undefined>(undefined);
  const [openEditor, setOpenEditor] = useState(false);
  const [savingError, setSavingError] = useState<string | undefined>(undefined);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const onRoleChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRole(event.target.value as string);
  };

  const onTxtSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTxtSearch(event.currentTarget.value);
  };

  const fetchUsers = () => {
    const variables: getApplicationUsersInput = {
      roleId: role === emptyRole.id ? undefined : role,
      usernameOrName: txtSearch,
      take: pageSize,
      skip: (pageNumber - 1) * pageSize
    };
    getUsers({
      variables
    });
  };

  const fetch = (resetPage?: boolean) => {
    if (!!resetPage && pageNumber !== 1) {
      setPageNumber(1);
    } else {
      fetchUsers();
    }
  };

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, error);
  }, [error]);

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, setEnablementVariables.error);
  }, [setEnablementVariables.error]);

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, getRolesOperation.error);
  }, [getRolesOperation.error]);

  useEffect(() => {
    fetch();
  }, [pageNumber]);

  useEffect(() => {
    fetch(true);
  }, [pageSize, txtSearch, role]);

  const onPageSizeChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(+event.target.value);
  };

  const openEditUserEditor = (user: applicationUser) => {
    setSelectedUser(user);
    setOpenEditor(true);
  };

  const openDeleteUserConfirmation = (user: applicationUser) => {
    setSelectedUser(user);
    setOpenDeleteConfirmation(true);
  };

  const changeEnablement = (user: applicationUser) => {
    const input: UserEnablementInput = {
      email: user.profile!.email!,
      isEnabled: !user.isEnabled!
    };
    setEnablement({
      variables: {
        input
      }
    });
  };

  const openAddUserEditor = () => {
    setSelectedUser(undefined);
    setOpenEditor(true);
  };

  const closeEditor = () => {
    setOpenEditor(false);
  };

  const afterAddingOrUpdatingUser = () => {
    setOpenEditor(false);
    if (refetch) {
      refetch();
    }
  };

  const handleAddingOrUpdatingUserError = (error: ApolloError) => {
    if (error.graphQLErrors.length > 0 &&
      !!error.graphQLErrors[0].extensions) {
      setSavingError(error.graphQLErrors[0].extensions['message']);
    } else {
      setSavingError(error.message);
    }
  };

  const [addUser, addUserOperation] = useMutation<applicationUser>(ADD_APPLICATION_USER, {
    onError: (e) => handleAddingOrUpdatingUserError(e),
    onCompleted: (result) => afterAddingOrUpdatingUser()
  });
  const [updateUser, updateUserOperation] = useMutation<applicationUser>(UPDATE_APPLICATION_USER, {
    onError: (e) => handleAddingOrUpdatingUserError(e),
    onCompleted: (result) => afterAddingOrUpdatingUser()
  });

  const onEditorSave = (payload: addApplicationUserInput | updateApplicationUserInput) => {
    if (!selectedUser) {
      addUser({
        variables: {
          input: payload
        }
      });
    } else {
      updateUser({
        variables: {
          input: payload
        }
      });
    }
  };

  const onDeleteCancel = () => setOpenDeleteConfirmation(false);
  const [deleteUser, deleteUserOperation] = useMutation<boolean>(DELETE_APPLICATION_USER, {
    onError: (e) => { },
    onCompleted: (result) => {
      if (data && refetch) {
        if (data.users.items.length == 1) {
          const targetPage = pageNumber - 1 <= 0 ? 1 : pageNumber - 1;
          if (pageNumber !== targetPage) {
            setPageNumber(targetPage);
          } else {
            refetch();
          }
        } else {
          refetch();
        }
      }
      onDeleteCancel();
    }
  });
  const onDelete = () => {
    const input: deleteApplicationUserInput = {
      email: selectedUser!.profile!.email!
    };
    deleteUser({
      variables: {
        input
      }
    });
  };

  const lblSearch = capitalizeFirstLetterEveryword(t(TXT_SEARCH_BY_USERNAME_OR_NAME));
  const lblRole = capitalizeFirstLetterEveryword(t(TXT_ROLE));
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{t(capitalizeFirstLetterEveryword(TXT_USERS_MANAGEMENT))}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          {
            openEditor &&
            <ManageUserEditor
              user={selectedUser}
              onClose={closeEditor}
              onSave={onEditorSave}
              isSaving={
                !selectedUser ? addUserOperation.loading :
                  updateUserOperation.loading
              }
              savingError={savingError}
            />
          }
          {
            openDeleteConfirmation &&
            <DeleteConfirmation
              loading={deleteUserOperation.loading}
              onYes={onDelete}
              onNo={onDeleteCancel}
              objectName={selectedUser?.profile?.fullName || ''}
            />
          }
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className='row'>
                    <div className='col-6'>
                      <form>
                        <div className="form-group">
                          <label>{lblRole}</label>
                          <select
                            className="form-control custom-select"
                            value={
                              getRolesOperation.loading || roles.length <= 0 ? '' : role
                            }
                            onChange={onRoleChanged}
                            disabled={getRolesOperation.loading}
                          >
                            {
                              !getRolesOperation.loading &&
                              roles.map((item, index) => (
                                <option key={`role-${index}`} value={item.id}>
                                  {item.name}
                                </option>
                              ))
                            }
                          </select>
                        </div>
                      </form>
                    </div>
                    <div className='col-6'>
                      <div className="form-group">
                        <label>{lblSearch}</label>
                        <input type='text' className='form-control' value={txtSearch} onChange={onTxtSearchChanged} />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                      <button
                        className='btn btn-primary'
                        data-target="#modal-user-editor"
                        data-toggle="modal"
                        disabled={loading}
                        onClick={openAddUserEditor}>
                        {t(TXT_ADD_USER).toUpperCase()}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ManageUsersTable
                    loading={loading}
                    data={data}
                    error={error}
                    onEdit={openEditUserEditor}
                    onDelete={openDeleteUserConfirmation}
                    changeEnablement={changeEnablement}
                  />
                </div>
                <PaginationBar
                  currentPage={pageNumber}
                  totalCount={data?.users.totalCount}
                  pageSize={pageSize}
                  onPageChanged={(page) => setPageNumber(page)}
                  onChangeRowsPerPage={onPageSizeChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
