/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import {
  capitalizeFirstLetterEveryword
} from '../../../shared/stringFunctions';
import {
  TXT_BOREHOLES,
  TXT_EXPORT,
  TXT_SEARCH_BY_NAME
} from '../../../translations/translationConstants';
import {
  useLazyQuery,
  useMutation } from '@apollo/client';
import {
  deleteBoreholeInput,
  getBoreholeOutput,
  GET_BOREHOLE,
  getBoreholesInput,
  excelBoreholeService,
  excelBoreholeDetailService
} from '../../../api';
import { useEffect } from 'react';
import { useState } from 'react';
import { ManageBoreholesTable } from './ManageBoreholeTable';
import {
  DeleteConfirmation,
  globalPageSizeOptions,
  PaginationBar
} from '../../../components';
import { borehole } from '../../../entityModel';
import { useApolloErrorHandler, useAuth, useAuthServices } from '../../../hooks';
import {
  DELETE_BOREHOLE
} from '../../../api';
import { useHistory } from 'react-router-dom';

export const ManageBoreholes = () => {
  const { t } = useTranslation();
  const { isSignedIn } = useAuthServices();
  const history = useHistory();
  const [auth] = useAuth();

  const [txtSearch, setTxtSearch] = useState('');

  const onTxtSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTxtSearch(event.currentTarget.value);
  };

  const [selectedBorehole, setSelectedBorehole] = useState<borehole | undefined>(undefined);
  const [getBoreholes, { loading, data, error, refetch }] = useLazyQuery<getBoreholeOutput>(GET_BOREHOLE);

  useEffect(() => {
    getBoreholes();
  }, []);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(globalPageSizeOptions[0]);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const lblSearch = capitalizeFirstLetterEveryword(t(TXT_SEARCH_BY_NAME));

  const fetchBoreholes = () => {
    const variables: getBoreholesInput = {
      code: txtSearch,
      take: pageSize,
      skip: (pageNumber - 1) * pageSize
    };
    getBoreholes({
      variables
    });
  };

  const fetch = (resetPage?: boolean) => {
    if (!!resetPage && pageNumber !== 1) {
      setPageNumber(1);
    } else {
      fetchBoreholes();
    }
  };

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, error);
  }, [error]);

  useEffect(() => {
    fetch();
  }, [pageNumber]);

  useEffect(() => {
    fetch(true);
  }, [pageSize, txtSearch]);

  const openDetailBorehole = (borehole: borehole) => {
    setSelectedBorehole(borehole);
    history.push(`/boreholeDetail/${borehole.id}`);
  };

  const onExportBoreholeDetail = (borehole: borehole) => {
    console.log(borehole);
    excelBoreholeDetailService(auth.accessToken, borehole.id!)?.then((blob) => saveAs(blob, `borehole-${borehole.code}.xlsx`));
  };

  const openDeleteBoreholeConfirmation = (borehole: borehole) => {
    setSelectedBorehole(borehole);
    setOpenDeleteConfirmation(true);
  };

  const onDeleteCancel = () => setOpenDeleteConfirmation(false);

  const [deleteBorehole, deleteBoreholeOperation] = useMutation<boolean>(DELETE_BOREHOLE, {
    onError: (e) => { },
    onCompleted: (result) => {
      if (data && refetch) {
        if (data.boreholes.items.length == 1) {
          const targetPage = pageNumber - 1 <= 0 ? 1 : pageNumber - 1;
          if (pageNumber !== targetPage) {
            setPageNumber(targetPage);
          } else {
            refetch();
          }
        } else {
          refetch();
        }
      }
      onDeleteCancel();
    }
  });

  const onDelete = () => {
    const input: deleteBoreholeInput = {
      id: selectedBorehole!.id!
    };
    deleteBorehole({
      variables: {
        input
      }
    });
  };

  const onPageSizeChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(+event.target.value);
  };

  const onExportData = () => {
    excelBoreholeService(auth.accessToken)?.then((blob) => saveAs(blob, 'boreholes.xlsx'));
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{t(capitalizeFirstLetterEveryword(TXT_BOREHOLES))}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          {
            openDeleteConfirmation &&
            <DeleteConfirmation
              loading={deleteBoreholeOperation.loading}
              onYes={onDelete}
              onNo={onDeleteCancel}
              objectName={selectedBorehole?.code || ''}
            />
          }
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className='row'>
                    <div className='col-6'>
                      <div className="form-group">
                        <label>{lblSearch}</label>
                        <input type='text' className='form-control' value={txtSearch} onChange={onTxtSearchChanged} />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-md-12" style={{ textAlign: 'right' }}>
                      <button className="btn btn-default" onClick={onExportData}>{t(TXT_EXPORT.toUpperCase())}</button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ManageBoreholesTable
                    loading={loading}
                    data={data}
                    error={error}
                    onDetail={openDetailBorehole}
                    onDelete={openDeleteBoreholeConfirmation}
                    onExport={onExportBoreholeDetail}
                  />
                </div>
                <PaginationBar
                  currentPage={pageNumber}
                  totalCount={data?.boreholes.totalCount}
                  pageSize={pageSize}
                  onPageChanged={(page) => setPageNumber(page)}
                  onChangeRowsPerPage={onPageSizeChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
