export const TXT_WELCOME = 'welcome, {{name}}';
export const TXT_SIGN_IN = 'sign in';
export const TXT_USERNAME = 'username';
export const TXT_PASSWORD = 'password';
export const TXT_SIGNING_IN = 'signing In';
export const TXT_SIGNING_OUT = 'signing Out';
export const TXT_SIGN_OUT = 'sign Out';

export const TXT_LIGHT_THEME = 'sight Theme';
export const TXT_DARK_THEME = 'dark Theme';

export const TXT_HOME = 'home';

export const TXT_FIELD_IS_REQUIRED = 'this field is required';

export const TXT_USERS_MANAGEMENT = 'users management';

export const TXT_SEARCH_BY_USERNAME_OR_NAME = 'search by username or name';

export const TXT_USERS = 'users';
export const TXT_ROLES = 'roles';
export const TXT_MENUS = 'menus';

export const TXT_NAME = 'name';
export const TXT_EMAIL = 'email';
export const TXT_PHONE = 'phone';
export const TXT_ROLE = 'role';
export const TXT_ENABLED = 'enabled';
export const TXT_REPASSWORD = 'repassword';

export const TXT_CHANGE_PASSWORD = 'change password';
export const TXT_EDIT = 'edit';
export const TXT_DELETE = 'delete';

export const TXT_LOADING = 'loading';

export const TXT_DATA_NOT_FOUND = 'data not found';

export const TXT_ROWS_PER_PAGE = 'rows per page';
export const TXT_DISPLAYED_ROWS = '{{from}} - {{to}} of {{count}}';

export const TXT_ADD_USER = 'add a new user';
export const TXT_EDIT_USER = 'edit a user';

export const TXT_SAVE = 'save';
export const TXT_SAVING = 'saving';
export const TXT_CANCEL = 'cancel';

// eslint-disable-next-line max-len
export const TXT_PASSWORD_REPASSWORD_MISMATCH = 'password is not the same to re-password';

export const TXT_DELETE_CONFIRMATION_TITLE = 'delete confirmation';
// eslint-disable-next-line max-len
export const TXT_DELETE_CONFIRMATION = 'are you sure you want to delete "{{name}}"?';

export const TXT_YES = 'yes';
export const TXT_NO = 'no';

export const TXT_ALL = 'all';

export const TXT_SEARCH_BY_NAME = 'search by name';

export const TXT_ADD_ROLE = 'add a new role';
export const TXT_EDIT_ROLE = 'edit a role';

export const TXT_OLD_PASSWORD = 'old password';

export const TXT_MASTER_DATA = 'master data';

export const TXT_ALTERATIONS = 'alterations';
export const TXT_BLOCKS = 'blocks';
export const TXT_PROSPECTS = 'prospects';
export const TXT_DEFECT_TYPES = 'defect types';
export const TXT_HOLE_SIZES = 'hole sizes';
export const TXT_INFILL_ALTERATIONS = 'infill alterations';
export const TXT_INFILL_MINERALS = 'infill minerals';
export const TXT_LITHOLOGIES = 'lithologies';
export const TXT_ROUGHNESSES = 'roughnesses';
export const TXT_STRENGTH = 'strength';
export const TXT_STRUCTURES = 'structures';
export const TXT_VEIN_QUALIFIERS = 'vein qualifiers';
export const TXT_WEATHERING = 'weathering';
export const TXT_MACHINE = 'machine';

export const TXT_ROLE_MANAGEMENT = 'roles management';

export const TXT_CODE = 'code';
export const TXT_LABEL = 'label';

export const TXT_ADD_ALTERATION = 'add a new alteration';
export const TXT_EDIT_ALTERATION = 'edit an alteration';

export const TXT_ADD_BLOCK = 'add a new block';
export const TXT_EDIT_BLOCK = 'edit a block';

export const TXT_ADD_DEFECT_TYPE = 'add a new defect type';
export const TXT_EDIT_DEFECT_TYPE = 'edit a defect type';

export const TXT_ADD_HOLE_SIZE = 'add a new hole size';
export const TXT_EDIT_HOLE_SIZE = 'edit a hole size';

export const TXT_ADD_INFILL_ALTERATION = 'add a new infill alteration';
export const TXT_EDIT_INFILL_ALTERATION = 'edit a infill alteration';

export const TXT_ADD_INFILL_MINERAL = 'add a new infill mineral';
export const TXT_EDIT_INFILL_MINERAL = 'edit a infill mineral';

export const TXT_ADD_LITHOLOGY = 'add a new lithology';
export const TXT_EDIT_LITHOLOGY = 'edit a lithology';

export const TXT_ADD_ROUGHNESS = 'add a new roughness';
export const TXT_EDIT_ROUGHNESS = 'edit a roughness';

export const TXT_ADD_STRENGTH = 'add a new strength';
export const TXT_EDIT_STRENGTH = 'edit a strength';

export const TXT_ADD_STRUCTURE = 'add a new structure';
export const TXT_EDIT_STRUCTURE = 'edit a structure';

export const TXT_ADD_VEIN_QUALIFIER = 'add a new vein qualifier';
export const TXT_EDIT_VEIN_QUALIFIER = 'edit a vein qualifier';

export const TXT_ADD_WEATHERING = 'add a new weathering';
export const TXT_EDIT_WEATHERING = 'edit a weathering';

export const TXT_ADD_PROSPECT = 'add a new prospect';
export const TXT_EDIT_PROSPECT = 'edit a prospect';

export const TXT_ADD_MACHINE = 'add a new machine';
export const TXT_EDIT_MACHINE = 'edit a machine';

export const TXT_ORDER = 'order';

export const TXT_BOREHOLE = 'borehole';
export const TXT_BLOCK = 'block';
export const TXT_MOBILE_USER = 'mobile user';
export const TXT_PROSPECT = 'prospect';
export const TXT_LENGTH = 'length';
export const TXT_NORTING = 'northing';
export const TXT_EASTING = 'easting';
export const TXT_COLLAR = 'collar';
export const TXT_EXPORT = 'export';
export const TXT_EXPORT_DETAIL = 'export detail';
export const TXT_BOREHOLES = 'boreholes';
export const TXT_DETAIL = 'detail';
export const TXT_BOREHOLE_DETAIL = 'borehole detail';

export const TXT_DEPTH = 'depth';
export const TXT_AZIMUTH = 'azimuth';
export const TXT_DIP = 'dip';
export const TXT_DIR = 'dir';
export const TXT_LIST_SURVEY_DATA = 'list of survey data';
export const TXT_LIST_HOLE_SIZE = 'list of hole size';
export const TXT_LIST_GEOLOGICAL_DATA = 'list of geological data';
export const TXT_LIST_PHOTO = 'list of photo';
export const TXT_LIST_ASSAY = 'list of assay';
export const TXT_LIST_GEOTECHNICAL_DATA = 'list of geotechnical data';
export const TXT_LIST_PERPANCINGAN = 'list of perpancingan';

export const TXT_CORE_SIZE = 'core size';
export const TXT_FROM = 'from';
export const TXT_TO = 'to';

export const TXT_LITH = 'lith';
export const TXT_VEIN_QUAL1 = 'vein qual1';
export const TXT_VEIN_QUAL2 = 'vein qual2';
export const TXT_VEIN_QUAL3 = 'vein qual3';
export const TXT_ALTERATION1 = 'alteration1';
export const TXT_ALTERATION2 = 'alteration2';
export const TXT_STOCKWORK = 'stockwork';
export const TXT_CY = 'cy';
export const TXT_QZ = 'qz';
export const TXT_CH = 'ch';
export const TXT_CB = 'cb';
export const TXT_SX = 'sx';
export const TXT_GX = 'gx';
export const TXT_SL_GN = 'sl/gn';
export const TXT_CP = 'cp';
export const TXT_AU = 'au';
export const TXT_CORE_RECT_UOM = 'core req (m)';
export const TXT_PERCENTAGE = '(%)';
export const TXT_LENGTH_UOM = 'length (m)';
export const TXT_REMARKS = 'remarks';
export const TXT_GEOTECHNICAL = 'geotech';
export const TXT_MINERALS = 'minerals';
export const TXT_CORE_INTEGRITY = 'core integrity';
export const TXT_STATUS = 'status';

export const TXT_HOLE_NO = 'hole no';
export const TXT_LOCATION = 'location';
export const TXT_COLLAR_RL = 'collar rl';
export const TXT_LOGGED_BY = 'logged by';
export const TXT_VALIDATE_BY = 'validate by';
export const TXT_VALIDATE_DATE = 'validate date';
export const TXT_LOGGIN_START = 'logging start';
export const TXT_LOGGIN_FINISH = 'logging finish';
export const TXT_DRILL_START = 'drill start';
export const TXT_DRILL_FINISH = 'drill finish';
export const TXT_OBJECTIVE = 'objective';
export const TXT_RESULT = 'result';
export const TXT_SIGNIFICANT_GEOLOGY = 'significant geology';

export const TXT_PHOTO = 'photo';
export const TXT_ASSAY = 'assay';
export const TXT_PERPANCINGAN = 'perpancingan';

export const TXT_DRILL_CARD = 'drill card';
// eslint-disable-next-line max-len
export const TXT_SHOW_PHOTO = 'photo of "{{name}}"?';
export const TXT_STRUCTURE = 'structure';
export const TXT_NO_DEFECT_SETS = 'no. defect sets';
export const TXT_JN = 'jn';
export const TXT_NO_DEFECT = 'no. defects';
export const TXT_DEFECT_TYPE = 'defect type';
export const TXT_QUANTITY = 'quantity';
export const TXT_ALPHA = 'alpha';
export const TXT_BETA = 'beta';
export const TXT_ROUGHNESS = 'rougness';
export const TXT_JR = 'jr';
export const TXT_INFILL_ALTERATION = 'infill alteration';
export const TXT_JA = 'ja';
export const TXT_SRF = 'srf';
export const TXT_INFILL_MINERAL = 'infill mineral';
export const TXT_HEALED = 'healed';
export const TXT_INFILL_WIDTH = 'infill width (mm)';
export const TXT_ORIENTATION = 'orientation';
export const TXT_ORI_QUALITY = 'ori quality';
export const TXT_DRILL_PROGRESS = 'drill progress';
export const TXT_START_DATE = 'start date';
export const TXT_FINISH_DATE = 'finish date';
export const TXT_TOTAL_TIME = 'total time';
export const TXT_CORE_LENGTH = 'core length';
export const TXT_CORE_REC = 'core rec';
export const TXT_CORE_LOSE = 'core lose';
export const TXT_D_SIZE = 'd size';
export const TXT_DRILL_RPM = 'drill rpm';
export const TXT_PUMP_RPM = 'pump rpm';
export const TXT_DRILL_WATER = 'drill water';
export const TXT_ROCK_FORMATION = 'rock formation';
export const TXT_DRILL_TECHNIQUE = 'drill technique';
export const TXT_EDIT_BOREHOLE = 'edit a borehole';
export const TXT_NUMBER = 'number';
export const TXT_COLLAR_AZIMUTH = 'collar azimuth';
export const TXT_COLLAR_DIP = 'collar dip';
export const TXT_EDIT_SURVEY_DATA = 'edit a survey data';
export const TXT_EDIT_LOG_HOLE_SIZE = 'edit a log hole size';
export const TXT_HOLE_SIZE = 'hole size';
