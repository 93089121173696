export const capitalizeFirstLetter = (text: string) => {
  text = text.toLowerCase();
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
};

export const capitalizeFirstLetterEveryword = (text: string) => {
  const words = text.toLowerCase().split(' ');
  return words.reduce((prev, current) => {
    return `${prev} ${capitalizeFirstLetter(current)}`;
  }, '');
};
