import { gql } from '@apollo/client';
import { blockQueryResult } from './blockQueries';

export const ADD_BLOCK = gql`
  mutation addBlock($input: AddBlockInput!) {
    addBlock(input: $input){
      ${blockQueryResult}
    }
  }
`;

export const UPDATE_BLOCK = gql`
  mutation updateBlock($input: UpdateBlockInput!) {
    updateBlock(input: $input){
      ${blockQueryResult}
    }
  }
`;

export const DELETE_BLOCK = gql`
  mutation deleteBlock($input: DeleteBlockInput!) {
    deleteBlock(input: $input)
  }
`;
