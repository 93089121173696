/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { getLithologyOutput } from '../../../../api';
import { manageOrderCodeLabelColumns } from '../../../../components';
import { lithology } from '../../../../entityModel';
import { ManageLithologysTableBody } from './ManageLithologysTableBody';
import { ManageLithologysTableHead } from './ManageLithologysTableHead';

interface ManageLithologysTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getLithologyOutput;
  onEdit: (lithology: lithology) => void;
  onDelete: (lithology: lithology) => void;
}

export const ManageLithologysTable = ({ error, loading, data, onEdit, onDelete, ...rest }: ManageLithologysTableProps) => {
  return (
    <table className="table table-bordered">
      <ManageLithologysTableHead
        columns={manageOrderCodeLabelColumns}
      />
      <ManageLithologysTableBody
        columns={manageOrderCodeLabelColumns}
        loading={loading}
        error={error}
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </table>
  );
};
