/* eslint-disable max-len */
import { holeSize, logHoleSize } from '../../../../entityModel';
import { useTranslation } from 'react-i18next';
import {
  TXT_CANCEL,
  TXT_EDIT_LOG_HOLE_SIZE,
  TXT_FROM,
  TXT_HOLE_SIZE,
  TXT_SAVE,
  TXT_SAVING,
  TXT_TO
} from '../../../../translations/translationConstants';
import {
  capitalizeFirstLetterEveryword
} from '../../../../shared/stringFunctions';
import {
  getHoleSizeOutput,
  GET_HOLE_SIZE,
  updateLogHoleSizeInput
} from '../../../../api';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useApolloErrorHandler, useAuthServices } from '../../../../hooks';

export interface ManageLogHoleSizeEditorProps {
  logHoleSize?: logHoleSize;
  onClose: () => void;
  onSave: (payload: updateLogHoleSizeInput) => void;
  isSaving: boolean;
  savingError?: string;
};

export const ManageLogHoleSizeEditor = (props: ManageLogHoleSizeEditorProps) => {
  const { t } = useTranslation();
  const { isSignedIn } = useAuthServices();

  const [holeSize, setHoleSize] = useState<holeSize | undefined>(props.logHoleSize && props.logHoleSize.holeSize);
  const [from, setFrom] = useState(props.logHoleSize?.from);
  const [to, setTo] = useState(props.logHoleSize?.to);

  const onFromChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(event.currentTarget.value))) {
      setFrom(Number(event.currentTarget.value));
    }
  };

  const onToChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(event.currentTarget.value))) {
      setTo(Number(event.currentTarget.value));
    }
  };

  const onHoleSizeChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.currentTarget.value !== undefined || event.currentTarget.value !== '') {
      const appHoleSize = holeSizesData && holeSizesData.holeSizes && holeSizesData.holeSizes.items &&
      holeSizesData.holeSizes.items.find((holeSize) => holeSize.id === event.currentTarget.value);

      setHoleSize(appHoleSize);
    }
  };

  const validate = () => {
    return !!holeSize && from !== undefined && to !== undefined;
  };

  const [getHoleSizes, { data: holeSizesData, error: errorHoleSize }] = useLazyQuery<getHoleSizeOutput>(GET_HOLE_SIZE);

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, errorHoleSize);
  }, [errorHoleSize]);

  useEffect(() => {
    getHoleSizes();
  }, [holeSizesData]);

  const [disabledSaveButton, setDisabledSaveButton] = useState(!validate());

  useEffect(() => {
    setDisabledSaveButton(!validate());
  }, [holeSize]);

  const updateAddInput = (): updateLogHoleSizeInput => {
    return {
      id: props.logHoleSize!.id!,
      code: props.logHoleSize?.code,
      boreholeCode: props.logHoleSize?.borehole?.code,
      holeSizeCode: holeSize?.code,
      from,
      to
    };
  };

  const onSaving = () => {
    if (!validate()) {
      return;
    }
    props.onSave(updateAddInput());
  };

  const lblTitle = t(TXT_EDIT_LOG_HOLE_SIZE).toUpperCase();
  const lblSave = t(TXT_SAVE);
  const lblSaving = t(TXT_SAVING);
  const lblCancel = t(TXT_CANCEL);

  const lblHoleSize = `${capitalizeFirstLetterEveryword(t(TXT_HOLE_SIZE))}*`;
  const lblFrom = `${capitalizeFirstLetterEveryword(t(TXT_FROM))}*`;
  const lblTo = `${capitalizeFirstLetterEveryword(t(TXT_TO))}*`;

  return (
    <div className="modal fade" id="modal-logHoleSize-editor">
      <div className="modal-dialog">
        <form>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{lblTitle}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="inputStatus">{lblHoleSize}</label>
                <select onChange={onHoleSizeChanged} onBlur={onHoleSizeChanged} className="form-control custom-select" value={holeSize?.id}>
                  {holeSizesData && holeSizesData.holeSizes &&
                    holeSizesData.holeSizes.items && holeSizesData.holeSizes.items.map((holeSize: holeSize) => (
                    <option key={holeSize.id} value={holeSize.id}>{holeSize.label}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>{lblFrom}</label>
                <input type="text" onChange={onFromChanged} value={from} className="form-control" />
              </div>
              <div className="form-group">
                <label>{lblTo}</label>
                <input type="text" onChange={onToChanged} value={to} className="form-control" />
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={props.onClose} className="btn btn-default" data-dismiss="modal">{lblCancel}</button>
              <button type="button" onClick={onSaving} data-dismiss="modal" disabled={disabledSaveButton || props.isSaving} className="btn btn-primary">{props.isSaving ? lblSaving : lblSave}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
