import { gql } from '@apollo/client';
import { alterationQueryResult } from './alterationQueries';

export const ADD_ALTERATION = gql`
  mutation addAlteration($input: AddAlterationInput!) {
    addAlteration(input: $input){
      ${alterationQueryResult}
    }
  }
`;

export const UPDATE_ALTERATION = gql`
  mutation updateAlteration($input: UpdateAlterationInput!) {
    updateAlteration(input: $input){
      ${alterationQueryResult}
    }
  }
`;

export const DELETE_ALTERATION = gql`
  mutation deleteAlteration($input: DeleteAlterationInput!) {
    deleteAlteration(input: $input)
  }
`;
