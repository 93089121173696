import axios from 'axios';
import { defaultAuthState } from '../../../shared/appLocalStorage';
import { apiUrl } from '../../../shared/links';
import { signInInput } from './signInInput';
import { signInOutput } from './signInOutput';

export const signInService = async (input: signInInput) => {
  const signInURL = `${apiUrl}/user/signin`;
  try {
    const response = await axios.post<signInOutput>(signInURL, input);
    return response.data;
  } catch (ex) {
    if (!ex.response || !ex.response.data) {
      const retError: signInOutput = {
        ...defaultAuthState,
        errorMessage: ex
      };
      return retError;
    }
    return ex.response.data as signInOutput;
  }
};
