/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { memo } from 'react';
import { getSurveyDataOutput } from '../../../../api';
import { surveyData } from '../../../../entityModel';
import { manageSurveyDataColumns } from './manageSurveyDataColumns';
import { ManageSurveyDataTableBody } from './ManageSurveyDataTableBody';
import { ManageSurveyDataTableHead } from './ManageSurveyDataTableHead';

interface ManageSurveyDatasTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getSurveyDataOutput;
  onEdit: (user: surveyData) => void;
  onDelete: (user: surveyData) => void;
}

export const ManageSurveyDatasTable = memo(({ error, loading, data, onEdit, onDelete, ...rest }: ManageSurveyDatasTableProps) => {
  return (
    <table className="table table-bordered">
      <ManageSurveyDataTableHead
        columns={manageSurveyDataColumns}
      />
      <ManageSurveyDataTableBody
        columns={manageSurveyDataColumns}
        loading={loading}
        error={error}
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </table>
  );
});

ManageSurveyDatasTable.displayName = 'ManageSurveyDatasTable';

