/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import { CustomError, ManageCodeLabelColumn } from '../../../../components';
import { infillMineral } from '../../../../entityModel';
import {
  TXT_DATA_NOT_FOUND,
  TXT_DELETE,
  TXT_EDIT,
  TXT_LOADING
} from '../../../../translations/translationConstants';
import { capitalizeFirstLetter } from '../../../../shared/stringFunctions';
import { getInfillMineralOutput } from '../../../../api';
import { ApolloError } from '@apollo/client';

export interface ManageInfillMineralsTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: getInfillMineralOutput;
  columns: ManageCodeLabelColumn[];
  onEdit: (infillMineral: infillMineral) => void;
  onDelete: (infillMineral: infillMineral) => void;
}

export const ManageInfillMineralsTableBody =
  ({ error, columns, loading, data, onEdit, onDelete }: ManageInfillMineralsTableBodyProps) => {
    const { t } = useTranslation();
    return (
      <tbody>
        {
          loading &&
          <tr>
            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
              <h3>
                {t(TXT_LOADING).toUpperCase()}
              </h3>
            </td>
          </tr>
        }
        {
          !loading && error && error.message &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {error.message}
              </CustomError>
            </td>
          </tr>
        }
        {
          !loading && data &&
          data.infillMinerals && data.infillMinerals.items &&
          data.infillMinerals.items.length > 0 &&
          data.infillMinerals.items.map((infillMineral) => (
            <tr key={`row-${infillMineral.id}`}>
              <td>
                {infillMineral.code}
              </td>
              <td>
                {infillMineral.label}
              </td>
              <td align='center'>
                <button
                  data-target="#modal-infillMineral-editor"
                  data-toggle="modal"
                  className='btn btn-primary'
                  aria-label="edit infillMineral"
                  onClick={() => onEdit(infillMineral)}>
                  {t(TXT_EDIT).toUpperCase()}
                </button>
              </td>
              <td align='center'>
                <button
                  data-target="#modal-default"
                  data-toggle="modal"
                  className='btn btn-danger'
                  aria-label="delete infillMineral"
                  type="button"
                  onClick={() => onDelete(infillMineral)}>
                  {t(TXT_DELETE).toUpperCase()}
                </button>
              </td>
            </tr>
          ))
        }
        {
          !loading &&
          (!data ||
            !data.infillMinerals ||
            !data.infillMinerals.items ||
            data.infillMinerals.items.length <= 0) &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {capitalizeFirstLetter(t(TXT_DATA_NOT_FOUND))}
              </CustomError>
            </td>
          </tr>
        }
      </tbody>
    );
  };
