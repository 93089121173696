import { gql } from '@apollo/client';
import { infillMineralQueryResult } from './infillMineralQueries';

export const ADD_INFILL_MINERAL = gql`
  mutation addInfillMineral($input: AddInfillMineralInput!) {
    addInfillMineral(input: $input){
      ${infillMineralQueryResult}
    }
  }
`;

export const UPDATE_INFILL_MINERAL = gql`
  mutation updateInfillMineral($input: UpdateInfillMineralInput!) {
    updateInfillMineral(input: $input){
      ${infillMineralQueryResult}
    }
  }
`;

export const DELETE_INFILL_MINERAL = gql`
  mutation deleteInfillMineral($input: DeleteInfillMineralInput!) {
    deleteInfillMineral(input: $input)
  }
`;
