import {
  TXT_ADD_ROLE,
  TXT_ADD_USER,
  TXT_ALL,
  TXT_CANCEL,
  TXT_CHANGE_PASSWORD,
  TXT_DARK_THEME,
  TXT_DATA_NOT_FOUND,
  TXT_DELETE,
  TXT_DELETE_CONFIRMATION,
  TXT_DELETE_CONFIRMATION_TITLE,
  TXT_DISPLAYED_ROWS,
  TXT_EDIT,
  TXT_EDIT_ROLE,
  TXT_EDIT_USER,
  TXT_EMAIL,
  TXT_ENABLED,
  TXT_FIELD_IS_REQUIRED,
  TXT_HOME,
  TXT_LIGHT_THEME,
  TXT_LOADING,
  TXT_MENUS,
  TXT_NAME,
  TXT_NO,
  TXT_OLD_PASSWORD,
  TXT_PASSWORD,
  TXT_PASSWORD_REPASSWORD_MISMATCH,
  TXT_PHONE,
  TXT_REPASSWORD,
  TXT_ROLE,
  TXT_ROLES,
  TXT_ROWS_PER_PAGE,
  TXT_SAVE,
  TXT_SAVING,
  TXT_SEARCH_BY_NAME,
  TXT_SEARCH_BY_USERNAME_OR_NAME,
  TXT_SIGNING_IN,
  TXT_SIGNING_OUT,
  TXT_SIGN_IN,
  TXT_SIGN_OUT,
  TXT_USERNAME,
  TXT_USERS,
  TXT_USERS_MANAGEMENT,
  TXT_WELCOME,
  TXT_YES
} from './translationConstants';

const enUS = {
  [TXT_WELCOME]: 'welcome, {{name}}',
  [TXT_SIGN_IN]: 'sign in',
  [TXT_USERNAME]: 'username',
  [TXT_PASSWORD]: 'password',
  [TXT_SIGNING_IN]: 'signing in',
  [TXT_SIGNING_OUT]: 'signing out',
  [TXT_SIGN_OUT]: 'sign out',

  [TXT_LIGHT_THEME]: 'light theme',
  [TXT_DARK_THEME]: 'dark theme',

  [TXT_HOME]: 'home',

  [TXT_FIELD_IS_REQUIRED]: 'this field is required',

  [TXT_USERS_MANAGEMENT]: 'users management',

  [TXT_SEARCH_BY_USERNAME_OR_NAME]: 'search by username or name',

  [TXT_USERS]: 'users',
  [TXT_ROLES]: 'roles',
  [TXT_MENUS]: 'menus',

  [TXT_NAME]: 'name',
  [TXT_EMAIL]: 'email',
  [TXT_PHONE]: 'phone',
  [TXT_ROLE]: 'role',
  [TXT_ENABLED]: 'enabled',
  [TXT_REPASSWORD]: 're-type password',

  [TXT_CHANGE_PASSWORD]: 'change password',
  [TXT_EDIT]: 'edit',
  [TXT_DELETE]: 'delete',

  [TXT_LOADING]: 'loading',

  [TXT_DATA_NOT_FOUND]: 'data not found',

  [TXT_ROWS_PER_PAGE]: 'rows per page',
  [TXT_DISPLAYED_ROWS]: '{{from}} - {{to}} of {{count}}',

  [TXT_ADD_USER]: 'add a new user',
  [TXT_EDIT_USER]: 'edit a user',

  [TXT_SAVE]: 'save',
  [TXT_SAVING]: 'saving',
  [TXT_CANCEL]: 'cancel',

  [TXT_PASSWORD_REPASSWORD_MISMATCH]: 'password is not the same to re-password',

  [TXT_DELETE_CONFIRMATION_TITLE]: 'delete confirmation',
  [TXT_DELETE_CONFIRMATION]: 'are you sure you want to delete "{{name}}"?',

  [TXT_YES]: 'yes',
  [TXT_NO]: 'no',

  [TXT_ALL]: 'all',

  [TXT_SEARCH_BY_NAME]: 'search by name',

  [TXT_ADD_ROLE]: 'add a new role',
  [TXT_EDIT_ROLE]: 'edit a role',

  [TXT_OLD_PASSWORD]: 'old password'
};

export default enUS;
