/* eslint-disable max-len */
import { surveyData } from '../../../../entityModel';
import { useTranslation } from 'react-i18next';
import {
  TXT_AZIMUTH,
  TXT_CANCEL,
  TXT_DEPTH,
  TXT_DIP,
  TXT_EDIT_SURVEY_DATA,
  TXT_SAVE,
  TXT_SAVING
} from '../../../../translations/translationConstants';
import {
  capitalizeFirstLetterEveryword
} from '../../../../shared/stringFunctions';
import {
  updateSurveyDataInput
} from '../../../../api';
import { useEffect } from 'react';
import { useState } from 'react';

export interface ManageSurveyDataEditorProps {
  surveyData?: surveyData;
  onClose: () => void;
  onSave: (payload: updateSurveyDataInput) => void;
  isSaving: boolean;
  savingError?: string;
};

export const ManageSurveyDataEditor = (props: ManageSurveyDataEditorProps) => {
  const { t } = useTranslation();

  const [dip, setDip] = useState(props.surveyData?.dip);
  const [azimuth, setAzimuth] = useState(props.surveyData?.azimuth);
  const [depth, setDepth] = useState(props.surveyData?.depth);

  const onDipChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(event.currentTarget.value))) {
      setDip(Number(event.currentTarget.value));
    }
  };

  const onAzimuthChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(event.currentTarget.value))) {
      setAzimuth(Number(event.currentTarget.value));
    }
  };

  const onDepthChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(event.currentTarget.value))) {
      setDepth(Number(event.currentTarget.value));
    }
  };

  const validate = () => {
    return dip !== undefined && azimuth !== undefined && depth !== undefined;
  };

  const [disabledSaveButton, setDisabledSaveButton] = useState(!validate());

  useEffect(() => {
    setDisabledSaveButton(!validate());
  }, [dip, azimuth, depth]);

  const updateAddInput = (): updateSurveyDataInput => {
    return {
      id: props.surveyData?.id!,
      code: props.surveyData?.code,
      boreholeCode: props.surveyData?.borehole?.code,
      azimuth,
      dip,
      depth
    };
  };

  const onSaving = () => {
    if (!validate()) {
      return;
    }
    props.onSave(updateAddInput());
  };

  const lblTitle = t(TXT_EDIT_SURVEY_DATA).toUpperCase();
  const lblSave = t(TXT_SAVE);
  const lblSaving = t(TXT_SAVING);
  const lblCancel = t(TXT_CANCEL);

  const lblDip = `${capitalizeFirstLetterEveryword(t(TXT_DIP))}*`;
  const lblDepth = `${capitalizeFirstLetterEveryword(t(TXT_DEPTH))}*`;
  const lblAzimuth = `${capitalizeFirstLetterEveryword(t(TXT_AZIMUTH))}*`;

  return (
    <div className="modal fade" id="modal-surveyData-editor">
      <div className="modal-dialog">
        <form>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{lblTitle}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label>{lblDip}</label>
                <div className="input-group">
                  <input type="text" value={dip} onChange={onDipChanged} className="form-control" />
                </div>
              </div>
              <div className="form-group">
                <label>{lblAzimuth}</label>
                <div className="input-group">
                  <input type="text" value={azimuth} onChange={onAzimuthChanged} className="form-control" />
                </div>
              </div>
              <div className="form-group">
                <label>{lblDepth}</label>
                <div className="input-group">
                  <input type="text" value={depth} onChange={onDepthChanged} className="form-control" />
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={props.onClose} className="btn btn-default" data-dismiss="modal">{lblCancel}</button>
              <button type="button" onClick={onSaving} data-dismiss="modal" disabled={disabledSaveButton || props.isSaving} className="btn btn-primary">{props.isSaving ? lblSaving : lblSave}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
