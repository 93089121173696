import { gql } from '@apollo/client';
import { holeSizeQueryResult } from './holeSizeQueries';

export const ADD_HOLE_SIZE = gql`
  mutation addHoleSize($input: AddHoleSizeInput!) {
    addHoleSize(input: $input){
      ${holeSizeQueryResult}
    }
  }
`;

export const UPDATE_HOLE_SIZE = gql`
  mutation updateHoleSize($input: UpdateHoleSizeInput!) {
    updateHoleSize(input: $input){
      ${holeSizeQueryResult}
    }
  }
`;

export const DELETE_HOLE_SIZE = gql`
  mutation deleteHoleSize($input: DeleteHoleSizeInput!) {
    deleteHoleSize(input: $input)
  }
`;
