/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { memo } from 'react';
import { getGeologicalDataOutput } from '../../../../api';
import { geologicalData } from '../../../../entityModel';
import { manageGeologicalDataColumns } from './manageGeologicalDataColumns';
import { ManageGeologicalDataTableBody } from './ManageGeologicalDataTableBody';
import { ManageGeologicalDataTableHead } from './ManageGeologicalDataTableHead';

interface ManageGeologicalDatasTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getGeologicalDataOutput;
  onEdit: (user: geologicalData) => void;
  onDetail: (user: geologicalData) => void;
  onDelete: (user: geologicalData) => void;
}

export const ManageGeologicalDatasTable = memo(({ error, loading, data, onEdit, onDetail, onDelete, ...rest }: ManageGeologicalDatasTableProps) => {
  return (
    <table className="table table-bordered">
      < ManageGeologicalDataTableHead
        columns={manageGeologicalDataColumns}
      />
      <ManageGeologicalDataTableBody
        columns={manageGeologicalDataColumns}
        loading={loading}
        error={error}
        data={data}
        onDetail={onDetail}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </table>
  );
});

ManageGeologicalDatasTable.displayName = 'ManageGeologicalDatasTable';
