import { gql } from '@apollo/client';
import { lithologyQueryResult } from './lithologyQueries';

export const ADD_LITHOLOGY = gql`
  mutation addLithology($input: AddLithologyInput!) {
    addLithology(input: $input){
      ${lithologyQueryResult}
    }
  }
`;

export const UPDATE_LITHOLOGY = gql`
  mutation updateLithology($input: UpdateLithologyInput!) {
    updateLithology(input: $input){
      ${lithologyQueryResult}
    }
  }
`;

export const DELETE_LITHOLOGY = gql`
  mutation deleteLithology($input: DeleteLithologyInput!) {
    deleteLithology(input: $input)
  }
`;
