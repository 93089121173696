/* eslint-disable max-len */
import { weathering } from '../../../../entityModel';
import { useTranslation } from 'react-i18next';
import {
  TXT_ADD_WEATHERING,
  TXT_CANCEL,
  TXT_CODE,
  TXT_EDIT_WEATHERING,
  TXT_LABEL,
  TXT_ORDER,
  TXT_SAVE,
  TXT_SAVING
} from '../../../../translations/translationConstants';
import {
  capitalizeFirstLetterEveryword
} from '../../../../shared/stringFunctions';
import {
  addWeatheringInput,
  updateWeatheringInput
} from '../../../../api';
import { useEffect } from 'react';
import { useState } from 'react';

export interface ManageWeatheringEditorProps {
  weathering?: weathering;
  onClose: () => void;
  onSave: (payload: addWeatheringInput | updateWeatheringInput) => void;
  isSaving: boolean;
  savingError?: string;
};

export const ManageWeatheringEditor = (props: ManageWeatheringEditorProps) => {
  const { t } = useTranslation();

  const [order, setOrder] = useState<number | undefined>(props.weathering ? props.weathering.order : undefined);
  const [code, setCode] = useState<string | undefined>(props.weathering ? props.weathering.code : '');
  const [label, setLabel] = useState<string | undefined>(props.weathering ? props.weathering.label : '');

  const onOrderChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrder(parseInt(event.currentTarget.value));
  };

  const onCodeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.currentTarget.value);
  };

  const onLabelChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.currentTarget.value);
  };

  const validate = () => {
    return !!code && !!label;
  };

  const [disabledSaveButton, setDisabledSaveButton] = useState(!validate());

  useEffect(() => {
    setDisabledSaveButton(!validate());
  }, [order, code, label]);

  const createAddInput = (): addWeatheringInput => {
    return {
      order: order!,
      code: code!,
      label: label!
    };
  };

  const updateAddInput = (): updateWeatheringInput => {
    return {
      id: props.weathering!.id!,
      order: order!,
      code: code!,
      label: label!
    };
  };

  const onSaving = () => {
    if (!validate()) {
      return;
    }
    props.onSave(!props.weathering ? createAddInput() : updateAddInput());
  };

  const lblTitle = t(props.weathering ? TXT_EDIT_WEATHERING : TXT_ADD_WEATHERING).toUpperCase();
  const lblSave = t(TXT_SAVE);
  const lblSaving = t(TXT_SAVING);
  const lblCancel = t(TXT_CANCEL);

  const lblOrder = `${capitalizeFirstLetterEveryword(t(TXT_ORDER))}*`;
  const lblCode = `${capitalizeFirstLetterEveryword(t(TXT_CODE))}*`;
  const lblName = `${capitalizeFirstLetterEveryword(t(TXT_LABEL))}*`;

  return (
    <div className="modal fade" id="modal-weathering-editor">
      <div className="modal-dialog">
        <form>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{lblTitle}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label>{lblOrder}</label>
                <input type="number" onChange={onOrderChanged} value={order} className="form-control" />
              </div>
              <div className="form-group">
                <label>{lblCode}</label>
                <input type="text" onChange={onCodeChanged} value={code} className="form-control" />
              </div>
              <div className="form-group">
                <label>{lblName}</label>
                <input type="text" onChange={onLabelChanged} value={label} className="form-control" />
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={props.onClose} className="btn btn-default" data-dismiss="modal">{lblCancel}</button>
              <button type="button" onClick={onSaving} data-dismiss="modal" disabled={disabledSaveButton || props.isSaving} className="btn btn-primary">{props.isSaving ? lblSaving : lblSave}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
