import { Redirect, Route, RouteProps } from 'react-router';
import { useAuthServices } from '../../hooks';
import { signInPath } from '../../shared/links';

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const { isSignedIn } = useAuthServices();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isSignedIn() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: signInPath,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
