/* eslint-disable max-len */
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteGeotechnicalDataInput, DELETE_GEOTECHNICAL_DATA, getGeotechnicalDataOutput, getGeotechnicalDatasInput, GET_GEOTECHNICAL_DATA } from '../../../api';
import { DeleteConfirmation, globalPageSizeOptions, PaginationBar } from '../../../components';
import { geotechnicalData } from '../../../entityModel';
import { useApolloErrorHandler, useAuthServices } from '../../../hooks';
import { capitalizeFirstLetterEveryword } from '../../../shared/stringFunctions';
import { TXT_LIST_GEOTECHNICAL_DATA, TXT_SEARCH_BY_NAME } from '../../../translations/translationConstants';
import { ManageGeotechnicalDatasTable } from './ManageGeotechnicalDataTable';

interface ManageGeotechnicalDatasProps {
  geologicalDataId?: string;
}

export const ManageGeotechnicalDatas = () => {
  const { t } = useTranslation();
  const params = useParams<ManageGeotechnicalDatasProps>();
  const { isSignedIn } = useAuthServices();

  const [selectedGeotechnicalData, setSelectedGeotechnicalData] = useState<geotechnicalData | undefined>(undefined);

  const [getGeotechnicalDatas, { loading, data, error, refetch }] = useLazyQuery<getGeotechnicalDataOutput>(GET_GEOTECHNICAL_DATA);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(globalPageSizeOptions[0]);
  const [openEditor, setOpenEditor] = useState(false);
  const [txtSearch, setTxtSearch] = useState('');

  const lblSearch = capitalizeFirstLetterEveryword(t(TXT_SEARCH_BY_NAME));

  const onTxtSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTxtSearch(event.currentTarget.value);
  };

  const fetchGeotechnicalDatas = () => {
    const variables: getGeotechnicalDatasInput = {
      code: txtSearch,
      take: pageSize,
      skip: (pageNumber - 1) * pageSize,
      filter: {
        geologicalDataId: {
          eq: params.geologicalDataId
        }
      }
    };
    getGeotechnicalDatas({
      variables
    });
  };

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, error);
  }, [error]);

  useEffect(() => {
    fetchGeotechnicalDatas();
  }, [data, pageNumber]);

  const onOpenDeleteConfirmation = (geotechnicalData: geotechnicalData) => {
    setSelectedGeotechnicalData(geotechnicalData);
    setOpenDeleteConfirmation(true);
  };

  const onDeleteCancel = () => setOpenDeleteConfirmation(false);

  const [deleteGeotechnicalData, deleteGeotechnicalDataOperation] = useMutation<boolean>(DELETE_GEOTECHNICAL_DATA, {
    onError: (e) => { },
    onCompleted: (result) => {
      if (data && refetch) {
        if (data.geotechnicalDatas.items.length == 1) {
          const targetPage = pageNumber - 1 <= 0 ? 1 : pageNumber - 1;
          if (pageNumber !== targetPage) {
            setPageNumber(targetPage);
          } else {
            refetch();
          }
        } else {
          refetch();
        }
      }
      onDeleteCancel();
    }
  });

  const onDelete = () => {
    const input: deleteGeotechnicalDataInput = {
      id: selectedGeotechnicalData!.id!
    };
    deleteGeotechnicalData({
      variables: {
        input
      }
    });
  };

  const onOpenEditGeotechnicalDataEditor = (geotechnicalData: geotechnicalData) => {
    setSelectedGeotechnicalData(geotechnicalData);
    setOpenEditor(true);
  };

  const onPageSizeChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(+event.target.value);
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{t(capitalizeFirstLetterEveryword(TXT_LIST_GEOTECHNICAL_DATA))}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          {
            openEditor &&
            { alert }
          }
          {
            openDeleteConfirmation &&
            <DeleteConfirmation
              loading={deleteGeotechnicalDataOperation.loading}
              onYes={onDelete}
              onNo={onDeleteCancel}
              objectName={selectedGeotechnicalData?.code || ''}
            />
          }
          <div className="row">
            <div className="col-12">
              <div className="card" style={{ overflowX: 'scroll' }}>
                <div className="card-header">
                  <div className='row'>
                    <div className='col-6'>
                      <div className="form-group">
                        <label>{lblSearch}</label>
                        <input type='text' className='form-control' value={txtSearch} onChange={onTxtSearchChanged} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ManageGeotechnicalDatasTable
                    loading={loading}
                    data={data}
                    error={error}
                    onEdit={onOpenEditGeotechnicalDataEditor}
                    onDelete={onOpenDeleteConfirmation}
                  />
                </div>
                <PaginationBar
                  currentPage={pageNumber}
                  totalCount={data?.geotechnicalDatas.totalCount}
                  pageSize={pageSize}
                  onPageChanged={(page) => setPageNumber(page)}
                  onChangeRowsPerPage={onPageSizeChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
