/* eslint-disable max-len */
import {
  TXT_DELETE,
  TXT_DRILL_CARD,
  TXT_EDIT,
  TXT_FROM,
  TXT_LENGTH,
  TXT_PHOTO,
  TXT_REMARKS,
  TXT_TO
} from '../../../../translations/translationConstants';

export interface ManageAssayColumn {
  id: 'drillCard' | 'from' | 'to' | 'length' | 'remarks' | 'edit' | 'delete' | 'photo';
  label: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

export const manageAssayColumns: ManageAssayColumn[] = [
  {
    id: 'drillCard',
    label: TXT_DRILL_CARD,
    maxWidth: '20%',
    align: 'center'
  },
  {
    id: 'from',
    label: TXT_FROM,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'to',
    label: TXT_TO,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'length',
    label: TXT_LENGTH,
    maxWidth: '5%',
    align: 'center'
  },
  {
    id: 'remarks',
    label: TXT_REMARKS,
    maxWidth: '35%',
    align: 'center'
  },
  {
    id: 'edit',
    label: TXT_EDIT,
    maxWidth: '10%',
    align: 'center'
  },
  {
    id: 'delete',
    label: TXT_DELETE,
    maxWidth: '10%',
    align: 'center'
  },
  {
    id: 'photo',
    label: TXT_PHOTO,
    maxWidth: '10%',
    align: 'center'
  }
];
