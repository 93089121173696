/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { getBlockOutput } from '../../../../api';
import { manageCodeLabelColumns } from '../../../../components';
import { block } from '../../../../entityModel';
import { ManageBlocksTableBody } from './ManageBlocksTableBody';
import { ManageBlocksTableHead } from './ManageBlocksTableHead';

interface ManageBlocksTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getBlockOutput;
  onEdit: (block: block) => void;
  onDelete: (block: block) => void;
}

export const ManageBlocksTable = ({ error, loading, data, onEdit, onDelete, ...rest }: ManageBlocksTableProps) => {
  return (
    <table className="table table-bordered">
      <ManageBlocksTableHead
        columns={manageCodeLabelColumns}
      />
      <ManageBlocksTableBody
        columns={manageCodeLabelColumns}
        loading={loading}
        error={error}
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </table>
  );
};
