import { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useAuthServices } from '../../hooks';
import { pageState } from '../../recoil/pageState';
import { homePath } from '../../shared/links';
import { SignInPage } from './SignInPage';

interface LocationState {
  from: {
    pathname: string;
  }
};

export const SignIn = () => {
  const location = useLocation();
  const { isSignedIn } = useAuthServices();
  const locationState: LocationState = location.state as LocationState;
  const [, setPage] = useRecoilState(pageState);

  useEffect(() => setPage({
    title: ''
  }), []);

  return (
    isSignedIn() ?
      <Redirect
        to={
          locationState ?
            locationState.from.pathname : homePath
        } /> :
      <SignInPage />
  );
};
