/* eslint-disable max-len */
import { gql } from '@apollo/client';
import { boreholeQueryResult } from '../borehole';

export const surveyDataQueryResult = `
  id
  code
  borehole {
    ${boreholeQueryResult}
  }
  depth
  azimuth
  dip
`;

export const GET_SURVEY_DATA = gql`
  query getSurveyDatas($code: String, $skip: Int, $take: Int, $filter: SurveyDataFilterInput) {
    surveyDatas(code: $code, skip: $skip, take: $take, where: $filter) {
      items {
        ${surveyDataQueryResult}
      },
      totalCount
    }
  }
`;
