import { atom } from 'recoil';

const pageStateKey = 'pageState';

export interface PageStateProps {
  title?: string;
};

const defaultPageState: PageStateProps = {
  title: undefined
};

export const pageState = atom({
  key: pageStateKey,
  default: defaultPageState
});
