/* eslint-disable max-len */
import { gql } from '@apollo/client';
import { applicationUserQueryResult } from '../../applicationUser';
import { baseCodeLabelQueryResult, baseOrderCodeLabelQueryResult } from '../../helper';

export const geologicalDataQueryResult = `
  id
  code
  from
  to
  lithology {
    ${baseOrderCodeLabelQueryResult}
  }
  weathering {
    ${baseOrderCodeLabelQueryResult}
  }
  strength {
    ${baseOrderCodeLabelQueryResult}
  }
  coreLength
  coreRecLength
  coreRecPrc
  veinQual1 {
    ${baseCodeLabelQueryResult}
  }
  veinQual2 {
    ${baseCodeLabelQueryResult}
  }
  veinQual3 {
    ${baseCodeLabelQueryResult}
  }
  alteration1 {
    ${baseOrderCodeLabelQueryResult}
  }
  alteration2 {
    ${baseOrderCodeLabelQueryResult}
  }
  minQz
  minCh
  minCb
  minSx
  minGs
  minSnGn
  minCp
  minAu
  minIntensity
  remarks
  isAssay
  addedDate
  user {
    ${applicationUserQueryResult}
  }
  minCy
  stockwork
`;

export const GET_GEOLOGICAL_DATA = gql`
  query getGeologicalDatas($code: String, $skip: Int, $take: Int, $filter: GeologicalDataFilterInput) {
    geologicalDatas(code: $code, skip: $skip, take: $take, where: $filter) {
      items {
        ${geologicalDataQueryResult}
      },
      totalCount
    }
  }
`;
