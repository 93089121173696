import { useHistory } from 'react-router-dom';
import { useAuth } from '.';
import { refreshTokenService, signInService } from '../api';
import { defaultAuthState } from '../shared/appLocalStorage';
import { signInPath } from '../shared/links';
import moment from 'moment';

export const useAuthServices = () => {
  const [auth, setAuth] = useAuth();
  const history = useHistory();

  const isSignedIn = () => {
    let signedIn = !!auth.email &&
      auth.email.trim().length > 0 && !!!auth.errorMessage;
    const needRefreshToken = signedIn &&
    !!auth.accessToken &&
      !!auth.accessTokenValidDate &&
      auth.accessToken.trim().length > 0 &&
      moment().isAfter(moment(auth.accessTokenValidDate));

    if (needRefreshToken) {
      refreshTokenService({
        ...auth
      }).then((response) => {
        setAuth({
          ...auth,
          ...response
        });
        return true;
      }).catch((error) => {
        history.push(signInPath);
      });
      signedIn = true;
      if (auth.errorMessage) {
        signOut();
        signedIn = false;
      }
    }
    return signedIn;
  };

  const signOut = () => {
    setAuth({ ...defaultAuthState });
  };

  return {
    signIn: signInService,
    refreshToken: refreshTokenService,
    signOut,
    isSignedIn
  };
};
