import { gql } from '@apollo/client';

export const applicationUserQueryResult = `
  username,
  isEnabled,
  isMobile,
  profile {
    fullName,
    email,
    phone
  },
  role {
    id,
    name
  }
`;

/* eslint-disable max-len */
export const GET_APPLICATION_USERS = gql`
query getUsers ($roleId: Uuid, $usernameOrName: String, $skip: Int, $take: Int) {
  users(roleId: $roleId, usernameOrName: $usernameOrName, skip: $skip, take: $take) {
    items {
      ${applicationUserQueryResult}
    },
    totalCount
  }
}
`;
