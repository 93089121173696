import { ReactNode, useEffect, useState } from 'react';
import { Copyright } from '..';
// import {
//   useHistory
// } from 'react-router-dom';
// import { MenuType } from './MenuType';
// import HomeIcon from '@material-ui/icons/Home';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import clsx from 'clsx';
// import {
//   AppBar,
//   CssBaseline,
//   Divider,
//   Drawer,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Toolbar,
//   Tooltip,
//   Typography,
//   useTheme
// } from '@material-ui/core';
// import {
//   homePath,
//   usersManagementPath
// } from '../../shared/links';
// import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
// import Brightness4Icon from '@material-ui/icons/Brightness4';
// import { useTranslation } from 'react-i18next';
// import {
//   TXT_DARK_THEME,
//   TXT_HOME,
//   TXT_LIGHT_THEME,
//   TXT_USERS_MANAGEMENT
// } from '../../translations/translationConstants';
// import { LanguagePopover } from '../LanguagePopover';
// import { UserInfo } from '../UserInfo';
import { useAuth, useAuthServices } from '../../hooks';
// // import PeopleIcon from '@material-ui/icons/People';
// import { useGlobalStyle } from '../../pages/globalStyle';
// import { useRecoilState } from 'recoil';
// import { pageState } from '../../recoil/pageState';
import AppBar from '../AppBar/AppBar';
import MenuBar from '../MenuBar/MenuBar';

interface NavBarProps {
  children?: ReactNode;
}

const NavBar = (props: NavBarProps) => {
  // const { t } = useTranslation();
  const [auth] = useAuth();
  const { isSignedIn } = useAuthServices();
  const [showDrawer, setShowDrawer] = useState(isSignedIn());
  // const [page] = useRecoilState(pageState);

  useEffect(() => {
    setShowDrawer(isSignedIn());
  }, [auth.email, auth.accessToken]);

  // const history = useHistory();

  return (
    <div>
      { showDrawer && <AppBar /> }
      { showDrawer && <MenuBar /> }
      <main className={`${!showDrawer ? 'login-page' : ''}`}>
        {props.children}
      </main>
      <Copyright />
    </div>
  );
};

export default NavBar;
