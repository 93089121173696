/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import { CustomError } from '../../../../components';
import { geotechnicalData } from '../../../../entityModel';
import {
  TXT_DATA_NOT_FOUND,
  TXT_DELETE,
  TXT_EDIT,
  TXT_LOADING
} from '../../../../translations/translationConstants';
import { capitalizeFirstLetter } from '../../../../shared/stringFunctions';
import { ApolloError } from '@apollo/client';
import { ManageGeotechnicalDataColumn } from './manageGeotechnicalDataColumns';
import { memo } from 'react';
import { getGeotechnicalDataOutput } from '../../../../api';

export interface ManageGeotechnicalDatasTableBodyProps {
  error?: ApolloError;
  loading: boolean;
  data?: getGeotechnicalDataOutput;
  columns: ManageGeotechnicalDataColumn[];
  onEdit: (geotechnicalData: geotechnicalData) => void;
  onDelete: (geotechnicalData: geotechnicalData) => void;
}

export const ManageGeotechnicalDataTableBody =
  memo(({ error, columns, loading, data, onEdit, onDelete }: ManageGeotechnicalDatasTableBodyProps) => {
    const { t } = useTranslation();
    return (
      <tbody>
        {
          loading &&
          <tr>
            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
              <h3>
                {t(TXT_LOADING).toUpperCase()}
              </h3>
            </td>
          </tr>
        }
        {
          !loading && error && error.message &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {error.message}
              </CustomError>
            </td>
          </tr>
        }
        {
          !loading && data &&
          data.geotechnicalDatas && data.geotechnicalDatas.items &&
          data.geotechnicalDatas.items.length > 0 &&
          data.geotechnicalDatas.items.map((geotechnicalData, index) => {
            return (
              <tr key={`row-${geotechnicalData.id}`}>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.from}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.to}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.structure && geotechnicalData.structure.code}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.dip}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.dir}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.noDefectSets}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.defectJn}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.noDefect}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.defectDepth}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.defectType && geotechnicalData.defectType.code}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.defectQuantity}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.defectAlpha}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.defectBeta}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.roughness && geotechnicalData.roughness.code}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.defectJr}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.infillAlteration && geotechnicalData.infillAlteration.code}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.defectJa}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.defectSrf}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.infillMineral && geotechnicalData.infillMineral.code}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.healed}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.healed}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.orientation}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {geotechnicalData.oriQuality}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <button
                    data-target="#modal-geotechnicalData-editor"
                    data-toggle="modal"
                    className='btn btn-primary btn-sm'
                    aria-label="edit geotechnicalData"
                    onClick={() => onEdit(geotechnicalData)}>
                    {t(TXT_EDIT).toUpperCase()}
                  </button>
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <button
                    data-target="#modal-default"
                    data-toggle="modal"
                    className='btn btn-danger btn-sm'
                    aria-label="delete geotechnicalData"
                    type="button"
                    onClick={() => onDelete(geotechnicalData)}>
                    {t(TXT_DELETE).toUpperCase()}
                  </button>
                </td>
              </tr>
            );
          })
        }
        {
          !loading &&
          (!data ||
            !data.geotechnicalDatas ||
            !data.geotechnicalDatas.items ||
            data.geotechnicalDatas.items.length <= 0) &&
          <tr>
            <td colSpan={columns.length}>
              <CustomError>
                {capitalizeFirstLetter(t(TXT_DATA_NOT_FOUND))}
              </CustomError>
            </td>
          </tr>
        }
      </tbody>
    );
  });

ManageGeotechnicalDataTableBody.displayName = 'ManageGeotechnicalDataTableBody';
