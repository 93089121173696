/* eslint-disable max-len */
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { useEffect, useState } from 'react';
import { createGraphQLClient } from './api';
import { useAuth } from './hooks';
import {
  RootPages
} from './pages';

const App = () => {
  const [auth] = useAuth();

  const [graphQLClient, setGraphQLClient] = useState<ApolloClient<NormalizedCacheObject> | undefined>(createGraphQLClient(auth.accessToken));
  useEffect(() => {
    if (!auth.accessToken || auth.accessToken.length <= 0) {
      setGraphQLClient(undefined);
      return;
    }

    setGraphQLClient(createGraphQLClient(auth.accessToken));
  }, [auth.accessToken]);

  return (
    <div className="wrapper">
      {
        graphQLClient ?
          <ApolloProvider client={graphQLClient}>
            <RootPages />
          </ApolloProvider> :
          <RootPages />
      }
    </div>
  );
};

export default App;
