/* eslint-disable max-len */
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
import { TXT_EXPORT } from '../../../translations/translationConstants';

export interface ExportToExcelProps {
  data: any;
  filename: string;
}

export const ExportToExcel = ({ data, filename }: ExportToExcelProps) => {
  const { t } = useTranslation();

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (apiData: any, fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button className="btn btn-default" onClick={(e) => exportToCSV(data, filename)}>{t(TXT_EXPORT).toUpperCase}</button>
  );
};
