/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { memo } from 'react';
import { getAssayOutput } from '../../../../api';
import { assay } from '../../../../entityModel';
import { manageAssayColumns } from './manageAssayColumns';
import { ManageAssayTableBody } from './ManageAssayTableBody';
import { ManageAssayTableHead } from './ManageAssayTableHead';

interface ManageAssaysTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getAssayOutput;
  onEdit: (user: assay) => void;
  onDelete: (user: assay) => void;
  onOpenPhoto: (user: assay) => void;
}

export const ManageAssaysTable = memo(({ error, loading, data, onEdit, onDelete, onOpenPhoto, ...rest }: ManageAssaysTableProps) => {
  return (
    <table className="table table-bordered">
      <ManageAssayTableHead
        columns={manageAssayColumns}
      />
      <ManageAssayTableBody
        columns={manageAssayColumns}
        loading={loading}
        error={error}
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
        onOpenPhoto={onOpenPhoto}
      />
    </table>
  );
});

ManageAssaysTable.displayName = 'ManageGeoAssaysTable';

