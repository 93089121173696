/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { getStrengthOutput } from '../../../../api';
import { manageOrderCodeLabelColumns } from '../../../../components';
import { strength } from '../../../../entityModel';
import { ManageStrengthsTableBody } from './ManageStrengthTableBody';
import { ManageStrengthsTableHead } from './ManageStrengthTableHead';

interface ManageStrengthsTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getStrengthOutput;
  onEdit: (strength: strength) => void;
  onDelete: (strength: strength) => void;
}

export const ManageStrengthsTable = ({ error, loading, data, onEdit, onDelete, ...rest }: ManageStrengthsTableProps) => {
  return (
    <table className="table table-bordered">
      <ManageStrengthsTableHead
        columns={manageOrderCodeLabelColumns}
      />
      <ManageStrengthsTableBody
        columns={manageOrderCodeLabelColumns}
        loading={loading}
        error={error}
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </table>
  );
};
