import { baseEntity } from '../base';
import { machine, prospect } from '../masterData';

export enum STATUS {
  // eslint-disable-next-line no-unused-vars
  OPEN = 0,
  // eslint-disable-next-line no-unused-vars
  VERIFIED
};

export enum LOCATION {
  // eslint-disable-next-line no-unused-vars
  UG = 0,
  // eslint-disable-next-line no-unused-vars
  SF
}

export interface borehole extends baseEntity {
  code?: string;
  projectCode?: string;
  projectNumber?: number;
  prospect?: prospect;
  machine?: machine;
  location?: string;
  norting?: number;
  easting?: number;
  collar?: number;
  collarAzimuth?: string;
  collarDip?: number;
  length?: number;
  loggingStart?: Date;
  loggingFinish?: Date;
  drillStart?: Date;
  drillFinish?: Date;
  objective?: string;
  result?: string;
  significantGeology?: string;
  status?: string;
};
