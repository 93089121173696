/* eslint-disable max-len */
import { gql } from '@apollo/client';
import { geologicalDataQueryResult } from '../../main/geologicalData';
import { applicationUserQueryResult } from '../../applicationUser';
import { defectTypeQueryResult, infillAlterationQueryResult, infillMineralQueryResult, roughnessQueryResult, structureQueryResult } from '../../masterData';

export const geotechnicalDataQueryResult = `
  id
  code
  geologicalData {
    ${geologicalDataQueryResult}
  }
  from
  to
  structure {
    ${structureQueryResult}
  }
  dip
  dir
  defectType {
    ${defectTypeQueryResult}
  }
  roughness {
    ${roughnessQueryResult}
  }
  infillAlteration {
    ${infillAlterationQueryResult}
  }
  infillMineral {
    ${infillMineralQueryResult}
  }
  noDefectSets
  noDefect
  defectJn
  defectDepth
  defectQuantity
  defectAlpha
  defectBeta
  defectJr
  defectJa
  defectSrf
  healed
  oriQuality
  orientation
  addedDate
  user {
    ${applicationUserQueryResult}
  }
`;

export const GET_GEOTECHNICAL_DATA = gql`
  query getGeotechnicalDatas($code: String, $skip: Int, $take: Int, $filter: GeotechnicalDataFilterInput) {
    geotechnicalDatas(code: $code, skip: $skip, take: $take, where: $filter) {
      items {
        ${geotechnicalDataQueryResult}
      },
      totalCount
    }
  }
`;
