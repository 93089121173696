/* eslint-disable max-len */
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ManageUsers, SignIn } from '..';
import NavBar from '../../components/NavBar/NavBar';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import { alterationsPath, assayPath, blocksPath, boreholeDetailPath, defecttypesPath, geotechnicalDataPath, holesizesPath, homePath, infillalterationsPath, infillmineralsPath, lithologiesPath, machinePath, perpancinganPath, photoPath, prospectsPath, rolesPath, roughnessPath, signInPath, strengthPath, structuresPath, usersPath, veinqualifiersPath, weatheringPath } from '../../shared/links';
import { ManageAssays, ManageBoreholeDetail, ManageBoreholes, ManageGeotechnicalDatas, ManagePerpancingan, ManagePhotos } from '../Main';
import { ManageAlterations, ManageBlocks, ManageDefectTypes, ManageHoleSizes, ManageInfillAlterations, ManageInfillMinerals, ManageLithologys, ManageMachines, ManageProspects, ManageRoughnesss, ManageStrengths, ManageStructures, ManageVeinQualifiers, ManageWeatherings } from '../MasterData';
import { ManageRoles } from '../UsersManagement';

export const RootPages = () => {
  return (
    <BrowserRouter>
      <NavBar>
        <Switch>
          <PrivateRoute exact path={homePath}>
            <ManageBoreholes />
          </PrivateRoute>
          <PrivateRoute exact path={usersPath}>
            <ManageUsers />
          </PrivateRoute>
          <PrivateRoute exact path={rolesPath}>
            <ManageRoles />
          </PrivateRoute>
          <PrivateRoute exact path={alterationsPath}>
            <ManageAlterations />
          </PrivateRoute>
          <PrivateRoute exact path={blocksPath}>
            <ManageBlocks />
          </PrivateRoute>
          <PrivateRoute exact path={defecttypesPath}>
            <ManageDefectTypes />
          </PrivateRoute>
          <PrivateRoute exact path={holesizesPath}>
            <ManageHoleSizes />
          </PrivateRoute>
          <PrivateRoute exact path={infillalterationsPath}>
            <ManageInfillAlterations />
          </PrivateRoute>
          <PrivateRoute exact path={infillmineralsPath}>
            <ManageInfillMinerals />
          </PrivateRoute>
          <PrivateRoute exact path={lithologiesPath}>
            <ManageLithologys />
          </PrivateRoute>
          <PrivateRoute exact path={prospectsPath}>
            <ManageProspects />
          </PrivateRoute>
          <PrivateRoute exact path={roughnessPath}>
            <ManageRoughnesss />
          </PrivateRoute>
          <PrivateRoute exact path={strengthPath}>
            <ManageStrengths />
          </PrivateRoute>
          <PrivateRoute exact path={structuresPath}>
            <ManageStructures />
          </PrivateRoute>
          <PrivateRoute exact path={veinqualifiersPath}>
            <ManageVeinQualifiers />
          </PrivateRoute>
          <PrivateRoute exact path={weatheringPath}>
            <ManageWeatherings />
          </PrivateRoute>
          <PrivateRoute exact path={machinePath}>
            <ManageMachines />
          </PrivateRoute>
          <PrivateRoute exact path={boreholeDetailPath}>
            <ManageBoreholeDetail />
          </PrivateRoute>
          <PrivateRoute exact path={photoPath}>
            <ManagePhotos />
          </PrivateRoute>
          <PrivateRoute exact path={assayPath}>
            <ManageAssays />
          </PrivateRoute>
          <PrivateRoute exact path={perpancinganPath}>
            <ManagePerpancingan />
          </PrivateRoute>
          <PrivateRoute exact path={geotechnicalDataPath}>
            <ManageGeotechnicalDatas />
          </PrivateRoute>
          <Route exact path={signInPath} component={SignIn} />
        </Switch>
      </NavBar>
    </BrowserRouter>
  );
};
