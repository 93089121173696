/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { getVeinQualifierOutput } from '../../../../api';
import { manageOrderCodeLabelColumns } from '../../../../components';
import { veinQualifier } from '../../../../entityModel';
import { ManageVeinQualifiersTableBody } from './ManageVeinQualifierTableBody';
import { ManageVeinQualifiersTableHead } from './ManageVeinQualifierTableHead';

interface ManageVeinQualifiersTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getVeinQualifierOutput;
  onEdit: (veinQualifier: veinQualifier) => void;
  onDelete: (veinQualifier: veinQualifier) => void;
}

export const ManageVeinQualifiersTable = ({ error, loading, data, onEdit, onDelete, ...rest }: ManageVeinQualifiersTableProps) => {
  return (
    <table className="table table-bordered">
      <ManageVeinQualifiersTableHead
        columns={manageOrderCodeLabelColumns}
      />
      <ManageVeinQualifiersTableBody
        columns={manageOrderCodeLabelColumns}
        loading={loading}
        error={error}
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </table>
  );
};
