// import { Typography } from '@material-ui/core';

export const Copyright = () => {
  const createdYear = 2021;
  const currentYear = new Date().getFullYear();
  const yearText = createdYear != currentYear ?
    `${createdYear} - ${currentYear}` : `${createdYear}`;
  const text = `© PT ANTAM Tbk. All Rights Reserved ${yearText}.`;
  return (
    <footer className="main-footer">
      {text}
    </footer>

  );
};
