import { gql } from '@apollo/client';
import { structureQueryResult } from './structureQueries';

export const ADD_STRUCTURE = gql`
  mutation addStructure($input: AddStructureInput!) {
    addStructure(input: $input){
      ${structureQueryResult}
    }
  }
`;

export const UPDATE_STRUCTURE = gql`
  mutation updateStructure($input: UpdateStructureInput!) {
    updateStructure(input: $input){
      ${structureQueryResult}
    }
  }
`;

export const DELETE_STRUCTURE = gql`
  mutation deleteStructure($input: DeleteStructureInput!) {
    deleteStructure(input: $input)
  }
`;
