import { gql } from '@apollo/client';
import { veinQualifierQueryResult } from './veinQualifierQueries';

export const ADD_VEIN_QUALIFIER = gql`
  mutation addVeinQualifier($input: AddVeinQualifierInput!) {
    addVeinQualifier(input: $input){
      ${veinQualifierQueryResult}
    }
  }
`;

export const UPDATE_VEIN_QUALIFIER = gql`
  mutation updateVeinQualifier($input: UpdateVeinQualifierInput!) {
    updateVeinQualifier(input: $input){
      ${veinQualifierQueryResult}
    }
  }
`;

export const DELETE_VEIN_QUALIFIER = gql`
  mutation deleteVeinQualifier($input: DeleteVeinQualifierInput!) {
    deleteVeinQualifier(input: $input)
  }
`;
