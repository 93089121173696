/* eslint-disable max-len */
import { gql } from '@apollo/client';

export const prospectQueryResult = `
  id,
  code,
  label
`;

export const GET_PROSPECT = gql`
  query getProspects($code: String, $skip: Int, $take: Int, $filter: ProspectFilterInput) {
    prospects(code: $code, skip: $skip, take: $take, where: $filter) {
      items {
        ${prospectQueryResult}
      },
      totalCount
    }
  }
`;
