/* eslint-disable max-len */
import { ApolloError } from '@apollo/client';
import { getHoleSizeOutput } from '../../../../api';
import { manageCodeLabelColumns } from '../../../../components';
import { holeSize } from '../../../../entityModel';
import { ManageHoleSizesTableBody } from './ManageHoleSizesTableBody';
import { ManageHoleSizesTableHead } from './ManageHoleSizesTableHead';

interface ManageHoleSizesTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getHoleSizeOutput;
  onEdit: (holeSize: holeSize) => void;
  onDelete: (holeSize: holeSize) => void;
}

export const ManageHoleSizesTable = ({ error, loading, data, onEdit, onDelete, ...rest }: ManageHoleSizesTableProps) => {
  return (
    <table className="table table-bordered">
      <ManageHoleSizesTableHead
        columns={manageCodeLabelColumns}
      />
      <ManageHoleSizesTableBody
        columns={manageCodeLabelColumns}
        loading={loading}
        error={error}
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </table>
  );
};
