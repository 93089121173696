import { gql } from '@apollo/client';
import { geotechnicalDataQueryResult } from './geotechnicalDataQueries';

export const ADD_GEOTECHNICAL_DATA = gql`
  mutation addGeotechnicalData($input: AddGeotechnicalDataInput!) {
    addGeotechnicalData(input: $input){
      ${geotechnicalDataQueryResult}
    }
  }
`;

export const UPDATE_GEOTECHNICAL_DATA = gql`
  mutation updateGeotechnicalData($input: UpdateGeotechnicalDataInput!) {
    updateGeotechnicalData(input: $input){
      ${geotechnicalDataQueryResult}
    }
  }
`;

export const DELETE_GEOTECHNICAL_DATA = gql`
  mutation deleteGeotechnicalData($input: DeleteGeotechnicalDataInput!) {
    deleteGeotechnicalData(input: $input)
  }
`;
