import { ApolloError } from '@apollo/client';
import { getApplicationUsersOutput } from '../../../../api';
import { applicationUser } from '../../../../entityModel';
import { manageUserColumns } from './manageUsersColumns';
import { ManageUsersTableBody } from './ManageUsersTableBody';
import { ManageUsersTableHead } from './ManageUsersTableHead';

interface ManageUsersTableProps {
  error?: ApolloError;
  loading: boolean;
  data?: getApplicationUsersOutput;
  onEdit: (user: applicationUser) => void;
  onDelete: (user: applicationUser) => void;
  changeEnablement: (user: applicationUser) => void;
}

// eslint-disable-next-line max-len
export const ManageUsersTable = ({ error, loading, data, onEdit, onDelete, changeEnablement, ...rest }: ManageUsersTableProps) => {
  return (
    <table className="table table-bordered">
      <ManageUsersTableHead
        columns={manageUserColumns}
      />
      <ManageUsersTableBody
        columns={manageUserColumns}
        loading={loading}
        error={error}
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
        changeEnablement={changeEnablement}
      />
    </table>
  );
};
