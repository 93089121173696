import { signInOutput } from '../api/auth/signIn';

export interface appStorageProps extends signInOutput {
  darkMode?: boolean
}

export const localStorageKey = 'rindaemas_localStorage';

export const defaultAuthState: signInOutput = {
  email: '',
  fullName: '',
  accessToken: '',
  accessTokenValidDate: new Date(),
  refreshToken: '',
  refreshTokenValidDate: new Date(),
  lastLogin: new Date()
};

export const defaultStorage: appStorageProps = {
  ...defaultAuthState
};

export const getAppLocalStorage = () => {
  const storedValue = localStorage.getItem(localStorageKey);

  if (!storedValue || storedValue === null) {
    localStorage.setItem(localStorageKey, JSON.stringify(defaultStorage));
    return defaultStorage;
  }

  return JSON.parse(storedValue) as appStorageProps;
};

export const setAppLocalStorage = (value: appStorageProps) => {
  localStorage.setItem(localStorageKey, JSON.stringify(value));
};

const appLocalStorage = getAppLocalStorage();

export default appLocalStorage;
