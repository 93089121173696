import { gql } from '@apollo/client';
import { roughnessQueryResult } from './roughnessQueries';

export const ADD_ROUGHNESS = gql`
  mutation addRoughness($input: AddRoughnessInput!) {
    addRoughness(input: $input){
      ${roughnessQueryResult}
    }
  }
`;

export const UPDATE_ROUGHNESS = gql`
  mutation updateRoughness($input: UpdateRoughnessInput!) {
    updateRoughness(input: $input){
      ${roughnessQueryResult}
    }
  }
`;

export const DELETE_ROUGHNESS = gql`
  mutation deleteRoughness($input: DeleteRoughnessInput!) {
    deleteRoughness(input: $input)
  }
`;
