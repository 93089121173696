import { gql } from '@apollo/client';
import { applicationUserQueryResult } from '.';

export type UserEnablementInput = {
  email: string;
  isEnabled: boolean;
};

export const CHANGE_USER_ENABLEMENT = gql`
  mutation changeUserEnablement($input: UserEnablementInput!) {
    changeUserEnablement(input: $input) {
      ${applicationUserQueryResult}
    }
  }
`;

export const ADD_APPLICATION_USER = gql`
  mutation addUser($input: AddApplicationUserInput!) {
    registerUser(input: $input){
      ${applicationUserQueryResult}
    }
  }
`;

export const UPDATE_APPLICATION_USER = gql`
  mutation updateUser($input: UpdateApplicationUserInput!) {
    updateUser(input: $input){
      ${applicationUserQueryResult}
    }
  }
`;

export const DELETE_APPLICATION_USER = gql`
  mutation deleteUser($input: DeleteApplicationUserInput!) {
    deleteUser(input: $input)
  }
`;

export const CHANGE_PASSWORD_APPLICATION_USER = gql`
  mutation changePassword($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`;
