/* eslint-disable max-len */
// export const apiBaseUrl = 'http://194.233.66.202:5100';
export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const graphqlUrl = `${apiBaseUrl}/graphql`;
export const apiUrl = `${apiBaseUrl}/api`;
export const homePath = '/';
export const signInPath = '/signin';

export const usersPath = '/users';
export const rolesPath = '/roles';

export const alterationsPath = '/alterations';
export const blocksPath = '/blocks';
export const prospectsPath = '/prospects';
export const defecttypesPath = '/defecttypes';
export const holesizesPath = '/holesizes';
export const infillalterationsPath = '/infillalterations';
export const infillmineralsPath = '/infillminerals';
export const lithologiesPath = '/lithologies';
export const roughnessPath = '/roughness';
export const strengthPath = '/strength';
export const structuresPath = '/structures';
export const veinqualifiersPath = '/veinqualifiers';
export const weatheringPath = '/weathering';
export const machinePath = '/machine';

export const boreholeDetailPath = '/boreholeDetail/:boreholeId';
export const assayPath = '/boreholeDetail/:boreholeId/assay';
export const photoPath = '/boreholeDetail/:boreholeId/photo';
export const perpancinganPath = '/boreholeDetail/:boreholeId/perpancingan';
export const geotechnicalDataPath = '/boreholeDetail/:boreholeId/geotechnical/:geologicalDataId';
