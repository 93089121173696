/* eslint-disable max-len */
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ManagePerpancinganColumn } from '.';

export interface ManagePerpancinganTableHeadProps {
  columns: ManagePerpancinganColumn[];
};

export const ManagePerpancinganTableHead = memo((props: ManagePerpancinganTableHeadProps) => {
  const { t } = useTranslation();
  return (
    <thead>
      <tr>
        {
          props.columns.map((col) => (
            <th
              key={`head-${col.id}`}
              style={{ width: col.maxWidth, textAlign: col.align }}
            >
              {t(col.label).toUpperCase()}
            </th>
          ))
        }
      </tr>
    </thead>
  );
});

ManagePerpancinganTableHead.displayName = 'ManagePerpancinganTableHead';
