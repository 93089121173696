import { gql } from '@apollo/client';

export const strengthQueryResult = `
  id,
  order,
  code,
  label
`;

export const GET_STRENGTH = gql`
  query getStrengths($code: String, $skip: Int, $take: Int) {
    strengths(code: $code, skip: $skip, take: $take) {
      items {
        ${strengthQueryResult}
      },
      totalCount
    }
  }
`;
