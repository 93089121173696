/* eslint-disable max-len */
import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguagePopover } from '..';
import { useAuthServices } from '../../hooks';
import { signInPath } from '../../shared/links';
import { SignOutButton } from '../SignOutButton/SignOutButton';

interface AppBarProps {
  children?: ReactNode;
}

const AppBar = (props: AppBarProps) => {
  const { signOut } = useAuthServices();
  const history = useHistory();

  const handleSignOut = () => {
    signOut();
    history.replace(signInPath);
  };

  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-dark">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
          </li>
        </ul>
        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {/* Navbar Search */}
          <li className="nav-item">
            <a className="nav-link" data-widget="navbar-search" href="#" role="button">
              <i className="fas fa-search" />
            </a>
            <div className="navbar-search-block">
              <form className="form-inline">
                <div className="input-group input-group-sm">
                  <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                  <div className="input-group-append">
                    <button className="btn btn-navbar" type="submit">
                      <i className="fas fa-search" />
                    </button>
                    <button className="btn btn-navbar" type="button" data-widget="navbar-search">
                      <i className="fas fa-times" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>
          <li>
            <LanguagePopover />
          </li>
          <li>
            <SignOutButton
              onSignOut={handleSignOut}
            />
          </li>
        </ul>
      </nav>
    </div>

  );
};

export default AppBar;
