/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';

type LanguangeOptionType = {
  [key: string]: {
    icon: string,
    label: string
  }
}

const languageOptions: LanguangeOptionType = {
  'en': {
    icon: 'flag-icon-gb',
    label: 'English'
  },
  'id': {
    icon: 'flag-icon-id',
    label: 'Bahasa Indonesia'
  }
};

export const LanguagePopover = () => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = (language: string): void => {
    i18n.changeLanguage(language);
  };

  const selectedOption = languageOptions[i18n.language];

  return (
    <>
      <a className="nav-link" data-toggle="dropdown" href="#">
        <i className={`flag-icon ${selectedOption.icon}`} />
      </a>
      <div className="dropdown-menu dropdown-menu-right p-0">
        {Object.keys(languageOptions).map((language) => (
          <a onClick={() => handleChangeLanguage(language)}
            className={`dropdown-item ${selectedOption.icon == languageOptions[language].icon ? 'active' : ''}`}
            key={language}>
            <i className={`flag-icon ${languageOptions[language].icon} mr-2`} /> {languageOptions[language].label}
          </a>
        ))}
      </div>
    </>
  );
};
