import { gql } from '@apollo/client';
import { logHoleSizeQueryResult } from './logHoleSizeQueries';

export const ADD_LOG_HOLE_SIZE = gql`
  mutation addLogHoleSize($input: AddLogHoleSizeInput!) {
    addLogHoleSize(input: $input){
      ${logHoleSizeQueryResult}
    }
  }
`;

export const UPDATE_LOG_HOLE_SIZE = gql`
  mutation updateLogHoleSize($input: UpdateLogHoleSizeInput!) {
    updateLogHoleSize(input: $input){
      ${logHoleSizeQueryResult}
    }
  }
`;

export const DELETE_LOG_HOLE_SIZE = gql`
  mutation deleteLogHoleSize($input: DeleteLogHoleSizeInput!) {
    deleteLogHoleSize(input: $input)
  }
`;
