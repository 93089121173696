/* eslint-disable max-len */
import { borehole } from '../../../../entityModel';
import moment from 'moment';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TXT_BLOCK, TXT_COLLAR_RL, TXT_DRILL_FINISH, TXT_DRILL_START, TXT_EASTING, TXT_HOLE_NO, TXT_LENGTH, TXT_LOCATION, TXT_LOGGED_BY, TXT_LOGGIN_FINISH, TXT_LOGGIN_START, TXT_MACHINE, TXT_NORTING, TXT_OBJECTIVE, TXT_PROSPECT, TXT_RESULT, TXT_SIGNIFICANT_GEOLOGY, TXT_STATUS, TXT_VALIDATE_BY, TXT_VALIDATE_DATE } from '../../../../translations/translationConstants';
import { capitalizeFirstLetterEveryword } from '../../../../shared/stringFunctions';

interface ManageBoreholeDetailTopProps {
  borehole?: borehole;
}

export const ManageBoreholeDetailTop = memo(({ borehole } : ManageBoreholeDetailTopProps) => {
  const [t] = useTranslation();

  return (
    <div className="row">
      <div className="col-md-4">
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_HOLE_NO))}</label>
          <p className="col-sm-8 col-form-label">{borehole?.code}</p>
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_BLOCK))}</label>
          <p className="col-sm-8 col-form-label">{borehole?.prospect?.block?.label}</p>
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_PROSPECT))}</label>
          <p className="col-sm-8 col-form-label">{borehole?.prospect?.label}</p>
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_LOCATION))}</label>
          <p className="col-sm-8 col-form-label">{borehole?.location}</p>
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_LENGTH))}</label>
          <p className="col-sm-8 col-form-label">{borehole?.length}</p>
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_NORTING))}</label>
          <p className="col-sm-8 col-form-label">{borehole?.norting}</p>
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_EASTING))}</label>
          <p className="col-sm-8 col-form-label">{borehole?.easting}</p>
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_COLLAR_RL))}</label>
          <p className="col-sm-8 col-form-label">{borehole?.collar}</p>
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_MACHINE))}</label>
          <p className="col-sm-8 col-form-label">{borehole?.machine?.label}</p>
        </div>
      </div>
      <div className="col-md-8">
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_STATUS))}</label>
          <p className="col-sm-8 col-form-label">{borehole?.status}</p>
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_LOGGED_BY))}</label>
          <p className="col-sm-8 col-form-label" />
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_VALIDATE_BY))}</label>
          <p className="col-sm-8 col-form-label" />
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_VALIDATE_DATE))}</label>
          <p className="col-sm-8 col-form-label" />
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_LOGGIN_START))}</label>
          <p className="col-sm-8 col-form-label">{borehole && borehole.loggingStart && moment(borehole.loggingStart).format('D MMM Y')}</p>
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_LOGGIN_FINISH))}</label>
          <p className="col-sm-8 col-form-label">{borehole && borehole.loggingFinish && moment(borehole.loggingFinish).format('D MMM Y')}</p>
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_DRILL_START))}</label>
          <p className="col-sm-8 col-form-label">{borehole && borehole.drillStart && moment(borehole.drillStart).format('D MMM Y')}</p>
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_DRILL_FINISH))}</label>
          <p className="col-sm-8 col-form-label">{borehole && borehole.drillFinish && moment(borehole.drillFinish).format('D MMM Y')}</p>
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_OBJECTIVE))}</label>
          <p className="col-sm-8 col-form-label">{borehole?.objective}</p>
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_RESULT))}</label>
          <p className="col-sm-8 col-form-label">{borehole?.result}</p>
        </div>
        <div className="row">
          <label className="col-sm-4 col-form-label">{capitalizeFirstLetterEveryword(t(TXT_SIGNIFICANT_GEOLOGY))}</label>
          <p className="col-sm-8 col-form-label">{borehole?.significantGeology}</p>
        </div>
      </div>
    </div>
  );
});

ManageBoreholeDetailTop.displayName = 'ManageBoreholeDetailTop';
