/* eslint-disable max-len */

import { useTranslation } from 'react-i18next';
import {
  capitalizeFirstLetterEveryword
} from '../../../shared/stringFunctions';
import { TXT_ADD_DEFECT_TYPE, TXT_DEFECT_TYPES } from '../../../translations/translationConstants';
import {
  TXT_SEARCH_BY_NAME
} from '../../../translations/translationConstants';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import {
  addDefectTypeInput,
  deleteDefectTypeInput,
  getDefectTypesInput,
  getDefectTypeOutput,
  GET_DEFECT_TYPE,
  updateDefectTypeInput
} from '../../../api';
import { useEffect } from 'react';
import { useState } from 'react';
import { ManageDefectTypesTable } from './ManageDefectTypesTable';
import {
  DeleteConfirmation,
  ExportToExcel,
  globalPageSizeOptions,
  PaginationBar
} from '../../../components';
import { defectType } from '../../../entityModel';
import { useApolloErrorHandler, useAuthServices } from '../../../hooks';
import {
  ADD_DEFECT_TYPE,
  DELETE_DEFECT_TYPE,
  UPDATE_DEFECT_TYPE
} from '../../../api';
import { ManageDefectTypeEditor } from './ManagerDefectTypeEditor';

export const ManageDefectTypes = () => {
  const { t } = useTranslation();
  const { isSignedIn } = useAuthServices();

  const [txtSearch, setTxtSearch] = useState('');
  const [alterationData, setAlterationData] = useState<defectType[] | undefined>([]);

  const onTxtSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTxtSearch(event.currentTarget.value);
  };

  const [selectedDefectType, setSelectedDefectType] = useState<defectType | undefined>(undefined);
  const [getDefectTypes, { loading, data, error, refetch }] = useLazyQuery<getDefectTypeOutput>(GET_DEFECT_TYPE);
  const [getAllAlterations] = useLazyQuery<getDefectTypeOutput>(GET_DEFECT_TYPE, {
    onCompleted: (data) => setAlterationData(data.defectTypes.items)
  });

  useEffect(() => {
    getDefectTypes();
    getAllAlterations();
  }, []);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(globalPageSizeOptions[0]);

  const [openEditor, setOpenEditor] = useState(false);
  const [savingError, setSavingError] = useState<string | undefined>(undefined);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const lblSearch = capitalizeFirstLetterEveryword(t(TXT_SEARCH_BY_NAME));

  const fetchDefectTypes = () => {
    const variables: getDefectTypesInput = {
      code: txtSearch,
      take: pageSize,
      skip: (pageNumber - 1) * pageSize
    };
    getDefectTypes({
      variables
    });
  };

  const fetch = (resetPage?: boolean) => {
    if (!!resetPage && pageNumber !== 1) {
      setPageNumber(1);
    } else {
      fetchDefectTypes();
    }
  };

  useEffect(() => {
    useApolloErrorHandler(isSignedIn, error);
  }, [error]);

  useEffect(() => {
    fetch();
  }, [pageNumber]);

  useEffect(() => {
    fetch(true);
  }, [pageSize, txtSearch]);

  const openEditDefectTypeEditor = (defectType: defectType) => {
    setSelectedDefectType(defectType);
    setOpenEditor(true);
  };

  const openDeleteDefectTypeConfirmation = (defectType: defectType) => {
    setSelectedDefectType(defectType);
    setOpenDeleteConfirmation(true);
  };

  const openAddDefectTypeEditor = () => {
    setSelectedDefectType(undefined);
    setOpenEditor(true);
  };

  const closeEditor = () => {
    setOpenEditor(false);
  };

  const afterAddingOrUpdatingDefectType = () => {
    setOpenEditor(false);
    if (refetch) {
      refetch();
    }
  };

  const handleAddingOrUpdatingDefectTypeError = (error: ApolloError) => {
    if (error.graphQLErrors.length > 0 &&
      !!error.graphQLErrors[0].extensions) {
      setSavingError(error.graphQLErrors[0].extensions['message']);
    } else {
      setSavingError(error.message);
    }
  };

  const [addDefectType, addDefectTypeOperation] = useMutation<defectType>(ADD_DEFECT_TYPE, {
    onError: (e) => handleAddingOrUpdatingDefectTypeError(e),
    onCompleted: (result) => afterAddingOrUpdatingDefectType()
  });

  const [updateDefectType, updateDefectTypeOperation] = useMutation<defectType>(UPDATE_DEFECT_TYPE, {
    onError: (e) => handleAddingOrUpdatingDefectTypeError(e),
    onCompleted: (result) => afterAddingOrUpdatingDefectType()
  });

  const onEditorSave = (payload: addDefectTypeInput | updateDefectTypeInput) => {
    if (!selectedDefectType) {
      addDefectType({
        variables: {
          input: payload
        }
      });
    } else {
      updateDefectType({
        variables: {
          input: payload
        }
      });
    }
  };

  const onDeleteCancel = () => setOpenDeleteConfirmation(false);

  const [deleteDefectType, deleteDefectTypeOperation] = useMutation<boolean>(DELETE_DEFECT_TYPE, {
    onError: (e) => { },
    onCompleted: (result) => {
      if (data && refetch) {
        if (data.defectTypes.items.length == 1) {
          const targetPage = pageNumber - 1 <= 0 ? 1 : pageNumber - 1;
          if (pageNumber !== targetPage) {
            setPageNumber(targetPage);
          } else {
            refetch();
          }
        } else {
          refetch();
        }
      }
      onDeleteCancel();
    }
  });

  const onDelete = () => {
    const input: deleteDefectTypeInput = {
      id: selectedDefectType!.id!
    };
    deleteDefectType({
      variables: {
        input
      }
    });
  };

  const onPageSizeChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(+event.target.value);
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{t(capitalizeFirstLetterEveryword(TXT_DEFECT_TYPES))}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          {
            openEditor &&
            <ManageDefectTypeEditor
              defectType={selectedDefectType}
              onClose={closeEditor}
              onSave={onEditorSave}
              isSaving={
                !selectedDefectType ? addDefectTypeOperation.loading :
                  updateDefectTypeOperation.loading
              }
              savingError={savingError}
            />
          }
          {
            openDeleteConfirmation &&
            <DeleteConfirmation
              loading={deleteDefectTypeOperation.loading}
              onYes={onDelete}
              onNo={onDeleteCancel}
              objectName={selectedDefectType?.label || ''}
            />
          }
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className='row'>
                    <div className='col-6'>
                      <div className="form-group">
                        <label>{lblSearch}</label>
                        <input type='text' className='form-control' value={txtSearch} onChange={onTxtSearchChanged} />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        className='btn btn-primary'
                        data-target="#modal-defectType-editor"
                        data-toggle="modal"
                        disabled={loading}
                        onClick={openAddDefectTypeEditor}>
                        {t(TXT_ADD_DEFECT_TYPE).toUpperCase()}
                      </button>
                    </div>
                    <div className="col-md-6" style={{ textAlign: 'right' }}>
                      <ExportToExcel filename={TXT_DEFECT_TYPES} data={alterationData} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ManageDefectTypesTable
                    loading={loading}
                    data={data}
                    error={error}
                    onEdit={openEditDefectTypeEditor}
                    onDelete={openDeleteDefectTypeConfirmation}
                  />
                </div>
                <PaginationBar
                  currentPage={pageNumber}
                  totalCount={data?.defectTypes.totalCount}
                  pageSize={pageSize}
                  onPageChanged={(page) => setPageNumber(page)}
                  onChangeRowsPerPage={onPageSizeChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
